import * as React from "react";
import { Grid } from "@mui/material";
import QuestionnairePopupEdit from "./QuestionnairePopupEdit/QuestionnairePopupEdit";
import DisplaySections from "./Fields/DisplaySections";
import DisplayTitle from "./Fields/DisplayTitle";
import DisplayDocument from "./Fields/DisplayDocument";
import DisplayAddSection from "./Fields/DisplayAddSection";
import DisplayDescription from "./Fields/DisplayDescription";
import DisplayTextRGPD from "./Fields/DisplayTextRGPD";
import DisplayButtons from "./DisplayButtons";
import DisplayNotification from "./Fields/DisplayNotification";
import DisplayConfirmMessage, {
  DisplayStopMessage,
} from "./Fields/DisplayConfirmMessage";
import DisplayModeDisplay from "./Fields/DisplayModeDisplay";
import DisplayConditions from "./Fields/DisplayConditions";
import DisplayConditionsList from "./Fields/DisplayConditionsList";
import DisplaySendEmailAuto from "./Fields/DisplaySendEmailAuto";
import DisplaySpecialities from "./Fields/DisplaySpecialities";
import DisplayTitleVisible from "./Fields/DisplayTitleVisible";
import TutorialDataService from "../../../services/TutorialService";
import DisplayRelanceSMS from "./Fields/DisplayRelanceSMS";
import { ConseilReponseAfterParsing } from "../../../pages/ConseilViewPage";
import useAppSelector from "../../../redux/useAppSelector";
import OrangeCheckBox from "../../Fields/CheckBox/OrangeCheckBox";
import AddButton from "../../Library/Buttons/AddButton/AddButton";
import {
  GenericFromPopup,
  GenericSendButton,
} from "../../../pages/ChatbotLexPage";
import {
  GenericLongText,
  GenericTextInput,
} from "../../../pages/CreateOrEditChatbotLexPage";
import { nanoid } from "nanoid";
import { ConditionField, ConditionType } from "../../../types/FormTypes";
import { Delete, Edit } from "@mui/icons-material";
import { MyConditionsHandler } from "../PDF/CreateOrEditPDFQuestionnaire";
import { GenericSelect } from "../../../pages/QuestionnairePage";

export const LIST_CPTS = [
  "CPTS du Sud Toulousain",
  "CPTS Ariège-Pyrénées",
  "CPTS du Grand Annecy",
  "CPTS du Roannais",
];

export interface Exclusion {
  id: string;
  title: string;
  conditionType: ConditionType;
  conditions: ConditionField[];
  exclusionMessage: string;
}

const CreateOrEditExclusion: React.FC<{
  isCreate: boolean;
  editInfos: Exclusion;
  closePopup: () => void;
  pushNewExclusion?: (newExclusion: Exclusion) => void;
  editThisExclusion?: (exclusionToEdit: Exclusion) => void;
  questionnaireData: any;
}> = (props) => {
  const {
    isCreate,
    editInfos,
    closePopup,
    pushNewExclusion,
    editThisExclusion,
    questionnaireData,
  } = props;
  const [exclusion, setExclusion] = React.useState<Exclusion>(undefined);

  React.useEffect(() => {
    if (editInfos) {
      setExclusion(editInfos);
    }
  }, [editInfos]);

  if (!exclusion) {
    return <></>;
  }
  return (
    <>
      <GenericFromPopup
        addCloseSecurity={true}
        onClose={closePopup}
        title={{
          leftPart: isCreate ? `Ajouter` : "Modifier",
          rightPart: `une exclusion`,
        }}
      >
        <Grid
          container
          direction="column"
          wrap="nowrap"
          padding="20px"
          gap="15px"
        >
          <Grid item display="grid">
            <GenericTextInput
              fieldTitle="Titre de l'exclusion"
              id="title-exclusion"
              isMandatory={false}
              onChange={(e) => {
                const newText = e?.target?.value;
                setExclusion((x) => {
                  return { ...x, title: newText };
                });
              }}
              value={exclusion?.title}
              placeholder="Sans titre"
            />
          </Grid>
          <Grid item display="grid">
            <GenericLongText
              fieldTitle="Texte à afficher après l'exclusion"
              isMandatory={false}
              placeholder="Aucun"
              value={exclusion.exclusionMessage}
              id={`exclusion-message-input`}
              maxLength={20000}
              onChange={(e) => {
                setExclusion((x) => {
                  const copy = { ...x };
                  if (e && typeof e === "string") {
                    copy.exclusionMessage = e;
                  } else {
                    copy.exclusionMessage = "";
                  }
                  return copy;
                });
              }}
            />
          </Grid>
          <Grid item display="grid">
            <MyConditionsHandler
              conditionType={exclusion.conditionType}
              conditions={exclusion.conditions}
              deleteConditionByIndex={(index) => {
                setExclusion((x) => {
                  return {
                    ...x,
                    conditions: x.conditions.filter((c, i) => i !== index),
                  };
                });
                return;
              }}
              questionnaireData={props.questionnaireData}
              updateConditionType={(v) => {
                setExclusion((x) => {
                  return {
                    ...x,
                    conditionType: v,
                  };
                });
              }}
              addCondition={(newCond) => {
                setExclusion((x) => {
                  return {
                    ...x,
                    conditions: [...x.conditions, newCond],
                  };
                });
              }}
            />
          </Grid>
          <Grid item display="grid">
            <GenericSendButton
              errorMessages={{
                noCondition: "Aucune condition n'a été ajoutée",
              }}
              errorSelector={{
                noCondition: "#add-cond-button",
              }}
              invalidConditions={{
                noCondition: exclusion.conditions.length === 0,
              }}
              onSendClick={() => {
                if (isCreate) {
                  if (pushNewExclusion) {
                    pushNewExclusion(exclusion);
                  }
                  closePopup();
                } else {
                  if (editThisExclusion) {
                    editThisExclusion(exclusion);
                  }
                  closePopup();
                }
                return;
              }}
              isLoading={false}
              textButton={isCreate ? "Ajouter" : "Modifier"}
            />
          </Grid>
        </Grid>
      </GenericFromPopup>
    </>
  );
};

const spacingBetweenFields = "15px";

const QuestionnaireEdit: React.FC<any> = (props) => {
  const { questionnaireData } = props;
  const [createExclusionOpen, setCreateExclusionOpen] =
    React.useState<boolean>(false);
  const [editExclusionOpen, setEditExclusionOpen] =
    React.useState<Exclusion>(undefined);
  const [favoriteConseils, setFavoriteConseils] = React.useState<
    ConseilReponseAfterParsing[]
  >([]);

  const fieldCopy = useAppSelector(
    (state) => state.questionnaireReducer.fieldCopy
  );

  React.useEffect(() => {
    TutorialDataService.getAll().then((response: any) => {
      setFavoriteConseils(
        response.data.data.default_conseils.favourite_conseils
      );
    });
  }, []);

  const stops = (() => {
    const allStops = [];
    for (let i = 0; i < questionnaireData?.sections?.length; i++) {
      for (let j = 0; j < questionnaireData?.sections[i]?.fields?.length; j++) {
        for (
          let k = 0;
          k < questionnaireData?.sections[i]?.fields[j]?.length;
          k++
        ) {
          for (
            let l = 0;
            l < questionnaireData?.sections[i]?.fields[j][k]?.stops?.length;
            l++
          )
            if (questionnaireData.sections[i].fields[j][k].stops[l].value) {
              allStops.push({
                field: questionnaireData.sections[i].fields[j][k],
                valueEq:
                  questionnaireData.sections[i].fields[j][k].stops[l].value,
              });
            }
        }
      }
    }
    return allStops;
  })();

  const initExclusion = (): Exclusion => {
    return {
      id: nanoid(),
      title: "",
      exclusionMessage: "",
      conditions: [],
      conditionType: "and",
    };
  };

  return (
    <>
      {fieldCopy && <QuestionnairePopupEdit fieldCopy={fieldCopy} {...props} />}
      {createExclusionOpen && (
        <CreateOrEditExclusion
          questionnaireData={props.questionnaireData}
          isCreate={true}
          editInfos={initExclusion()}
          closePopup={() => setCreateExclusionOpen(false)}
          pushNewExclusion={(newExclusion) => {
            props.setQuestionnaireData((state) => {
              return {
                ...state,
                exclusions: !state.exclusions
                  ? [newExclusion]
                  : [...state.exclusions, newExclusion],
              };
            });
            return;
          }}
        />
      )}
      {editExclusionOpen && (
        <CreateOrEditExclusion
          questionnaireData={props.questionnaireData}
          isCreate={false}
          editInfos={editExclusionOpen}
          closePopup={() => setEditExclusionOpen(undefined)}
          editThisExclusion={(exclusionToEdit) => {
            props.setQuestionnaireData((state) => {
              return {
                ...state,
                exclusions: state.exclusions.map((x) => {
                  if (x?.id == exclusionToEdit?.id) {
                    return exclusionToEdit;
                  }
                  return x;
                }),
              };
            });
          }}
        />
      )}
      <Grid
        container
        direction="column"
        spacing={spacingBetweenFields}
        wrap="nowrap"
      >
        <Grid item display="grid">
          <DisplayModeDisplay {...props} />
        </Grid>
        <Grid item display="grid">
          <DisplayTitle {...props} />
        </Grid>
        <Grid item display="grid">
          <DisplayTitleVisible {...props} />
        </Grid>
        <Grid item display="grid">
          <DisplaySpecialities {...props} />
        </Grid>
        {props.questionnaireData?.specialities &&
          props.questionnaireData?.specialities.includes("13089") && (
            <Grid item display="grid">
              <GenericSelect
                fieldTitle="Nom de la CPTS"
                isMandatory={false}
                options={LIST_CPTS.map((x) => {
                  return { label: x, value: x };
                })}
                id="input-cpts-name"
                placeholder="Sélectionner"
                isClearable={true}
                value={
                  props.questionnaireData?.cptsName
                    ? {
                        label: props.questionnaireData.cptsName,
                        value: props.questionnaireData.cptsName,
                      }
                    : ""
                }
                onChange={(e) => {
                  props.setQuestionnaireData((x) => {
                    return { ...x, cptsName: e?.value ? e.value : "" };
                  });
                }}
              />
            </Grid>
          )}
        <Grid item display="grid">
          <DisplayDocument {...props} />
        </Grid>
        <Grid item display="grid">
          <DisplayDescription {...props} />
        </Grid>
        <Grid item display="grid">
          <DisplayTextRGPD {...props} />
        </Grid>
        <Grid item display="grid">
          <Grid container direction="column" spacing="10px" wrap="nowrap">
            <Grid item display="grid">
              <DisplayAddSection {...props} />
            </Grid>
            <Grid item display="grid">
              <DisplaySections {...props} />
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          display="grid"
          style={{
            paddingTop: "30px",
            paddingRight: "15px",
            paddingBottom: "10px",
          }}
        >
          <Grid container direction="column" spacing="10px" wrap="nowrap">
            <Grid item display="grid">
              <div
                style={{ borderTop: "2px solid #E6EBF0", borderRadius: "20px" }}
              />
            </Grid>
            <Grid item display="grid">
              <span
                style={{
                  fontFamily: "Poppins",
                  fontSize: "16px",
                  color: "#657273",
                  textAlign: "end",
                }}
              >
                {`Critères d'exclusion`}
              </span>
            </Grid>
          </Grid>
        </Grid>
        <Grid item display="grid">
          <Grid container direction="column" wrap="nowrap" gap="10px">
            <Grid item display="grid">
              <AddButton
                text={"Ajouter une exclusion"}
                displayText={true}
                onClick={() => {
                  setCreateExclusionOpen(true);
                }}
              />
            </Grid>
            {props.questionnaireData?.exclusions && (
              <Grid item display="grid">
                <Grid container direction="row" wrap="nowrap" gap="10px">
                  {props.questionnaireData.exclusions.map(
                    (exclusion: Exclusion) => {
                      return (
                        <Grid item display="grid" key={exclusion.id}>
                          <Grid
                            container
                            direction="row"
                            wrap="nowrap"
                            style={{
                              border: "1px solid rgba(11, 36, 60)",
                              backgroundColor: "rgba(247, 252, 252, 1)",
                              boxShadow: "3px 3px 2px 1px rgba(11, 36, 60, .3)",
                            }}
                            paddingLeft="7px"
                            paddingRight="7px"
                            borderRadius="10px"
                          >
                            <Grid
                              item
                              display="grid"
                              paddingLeft="5px"
                              paddingRight="5px"
                              alignItems="center"
                            >
                              <span
                                style={{
                                  fontFamily: "Poppins",
                                  color: "#0B243C",
                                  fontSize: "16px",
                                  textAlign: "center",
                                }}
                              >
                                {exclusion?.title
                                  ? `${exclusion.title}`
                                  : "Sans titre"}
                              </span>
                            </Grid>
                            <Grid
                              item
                              display="grid"
                              paddingLeft="5px"
                              paddingRight="5px"
                            >
                              <div
                                style={{
                                  height: "100%",
                                  borderLeft: "1px solid #0B243C",
                                }}
                              />
                            </Grid>
                            <Grid item display="grid" alignItems="center">
                              <Edit
                                style={{
                                  color: "#0B243C",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  setEditExclusionOpen(exclusion);
                                }}
                              />
                            </Grid>
                            <Grid
                              item
                              display="grid"
                              paddingLeft="5px"
                              paddingRight="5px"
                            >
                              <div
                                style={{
                                  height: "100%",
                                  borderLeft: "1px solid #0B243C",
                                }}
                              />
                            </Grid>
                            <Grid item display="grid" alignItems="center">
                              <Delete
                                style={{
                                  color: "#0B243C",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  props.setQuestionnaireData((x) => {
                                    return {
                                      ...x,
                                      exclusions: [...x.exclusions].filter(
                                        (x) => x?.id !== exclusion?.id
                                      ),
                                    };
                                  });
                                }}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      );
                    }
                  )}
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid
          item
          display="grid"
          style={{
            paddingTop: "30px",
            paddingRight: "15px",
            paddingBottom: "10px",
          }}
        >
          <Grid container direction="column" spacing="10px" wrap="nowrap">
            <Grid item display="grid">
              <div
                style={{ borderTop: "2px solid #E6EBF0", borderRadius: "20px" }}
              />
            </Grid>
            <Grid item display="grid">
              <span
                style={{
                  fontFamily: "Poppins",
                  fontSize: "16px",
                  color: "#657273",
                  textAlign: "end",
                }}
              >
                Arrêt sous condition
              </span>
            </Grid>
          </Grid>
        </Grid>
        <Grid item display="grid">
          <DisplayConditions {...props} />
        </Grid>
        {stops?.length > 0 && (
          <Grid item display="grid">
            <DisplayConditionsList stops={stops} {...props} />
          </Grid>
        )}
        <Grid item display="grid">
          <DisplayStopMessage {...props} />
        </Grid>
        <Grid
          item
          display="grid"
          style={{
            paddingTop: "30px",
            paddingRight: "15px",
            paddingBottom: "10px",
          }}
        >
          <Grid container direction="column" spacing="10px" wrap="nowrap">
            <Grid item display="grid">
              <div
                style={{ borderTop: "2px solid #E6EBF0", borderRadius: "20px" }}
              />
            </Grid>
            <Grid item display="grid">
              <span
                style={{
                  fontFamily: "Poppins",
                  fontSize: "16px",
                  color: "#657273",
                  textAlign: "end",
                }}
              >
                Après envoi
              </span>
            </Grid>
          </Grid>
        </Grid>
        <Grid item display="grid">
          <DisplayConfirmMessage
            favoriteConseils={favoriteConseils}
            {...props}
          />
        </Grid>
        <Grid item display="grid" marginTop="10px">
          <DisplayNotification {...props} />
        </Grid>
        <Grid item display="grid" marginTop="0px">
          <DisplaySendEmailAuto {...props} />
        </Grid>
        <Grid item display="grid" marginTop="0px">
          <DisplayRelanceSMS {...props} />
        </Grid>
        <Grid item display="grid" marginTop="0px">
          <OrangeCheckBox
            value={questionnaireData?.canDownloadResponse}
            onClick={() => {
              props.setQuestionnaireData((x) => {
                return {
                  ...x,
                  canDownloadResponse: x?.canDownloadResponse ? false : true,
                };
              });
            }}
            text="Permettre le téléchargement de la réponse (PDF)"
          />
        </Grid>
        <Grid item display="grid" marginTop="0px">
          <OrangeCheckBox
            value={questionnaireData?.sharePublicStats}
            onClick={() => {
              props.setQuestionnaireData((x) => {
                return {
                  ...x,
                  sharePublicStats: x?.sharePublicStats ? false : true,
                };
              });
            }}
            text={`Accès et partage des statistiques anonymisées et agrégées via ${process.env.REACT_APP_WEB_APP_URL}/#/qstats/${questionnaireData?.id}`}
          />
        </Grid>
        <Grid item display="grid" marginTop="0px">
          <OrangeCheckBox
            value={questionnaireData?.allowMultipleResponses}
            onClick={() => {
              props.setQuestionnaireData((x) => {
                return {
                  ...x,
                  allowMultipleResponses: x?.allowMultipleResponses ? false : true,
                };
              });
            }}
            text={`Permettre à un patient de répondre plusieurs fois`}
          />
        </Grid>
        <Grid item display="grid" marginTop="0px">
          <OrangeCheckBox
            value={questionnaireData?.refuseAnonymPatients}
            onClick={() => {
              props.setQuestionnaireData((x) => {
                return {
                  ...x,
                  refuseAnonymPatients: x?.refuseAnonymPatients ? false : true,
                };
              });
            }}
            text={`Bloquer l'accès aux patients anonymes`}
          />
        </Grid>
        <Grid item display="grid" marginTop="15px">
          <DisplayButtons {...props} />
        </Grid>
      </Grid>
    </>
  );
};

export default QuestionnaireEdit;
