import FullPage from "./FullPage";
import * as React from "react";
import { CircularProgress, Grid } from "@mui/material";
import { RouteComponentProps, useHistory } from "react-router";
import { ChevronLeftOutlined } from "@mui/icons-material";
import makeAPIRequest from "../utils/makeAPIRequest";
import OrangeCheckBox from "../components/Fields/CheckBox/OrangeCheckBox";
import { MyNewSelect } from "../components/Library/Select/MySelect";
import DisplayFormScheduled from "../components/Questionnaire/Scheduled/DisplayFormScheduled";
import StartQuestionnaire from "../components/Questionnaire/StartQuestionnaire";
import useQuery from "../utils/hooks/useQuery";
import useAppSelector from "../redux/useAppSelector";
import { GenericSelectPatient } from "../components/Messages/MessagesContent";
import IPatientData from "../types/Patient";
import { useOpenSnackbar } from "./CreateOrEditChatbotLexPage";
import { MoreOrLessButtons } from "./ChatbotLexPage";

const DisplayHowManyScheduled: React.FC<any> = (props) => {
  const { filteredAllScheduled } = props;

  const nbScheduled = filteredAllScheduled?.length;

  return (
    <span
      style={{
        fontFamily: "Poppins",
        fontSize: "16px",
        color: "#0B243C",
        paddingBottom: !nbScheduled || nbScheduled === 0 ? "70px" : undefined,
      }}
    >
      {nbScheduled >= 200
        ? "Plus de 200 programmations trouvées, veuillez utiliser les filtres pour affiner vos recherches."
        : !nbScheduled || nbScheduled === 0
        ? "Aucune programmation trouvée"
        : `${nbScheduled} programmation${nbScheduled > 1 ? "s" : ""} trouvée${
            nbScheduled > 1 ? "s" : ""
          }`}
    </span>
  );
};

const avancementOptions = [
  {
    value: "in progress",
    label: "Attente de la réponse du patient",
  },
  {
    value: "completed",
    label: "Le patient a répondu",
  },
  {
    value: "pending",
    label: "Questionnaire non envoyé",
  },
  {
    value: "cancelled",
    label: "Programmation annulée",
  },
];

interface FilterData {
  avancement: string;
  onlyArchived: boolean;
  sortByRecent: boolean;
  patientId: string;
  formId: string;
}

const DisplayAllScheduled: React.FC<any> = (props) => {
  const { forms, patientList } = props;
  const [maxItem, setMaxItem] = React.useState(10);
  const [actualDestinataire, setActualDestinataire] =
    React.useState<IPatientData>(undefined);
  const [isLoading, setIsLoading] = React.useState(false);
  const snackbarOpenUse = useOpenSnackbar();
  const [allScheduled, setAllScheduled] = React.useState([]);
  const [formsMinimalList, setFormsMinimalList] = React.useState<
    { id: string; title: string }[]
  >([]);
  const defaultFilter = {
    avancement: undefined,
    onlyArchived: false,
    patientId: undefined,
    sortByRecent: true,
    formId: undefined,
  } as FilterData;
  const [filter, setFilter] = React.useState<FilterData>(defaultFilter);



  React.useEffect(() => {
    makeAPIRequest("get", "/questions/forms/minimal_list", null, "v3")
      .then((res) => {
        setFormsMinimalList(res?.data);
      })
      .catch((err) => {
        snackbarOpenUse.error(
          "Impossible de récupérer la liste de vos questionnaires.",
          err
        );
      });
  }, []);

  React.useEffect(() => {
    setFilter((x) => {
      return {
        ...x,
        patientId: actualDestinataire?.id,
      };
    });
  }, [actualDestinataire]);

  React.useEffect(() => {
    setIsLoading(true);
    makeAPIRequest(
      "post",
      `/questions/forms/scheduled/list_with_filter`,
      {
        formStatus: filter.avancement,
        onlyArchived: filter.onlyArchived,
        sortByRecent: filter.sortByRecent,
        patientId: filter.patientId,
        formId: filter.formId,
      },
      "v3"
    )
      .then((res) => {
        setAllScheduled(res?.data);
      })
      .catch((err) => {})
      .finally(() => setIsLoading(false));
  }, [filter]);

  const editFormScheduleReducer = useAppSelector(
    (state) => state.editFormScheduleReducer
  );

  const [formSearched, setFormSearched] = React.useState(null);
  const [patientSearched, setPatientSearched] = React.useState(null);

  const patientIdQuery = useQuery().get("p");

  React.useEffect(() => {
    if (!patientIdQuery) {
      return;
    }
    makeAPIRequest("post", "/patient/by_id", { id: patientIdQuery }, "v3")
      .then((res) => {
        const patient: IPatientData = res?.data;
        if (patient) {
          setActualDestinataire(patient);
        } else {
          setActualDestinataire(undefined);
        }
      })
      .catch((err) => {
        snackbarOpenUse.error(
          `Impossible de trouver le patient dont l'ID est ${patientIdQuery}.`
        );
        setActualDestinataire(undefined);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [patientIdQuery]);

  return (
    <>
      {editFormScheduleReducer?.isOpen && (
        <StartQuestionnaire
          isEdit={true}
          filter={filter}
          allScheduled={allScheduled}
          setAllScheduled={setAllScheduled}
        />
      )}
      <Grid container direction="column" wrap="nowrap">
        <Grid item display="grid">
          <Grid
            container
            direction="row"
            alignItems="center"
            rowSpacing="10px"
            columnSpacing="20px"
          >
            <Grid item display="grid">
              <span className="filter-by-text">Filtrer par :</span>
            </Grid>
            <Grid item display="grid" width="300px">
              <GenericSelectPatient
                placeholder={
                  actualDestinataire
                    ? actualDestinataire?.lastname &&
                      actualDestinataire?.firstname
                      ? actualDestinataire?.lastname +
                        " " +
                        actualDestinataire?.firstname
                      : actualDestinataire?.phone
                    : "Nom ou n° tel patient"
                }
                onPatientClick={(patient) => {
                  setActualDestinataire(patient);
                }}
              />
            </Grid>
            <Grid item display="grid">
              <MyNewSelect
                fixedWidth={"200px"}
                options={formsMinimalList.map((x) => {
                  return {
                    value: x.id,
                    label: x.title,
                  };
                })}
                noOptionsMessage={() => "Aucun résultat"}
                value={
                  filter.formId
                    ? formsMinimalList
                        .map((x) => {
                          return {
                            value: x.id,
                            label: x.title,
                          };
                        })
                        .find((x) => x?.value == filter.formId)
                    : ("" as any)
                }
                placeholder="Questionnaire"
                onChange={(e) => {
                  setFilter((x) => {
                    return { ...x, formId: e?.value };
                  });
                }}
              />
            </Grid>
            <Grid item display="grid">
              <MyNewSelect
                fixedWidth={"200px"}
                options={avancementOptions}
                noOptionsMessage={() => "Aucun résultat"}
                value={
                  filter.avancement
                    ? avancementOptions.find(
                        (x) => x?.value == filter.avancement
                      )
                    : ("" as any)
                }
                placeholder="Avancement"
                onChange={(e) => {
                  setFilter((x) => {
                    return { ...x, avancement: e?.value };
                  });
                }}
              />
            </Grid>
            <Grid item display="grid">
              <button
                onClick={() => {
                  setActualDestinataire(undefined);
                  setFilter(defaultFilter);
                }}
                className={"delete_advice_button"}
                style={{
                  color: "rgb(91, 185, 193)",
                  padding: "0",
                  margin: "0",
                }}
              >
                Réinit.
              </button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item display="grid" paddingTop="15px">
          <OrangeCheckBox
            value={filter.sortByRecent}
            onClick={() =>
              setFilter((x) => {
                return {
                  ...x,
                  sortByRecent: filter.sortByRecent ? false : true,
                };
              })
            }
            text="Trier du plus récent au plus ancien"
          />
        </Grid>
        <Grid item display="grid" marginTop="5px">
          <OrangeCheckBox
            value={filter.onlyArchived}
            onClick={() =>
              setFilter((x) => {
                return { ...x, onlyArchived: x.onlyArchived ? false : true };
              })
            }
            text="Afficher uniquement les programmations archivées"
          />
        </Grid>
        {isLoading ? (
          <Grid
            container
            direction="row"
            display="flex"
            justifyContent="center"
            alignItems="center"
            paddingTop="15px"
          >
            <CircularProgress />
          </Grid>
        ) : (
          <>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              paddingTop="15px"
            >
              <Grid item display="grid">
                <DisplayHowManyScheduled filteredAllScheduled={allScheduled} />
              </Grid>
            </Grid>
            <Grid item display="grid" marginTop="15px">
              <Grid container direction="column" wrap="nowrap" gap="10px">
                <Grid item display="grid">
                  <Grid
                    container
                    direction="column"
                    wrap="nowrap"
                    spacing="10px"
                  >
                    {allScheduled
                      ?.slice(0, maxItem)
                      ?.map((schehduled, index) => {
                        return (
                          <Grid
                            item
                            key={schehduled?.formScheduleId}
                            display="grid"
                          >
                            <DisplayFormScheduled
                              scheduledIndex={index}
                              filteredScheduled={schehduled}
                              allScheduled={allScheduled}
                              setAllScheduled={setAllScheduled}
                            />
                          </Grid>
                        );
                      })}
                  </Grid>
                </Grid>
                <Grid item display="grid">
                  <MoreOrLessButtons
                    items={allScheduled}
                    maxItem={maxItem}
                    setMaxItem={setMaxItem}
                  />
                </Grid>
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
};

export const FormScheduledBox: React.FC<any> = (props) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [patientList, setPatientList] = React.useState([]);
  const [forms, setForms] = React.useState([]);

  return (
    <Grid container direction="column" wrap="nowrap">
      <Grid item display="grid">
        <DisplayAllScheduled forms={[]} patientList={[]} />
      </Grid>
    </Grid>
  );
};

interface IParams {
  id: string;
}
interface RouterProps {
  history: string;
}

type Props = RouteComponentProps<RouterProps>;

const QuestionnaireScheduledPage: React.FC<Props> = (props: Props) => {
  const history = useHistory();

  return (
    <>
      <FullPage>
        <Grid container direction="column" width="80vw" wrap="nowrap">
          <Grid item display="grid" paddingBottom="10px">
            <button
              className={`return-button`}
              style={{ width: "100px", height: "30px", fontSize: "16px" }}
              onClick={() => history.push("/home?f=questionnaires")}
            >
              <span style={{ display: "flex" }}>
                <Grid
                  container
                  direction="row"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid item display="grid" marginTop="1px">
                    <ChevronLeftOutlined />
                  </Grid>
                  <Grid item display="grid">
                    Retour
                  </Grid>
                </Grid>
              </span>
            </button>
          </Grid>
          <Grid item display="grid">
            <FormScheduledBox />
          </Grid>
        </Grid>
      </FullPage>
    </>
  );
};

export default QuestionnaireScheduledPage;
