import React, { useState, useEffect } from "react";
import TutorialDataService from "../services/TutorialService";
import { ReactComponent as IconStar } from "../assets/IconStar.svg";
import { ReactComponent as IconEdit } from "../assets/IconEdit.svg";
import EditConseilModal from "./EditConseil";
import useModal from "./UseModal";
import { ImCross } from "react-icons/im";
import { AiOutlineStar } from "react-icons/ai";
import EventEmitter from "../services/event";
import "../styles/last_conseils.scss";
import SelectedAdvice from "../utils/SelectedAdvice";
import { CircularProgress, Grid, IconButton, Tooltip } from "@mui/material";
import CancellablePopup from "./Library/Popups/CancellablePopup";
import NotSelectedCardBody from "./MesConseilsBox/Card/NotSelectedCardBody";
import * as editAdviceReducer from "../redux/editAdviceReducer";
import { useDispatch } from "react-redux";
import makeArrayCopy from "../utils/makeCopy";
import useSpecialities from "../utils/hooks/useSpecialities";
import * as createAdviceReducer from "../redux/createAdviceReducer";
import makeAPIRequest from "../utils/makeAPIRequest";
import { openSnackbar } from "../redux/snackbarReducer";
import useAppSelector from "../redux/useAppSelector";
import { useOpenSnackbar } from "../pages/CreateOrEditChatbotLexPage";
import { QRCodeCanvas, QRCodeSVG } from "qrcode.react";
import { SettingsRounded, Visibility } from "@mui/icons-material";
import { DisplayConseil } from "./ConseilComponent";
import IConseilData from "../types/Conseils";
import { removeParenthesis } from "./MesConseilsBox/TabsContainer/AdminTab/AdminTabContent";

export const cannotBeAddedToFavoriteGetError = (
  conseil: IConseilData
): string => {
  if (!conseil?.messages?.[0] || conseil?.messages?.[0]?.length == 0) {
    return "";
  }
  for (const message of conseil.messages[0]) {
    if ((message as any)?.is_questionnaire_interactif_owner === false) {
      return "Un questionnaire interactif contenu dans le conseil ne vous appartient pas.";
    }
    if ((message as any)?.is_form_url_owner === false) {
      return "Un questionnaire contenu dans le conseil ne vous appartient pas.";
    }
  }
  return "";
};

const DisplayFavorite: React.FC<{
  favoriteLoading: any;
  listFavorite: any;
  tutorial: any;
  handleFavorite: any;
}> = (props) => {
  const { favoriteLoading, listFavorite, tutorial, handleFavorite } = props;

  if (favoriteLoading.find((element) => element === tutorial.id)) {
    return (
      <CircularProgress
        style={{ color: "#657273", width: "20px", height: "20px" }}
      />
    );
  } else if (listFavorite.find((element) => element.id === tutorial.id)) {
    return (
      <IconStar
        onClick={() => handleFavorite(tutorial)}
        style={{ cursor: "pointer" }}
      />
    );
  } else {
    return (
      <AiOutlineStar
        onClick={() => handleFavorite(tutorial)}
        style={{ cursor: "pointer" }}
      />
    );
  }
};

export const ViewsEye: React.FC<{ nbViews: number }> = ({ nbViews }) => {
  return (
    <Tooltip
      title={`Ce code QR a été scanné ${nbViews ? nbViews : 0} fois.`}
      placement="top"
    >
      <Grid
        container
        direction={`row`}
        wrap="nowrap"
        style={{ color: "black" }}
        justifyContent={"center"}
        alignItems="center"
        gap="5px"
      >
        <Grid item display="grid">
          <Visibility style={{ fontSize: "16px" }} />
        </Grid>
        <Grid item display="grid">
          <span
            style={{
              fontFamily: "Poppins",
              fontSize: "14px",
            }}
          >
            {nbViews ? nbViews : 0}
          </span>
        </Grid>
      </Grid>
    </Tooltip>
  );
};

export const FullConseilCodeQR: React.FC<{
  conseil: any;
  displayViews?: boolean;
}> = ({ conseil, displayViews }) => {
  return (
    <>
      <div
        style={{
          position: "absolute",
          right: -12,
          top: displayViews ? 47 : 23,
        }}
      >
        <Tooltip
          title="Générer un code QR pour envoyer des conseils"
          placement="bottom"
        >
          <IconButton
            style={{
              margin: 0,
              padding: 0,
            }}
            onClick={() => {
              window.open(
                `${process.env.REACT_APP_WEB_APP_URL}/#/conseil_qrcode?conseilId=${conseil?.id}`,
                "_blank"
              );
            }}
          >
            <SettingsRounded
              style={{
                fontSize: "15px",
                color: "black",
              }}
            />
          </IconButton>
        </Tooltip>
      </div>
      <Grid container direction="column" wrap="nowrap" gap="2px">
        {displayViews && (
          <Grid item display="grid">
            <ViewsEye nbViews={conseil?.scanCodeQR ? conseil?.scanCodeQR : 0} />
          </Grid>
        )}
        <Grid item display="grid">
          <Tooltip title={`Lire le code QR (${conseil?.id})`} placement="top">
            <IconButton
              style={{
                margin: 0,
                padding: 0,
              }}
              onClick={() => {
                window.open(
                  `${process.env.REACT_APP_WEB_APP_URL}/#/c/${conseil?.id}`,
                  "_blank"
                ).focus;
              }}
            >
              <MyQRCode
                value={`${process.env.REACT_APP_WEB_APP_URL}/#/c/${conseil?.id}?scan=1`}
              />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
    </>
  );
};

FullConseilCodeQR.defaultProps = {
  displayViews: true,
};

export const MyQRCode = ({ value, sizeCanvas, sizeImg }) => {
  const qrCodeParentRef = React.useRef(null);
  const [canvas, setCanvas] = React.useState<any>(undefined);
  const [base64, setBase64] = React.useState<string>(undefined);

  React.useEffect(() => {
    setCanvas(qrCodeParentRef.current?.children?.[0]);
  }, [qrCodeParentRef.current]);

  React.useEffect(() => {
    if (!canvas || base64) {
      return;
    }
    const dataURL = canvas?.toDataURL();
    if (dataURL) {
      setBase64(dataURL);
    }
  }, [canvas, base64]);

  return (
    <div ref={qrCodeParentRef}>
      {base64 ? (
        <img src={base64} height={sizeImg} width={sizeImg} />
      ) : (
        <QRCodeCanvas value={value} size={sizeCanvas} />
      )}
    </div>
  );
};

MyQRCode.defaultProps = {
  sizeCanvas: 100,
  sizeImg: "30px",
};

export const getTextFromPeriod = (
  period: number,
  displaySign: boolean = false
): string => {
  let hoursCounter = Math.abs(period);
  let dayCounter = 0;
  let monthCounter = 0;
  let yearCounter = 0;

  const checkAddDisplaySign = () => {
    if (!displaySign) {
      return "";
    }
    if (period < 0) {
      return "-";
    }
    return "+";
  };

  while (hoursCounter >= 24) {
    dayCounter += 1;
    hoursCounter -= 24;
  }
  while (dayCounter >= 30) {
    monthCounter += 1;
    dayCounter -= 30;
  }
  while (monthCounter >= 12) {
    yearCounter += 1;
    monthCounter -= 12;
  }
  if (yearCounter === 0 && monthCounter === 0 && dayCounter === 0) {
    return `${checkAddDisplaySign()}${hoursCounter} heure${
      hoursCounter > 1 ? "s" : ""
    }`;
  }
  if (yearCounter === 0 && monthCounter === 0) {
    return `${checkAddDisplaySign()}${dayCounter} jour${
      dayCounter > 1 ? "s" : ""
    }`;
  }
  if (yearCounter === 0) {
    return `${checkAddDisplaySign()}${monthCounter} mois${
      dayCounter > 0 ? ` et ${dayCounter} jour${dayCounter > 1 ? "s" : ""}` : ""
    }`;
  }
  return `${checkAddDisplaySign()}${yearCounter} an${
    yearCounter > 1 ? "s" : ""
  }${monthCounter > 0 ? ` et ${monthCounter} mois` : ""}`;
};

export const getPackPeriod = (messages: any): string => {
  const hours: Array<number> = [];
  for (const message of messages) {
    hours.push(message.hours);
  }
  const min = Math.min(...hours);
  const max = Math.max(...hours);
  const period = max - min;
  const addS = messages.length > 1;

  return period === 0
    ? ` (Envoi${addS ? "s" : ""} immédiat${addS ? "s" : ""})`
    : ` sur ${getTextFromPeriod(period)}`;
};

const LastConseilComponent: React.FC<any> = (props) => {
  const dispatch = useDispatch();
  const [total, setTotal] = React.useState(0);
  const [tutorials, setTutorials] = useState([]);
  const [isDuplicatePopUp, setIsDuplicatePopUp] = useState(null);
  const [listFavorite, setListFavorite] = useState([]);
  const { isShowing, toggle } = useModal();
  const [idOpen, setIdOpen] = useState<any>({});
  const [tutorialAdded, setTutorailAdded] = useState<any>({});
  const [buttonExtendsLoading, setButtonExtendsLoading] =
    React.useState<boolean>(false);
  const [toggleFavorite, setToggleFavorite] = useState({});
  const [, fctMiseAJour] = React.useState({});
  const miseAJour = React.useCallback(() => fctMiseAJour({}), []);
  const [searchIsLoading, setSearchIsLoading] = React.useState<boolean>(true);
  const [proArray] = useSpecialities();
  const [lastConseilAddClick, setLastConseilAddClick] =
    React.useState(undefined);
  const [cannotUseUrlPopup, setCannotUseUrlPopup] = React.useState("");
  const [
    cannotUseQuestionnaireInteractifPopup,
    setCannotUseQuestionnaireInteractifPopup,
  ] = React.useState("");
  const tokensReducer = useAppSelector((state) => state.tokensReducer);
  const specialities = useAppSelector((x) => x.languageReducer.displaySpec);
  const user = useAppSelector((x) => x.userReducer.user);
  const snackbarUse = useOpenSnackbar();
  const [selectedAdviceObject, setSelectedAdviceObject] =
    React.useState(undefined);

  let count = 0;
  React.useEffect(() => {
    setSelectedAdviceObject(new SelectedAdvice());
  }, []);

  const getTokensCost = (): number => {
    if ([2, 4, "2", "4"].includes(props?.user?.user?.account_type)) {
      // Premium and administrator
      return 0;
    }
    return tokensReducer?.displayMoreConseilsCost;
  };

  useEffect(() => {
    if (!specialities) {
      return;
    }
    if (!user) {
      return;
    }
    const onRefreshFavorite = () => {
      TutorialDataService.getAll().then((response: any) => {
        setListFavorite(response.data.data.default_conseils.favourite_conseils);
        props.setFavoriteLoading([]);
      });
    };

    const OnFilter = (eventData) => {
      if (!specialities) {
        return;
      }
      if (!user) {
        return;
      }
      const { page } = eventData.value;
      const value = eventData?.value?.value
        ? eventData.value.value
        : props?.filterKey;

      if (page != "fav") {
        let dataSearch: any = {
          search: "",
        };
        if (value?.conseil) {
          dataSearch.type = value?.conseil;
        }
        if (value?.spec) {
          //dataSearch.speciality = value?.spec;
          dataSearch.specialities = specialities.find(
            (x) => x.value == value?.spec
          )?.specialities;
        }
        if (value?.attchment) {
          dataSearch.attachement = value?.attchment;
        }
        console.log(props.filterKey);
        console.log(dataSearch);
        setSearchIsLoading(true);
        TutorialDataService.search(dataSearch)
          .then((response: any) => {
            setSearchIsLoading(false);
            const result =
              response?.data?.data.search_conseils_results?.search_conseils;
            setTutorials(result);
            setTotal(response?.data?.data.search_conseils_results?.total);
          })
          .catch(() => {
            setSearchIsLoading(false);
          });
      }
    };

    const onDeleted = (id) => {
      miseAJour();
    };

    const onFullFiled = (eventData) => {
      let temp;
      eventData.map((n) => {
        if (n.conseil_favourite_ids.includes(localStorage.getItem("user_id"))) {
          temp = { ...temp, [n.id]: true };
        } else {
          temp = { ...temp, [n.id]: false };
        }
      });
      setToggleFavorite(temp);
      miseAJour();
    };

    const Added = (e) => {
      setTutorailAdded({ ...tutorialAdded, [e.text.id]: true });
      miseAJour();
    };

    const onCreated = () => {
      OnFilter({
        value: {
          page: "recents",
        },
      });
    };

    const onRefreshAll = () => {
      OnFilter({
        value: {
          page: "recents",
        },
      });
    };

    const listenerRefreshAll = EventEmitter.addListener(
      "RefreshAll",
      onRefreshAll
    );
    const listenerRefreshFavorite = EventEmitter.addListener(
      "RefreshFavorite",
      onRefreshFavorite
    );
    const listenerCreated = EventEmitter.addListener("Created", onCreated);
    const listenerFilled = EventEmitter.addListener("Fuffiled", onFullFiled);
    const listenerConseilDeleted = EventEmitter.addListener(
      "DeletedConseil",
      onDeleted
    );
    const listenerConseilAdded = EventEmitter.addListener("Added", Added);
    const listenerFilter = EventEmitter.addListener("filter", OnFilter);

    onRefreshFavorite(); //

    if (props?.filterKey?.loading === false) {
      OnFilter({
        value: {
          page: "recents",
          ...props?.filterKey,
        },
      });
    }

    return () => {
      listenerRefreshFavorite.remove();
      listenerRefreshAll.remove();
      listenerFilled.remove();
      listenerCreated.remove();
      listenerConseilDeleted.remove();
      listenerConseilAdded.remove();
      listenerFilter.remove();
    };
  }, [props?.filterKey, specialities, user]);

  const openDiv = (id) => {
    if (idOpen[id] == true) setIdOpen({ ...idOpen, [id]: false });
    else setIdOpen({ ...idOpen, [id]: true });
  };

  const tut = (tut) => {
    return tut;
  };

  const handleDelete = (tutorial) => {
    selectedAdviceObject.deleteById(tutorial.id);
    props.setSelectedAdvice(selectedAdviceObject.getAll());

    EventEmitter.emit("DeletedConseil", {
      text: tutorial,
    });
  };

  const handleAdd = async (tutorial, index) => {
    setLastConseilAddClick(tutorial?.id);
    for (const message of tutorial?.messages?.[0]) {
      if (
        message?.questionnaire_interactif_id &&
        message?.is_questionnaire_interactif_owner === false
      ) {
        setCannotUseQuestionnaireInteractifPopup(
          message.questionnaire_interactif_id
        );
        return;
      }
      if (message?.url?.includes("/#/q/") && message?.is_form_url_owner === false) {
          setCannotUseUrlPopup(message?.url);
          return;
      }
    }

    selectedAdviceObject.add(tutorial);
    props.setSelectedAdvice(selectedAdviceObject.getAll());
    EventEmitter.emit("Added", {
      text: tutorial,
    });
  };

  const handleFavorite = (tutorial) => {
    if (!listFavorite.find((element) => element?.id === tutorial?.id)) {
      const errorMessage = cannotBeAddedToFavoriteGetError(tutorial);
      if (errorMessage) {
        snackbarUse.error(errorMessage);
        return;
      }
    }
    EventEmitter.emit("Favorite", {
      id: tutorial.id,
      filterKeySearch: props.filterKeySearch,
      filterKey: props.filterKey,
      filterKeyFavourite: props.filterKeyFavourite,
    });
  };

  const displaySpec = (tutorial) => {
    if (!proArray) return "";
    for (const spec of proArray) {
      if (spec?.value === tutorial?.speciality_id) return spec?.label;
    }
  };

  const extendSearch = () => {
    const value = props?.filterKey;
    let dataSearch: any = {
      search: "",
    };
    if (tutorials?.length > 0) {
      dataSearch.start = tutorials.length;
    }
    if (value?.conseil) {
      dataSearch.type = value?.conseil;
    }
    if (value?.spec) {
      //dataSearch.speciality = value?.spec;
      dataSearch.specialities = specialities.find(
        (x) => x.value == value?.spec
      )?.specialities;
    }
    if (value?.attchment) {
      dataSearch.attachement = value?.attchment;
    }

    setButtonExtendsLoading(true);
    TutorialDataService.search(dataSearch)
      .then((response: any) => {
        setButtonExtendsLoading(false);
        if (response?.data?.success === true) {
          const result =
            response?.data?.data.search_conseils_results?.search_conseils;
          setTutorials([...tutorials, ...result]);
          setTotal(response?.data?.data.search_conseils_results?.total);
        } else {
          if (
            response?.data?.data?.[0]?.message ===
            "You don't have enough tokens."
          ) {
            snackbarUse.error("Vous n'avez pas assez jetons");
            dispatch(
              openSnackbar({
                type: "error",
                message: "Vous n'avez pas assez jetons",
                duration: 4000,
              })
            );
          } else {
            snackbarUse.error("Impossible d'afficher plus de conseils");
          }
        }
      })
      .catch((error) => {
        snackbarUse.error("Impossible d'afficher plus de conseils", error);
        setButtonExtendsLoading(false);
      });
  };

  const reduceSearch = () => {
    setTutorials((e) => e.slice(0, -10));
  };

  const confirmDuplicatePopUp = () => {
    if (!isDuplicatePopUp) return;
    setIsDuplicatePopUp(null);
    window.scrollTo({ top: 0, behavior: "smooth" });
    const tutorial = { ...isDuplicatePopUp };
    const data = {
      type: tutorial.type,
      title: tutorial.title,
      speciality_id: tutorial.speciality_id,
      conseil_profession_id: tutorial.conseil_profession_id,
      add_my_favourites: false,
      messages: [],
    };
    for (const messages of tutorial?.messages) {
      for (const message of messages) {
        data.messages.push({
          attachment: message?.attachment,
          attachment_name: message?.attachment_name,
          url: message?.url,
          short_url: message?.short_url,
          stop_sms: message?.stop_sms,
          dont_send_form_if: message?.dont_send_form_if,
          dont_send_form_if_duration: message?.dont_send_form_if_duration,
          content: message?.content,
          hours: message?.hours,
        });
      }
    }
    TutorialDataService.create(data).then((response: any) => {
      EventEmitter.emit("Created", {
        isDuplicate: true,
        filterKeyFavourite: props?.filterKeyFavourite,
        filterKeySearch: props?.filterKeySearch,
        filterKey: props?.filterKey,
        text: tutorial,
      });
    });
  };

  const cancelDuplicatePopUp = () => {
    setIsDuplicatePopUp(null);
  };

  const handleDuplicate = (tutorial) => {
    setIsDuplicatePopUp(tutorial);
  };

  const handleEdit = (tutorial) => {
    if (tutorial.validated != 0) {
      console.log(tutorial);
      dispatch(createAdviceReducer?.openAdvice({ actualAdvice: tutorial }));
    } else {
      let addToFavorites = false;

      if (
        tutorial?.conseil_favourite_ids?.includes(
          localStorage?.getItem("user_id")
        )
      )
        addToFavorites = true;
      dispatch(
        editAdviceReducer?.openAdvice({
          actualAdvice: tutorial,
          addToFavorites: addToFavorites,
        })
      );
    }
  };

  const HandleHours = (tutorial) => {
    return getPackPeriod(tutorial?.messages[0]);
  };

  if (!selectedAdviceObject) {
    return <></>;
  }

  return (
    <div>
      {cannotUseQuestionnaireInteractifPopup && (
        <CancellablePopup
          firstTitle="Sélection"
          secondTitle="de conseil"
          message="Attention, vous ne pouvez pas envoyer ce questionnaire interactif. Si vous souhaitez le réutiliser, vous devez dupliquer le conseil."
          confirmButtonText="Dupliquer"
          cancelButtonText="Annuler"
          onCancel={() => setCannotUseQuestionnaireInteractifPopup("")}
          onConfirm={async () => {
            try {
              const result = await makeAPIRequest(
                "get",
                `/chatbot/${cannotUseQuestionnaireInteractifPopup}/duplicate`,
                null,
                "v3"
              );
              const newIdQuestionnaireInteractif = result?.data?.insertedId;
              if (!newIdQuestionnaireInteractif) {
                throw new Error(
                  "Impossible de dupliquer ce questionnaire interactif."
                );
              }
              const toDuplicate = tutorials.find(
                (x) => x?.id == lastConseilAddClick
              );
              const copyMessages = toDuplicate?.messages?.[0]?.map((x) => {
                const cp = { ...x };
                delete cp?.id;
                if (
                  cp?.questionnaire_interactif_id ==
                  cannotUseQuestionnaireInteractifPopup
                ) {
                  cp.questionnaire_interactif_id = newIdQuestionnaireInteractif;
                }
                return cp;
              });
              const data = {
                type: toDuplicate?.type,
                add_my_favourites: false,
                title: toDuplicate?.title,
                speciality_id: toDuplicate?.speciality_id,
                messages: copyMessages,
              };
              TutorialDataService.create(data)
                .then(() => {
                  EventEmitter.emit("Created", {
                    text: data,
                  });
                  if (data?.add_my_favourites) {
                    EventEmitter.emit("RefreshFavorite");
                  }
                  dispatch(
                    openSnackbar({
                      message:
                        "Le questionnaire interactif a bien été dupliqué, un nouveau conseil a été créé",
                      duration: 5000,
                      type: "success",
                    })
                  );
                  setCannotUseQuestionnaireInteractifPopup("");
                })
                .catch((err) => {
                  dispatch(
                    openSnackbar({
                      message: "Impossible de créer le nouveau conseil",
                      duration: 3000,
                      type: "error",
                    })
                  );
                });
            } catch (err) {
              snackbarUse.error(
                "Impossible de dupliquer ce questionnaire interactif.",
                err
              );
            }
          }}
        />
      )}
      {cannotUseUrlPopup && (
        <CancellablePopup
          firstTitle="Sélection"
          secondTitle="de conseil"
          message="Attention, vous ne pouvez pas envoyer ce questionnaire. Si vous souhaitez le réutiliser, vous devez dupliquer le conseil."
          confirmButtonText="Dupliquer"
          cancelButtonText="Annuler"
          onCancel={() => setCannotUseUrlPopup("")}
          onConfirm={() => {
            makeAPIRequest(
              "post",
              "/questions/form/create_from_url",
              { url: cannotUseUrlPopup },
              "v3"
            )
              .then((res) => {
                const newFormId = res?.data?.id;
                const toDuplicate = tutorials.find(
                  (x) => x?.id == lastConseilAddClick
                );
                const copyMessages = toDuplicate?.messages?.[0]?.map((x) => {
                  const cp = { ...x };
                  delete cp?.id;
                  if (cp?.url == cannotUseUrlPopup) {
                    cp.url = cp.url.replace(
                      cannotUseUrlPopup?.split("/#/q/")?.[1],
                      newFormId
                    );
                  }
                  return cp;
                });
                const data = {
                  type: toDuplicate?.type,
                  add_my_favourites: false,
                  title: toDuplicate?.title,
                  speciality_id: toDuplicate?.speciality_id,
                  messages: copyMessages,
                };
                TutorialDataService.create(data)
                  .then(() => {
                    EventEmitter.emit("Created", {
                      text: data,
                    });
                    if (data?.add_my_favourites) {
                      EventEmitter.emit("RefreshFavorite");
                    }
                    dispatch(
                      openSnackbar({
                        message:
                          "Le questionnaire a bien été dupliqué, un nouveau conseil a été créé",
                        duration: 5000,
                        type: "success",
                      })
                    );
                    setCannotUseUrlPopup("");
                  })
                  .catch((err) => {
                    dispatch(
                      openSnackbar({
                        message: "Impossible de créer le nouveau conseil",
                        duration: 3000,
                        type: "error",
                      })
                    );
                  });
              })
              .catch((err) => {
                dispatch(
                  openSnackbar({
                    message:
                      err?.response?.data?.error &&
                      err?.response?.data?.error != "Internal Server Error"
                        ? err.response.data.error
                        : `Impossible de trouver ce questionnaire`,
                    duration: 3000,
                    type: "error",
                  })
                );
              });
          }}
        />
      )}
      {isDuplicatePopUp && (
        <CancellablePopup
          firstTitle="Dupliquer"
          secondTitle="ce conseil ?"
          message="Voulez-vous vraiment dupliquer ce conseil ?"
          confirmButtonText="Oui"
          cancelButtonText="Non"
          onCancel={cancelDuplicatePopUp}
          onConfirm={confirmDuplicatePopUp}
        />
      )}
      <div className="col-md-12">
        {props.filterKey?.spec && props.filterKey?.spec?.length > 0 ? (
          <span className="title-in-tabs">
            Résultat de la recherche pour{" "}
            {specialities
              ?.find((element) => element?.value == props.filterKey.spec)
              ?.label?.toLowerCase()}
          </span>
        ) : (
          <span className="title-in-tabs">Recherches récentes</span>
        )}
        <ul className="list-group">
          {searchIsLoading && (
            <div
              style={{
                paddingTop: "15px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <CircularProgress />
            </div>
          )}
          {!searchIsLoading && (!tutorials || tutorials?.length === 0) && (
            <div style={{ paddingTop: "15px" }}>
              <p style={{ fontSize: "16px", fontFamily: "Poppins" }}>
                Aucun résultat trouvé
              </p>
            </div>
          )}
          {tutorials &&
            tutorials?.length > 0 &&
            !searchIsLoading &&
            tutorials.map((tutorial, index) => (
              <li
                className={"conseil-card"}
                key={tutorial.id}
                style={{ paddingTop: 0 }}
              >
                <div className="conseil-card-title">
                  {tutorial.type == "pack" ? (
                    <div
                      style={{
                        display: "flex",
                        background: "#5BB9C1 0% 0% no-repeat padding-box",
                        borderRadius: " 0px 0px 10px 10px",
                        width: "51px",
                        height: "28px",
                      }}
                    >
                      <p style={{ margin: "auto", color: "white" }}>Pack</p>
                    </div>
                  ) : (
                    <div style={{ paddingTop: "10px" }}></div>
                  )}
                  {idOpen[tutorial.id] == true ? null : (
                    <Grid container direction="row" wrap="nowrap">
                      <Grid item xs display="grid">
                        <div className="title_detail">
                          {!selectedAdviceObject.isSelectedById(
                            tutorial.id
                          ) && (
                            <p
                              style={{ margin: "0" }}
                              className="titre_Conseil"
                            >
                              {tutorial.title}
                              <br />
                            </p>
                          )}

                          <p
                            style={{ margin: "0", marginLeft: "0" }}
                            className="titre_Conseil"
                          >
                            {removeParenthesis(displaySpec(tutorial))}
                          </p>

                          {tutorial.type == "pack" ? (
                            <p style={{ margin: "0", marginLeft: "0" }}>
                              Détail du pack : {tutorial.messages[0].length}{" "}
                              messages
                              {HandleHours(tutorial)}
                            </p>
                          ) : null}
                          {tutorial.display_name === "1" && (
                            <p
                              style={{
                                margin: "0",
                                marginLeft: "0",
                                fontSize: "14px",
                                color: "#6b6b6b",
                                fontStyle: "italic",
                              }}
                            >
                              Auteur : {tutorial.display_name_str}
                            </p>
                          )}
                          {tutorial.validated == 0
                            && `en cours de validation`
                          }
                        </div>
                      </Grid>
                      <Grid
                        item
                        display="grid"
                        style={{
                          marginLeft: 20,
                          marginRight: 20,
                          marginBottom: 5,
                          marginTop: 5,
                          height: `fit-content`,
                          width: "fit-content",
                          position: "relative",
                        }}
                      >
                        <Grid
                            container
                            direction="column"
                            wrap="nowrap"
                            gap="10px"
                          >
                            <Grid item display="grid">
                              <FullConseilCodeQR
                                displayViews={true}
                                conseil={tutorial}
                              />
                            </Grid>
                            {user?.see_credits == 1 && tutorial?.credits && (
                              <Grid item display="grid">
                                <p
                                  style={{
                                    margin: "0",
                                    marginLeft: "0",
                                    fontSize: "12px",
                                    color: "#6b6b6b",
                                  }}
                                >
                                  {tutorial.credits} crédit
                                  {tutorial.credits > 1 ? "s" : ""}
                                </p>
                              </Grid>
                            )}
                          </Grid>
                      </Grid>
                    </Grid>
                  )}
                </div>
                {selectedAdviceObject.isSelectedById(tutorial.id) === true ? (
                  <div className="conseilAded">
                    <div className="cross">
                      <ImCross
                        onClick={() => handleDelete(tutorial)}
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                    <div
                      className="vl-l"
                      style={{ width: "0px", margin: "auto" }}
                    ></div>
                    <div style={{ margin: "auto", marginLeft: "25px" }}>
                      <p
                        style={{
                          margin: "0px",
                          fontFamily: "Poppins",
                          fontSize: "16px",
                          fontWeight: "medium",
                          color: "#23595D",
                        }}
                      >
                        {tutorial.title}
                      </p>
                      <p style={{ margin: "0px" }}>
                        {tutorial.type == "pack" ? (
                          <span>Pack de conseils déjà sélectionné</span>
                        ) : (
                          <span>Conseil déjà sélectionné</span>
                        )}
                      </p>
                    </div>
                    <div
                      className="vl-l"
                      style={{ width: "0px", margin: "auto" }}
                    />
                    <section
                      className="FlexContainer-r"
                      style={{ margin: "auto", marginRight: "-5px" }}
                    >
                      <div>
                        <DisplayFavorite
                          favoriteLoading={props.favoriteLoading}
                          handleFavorite={handleFavorite}
                          listFavorite={listFavorite}
                          tutorial={tutorial}
                        />
                      </div>
                      <button className="icon_button modal-toggle">
                        <IconEdit onClick={() => handleEdit(tutorial)} />
                      </button>
                      <EditConseilModal
                        isShowing={isShowing}
                        favoriteList={listFavorite}
                        hide={toggle}
                        id={tutorial.id}
                        dataConseil={tut(tutorials)}
                        {...props}
                      />
                    </section>
                  </div>
                ) : (
                  <div></div>
                )}
                {!selectedAdviceObject.isSelectedById(tutorial.id) && (
                  <NotSelectedCardBody
                    tutorial={tutorial}
                    index={index}
                    handleAdd={handleAdd}
                    handleDuplicate={handleDuplicate}
                    handleEdit={handleEdit}
                    idOpen={idOpen}
                    DisplayConseil={
                      <DisplayConseil
                        idOpen={idOpen}
                        tutorial={tutorial}
                        openDiv={openDiv}
                      />
                    }
                    DisplayFavorite={
                      <DisplayFavorite
                        favoriteLoading={props.favoriteLoading}
                        handleFavorite={handleFavorite}
                        listFavorite={listFavorite}
                        tutorial={tutorial}
                      />
                    }
                    isShowing={isShowing}
                    favoriteList={listFavorite}
                    toggle={toggle}
                    dataConseil={tut(tutorials)}
                    {...props}
                  />
                )}
              </li>
            ))}
        </ul>

        <Grid container direction="row" justifyContent="center" spacing="15px">
          {!searchIsLoading &&
            !buttonExtendsLoading &&
            tutorials?.length > 10 && (
              <Grid item display="grid">
                <button
                  className="more-button"
                  style={{ marginTop: "25px" }}
                  onClick={() => reduceSearch()}
                >
                  Afficher moins
                </button>
              </Grid>
            )}
          {!searchIsLoading &&
            !buttonExtendsLoading &&
            total > tutorials?.length && (
              <>
                <Grid item display="grid">
                  <button
                    className="more-button"
                    style={{ marginTop: "25px" }}
                    onClick={() => extendSearch()}
                  >
                    Afficher plus{/*({total - tutorials?.length} restants)*/}
                  </button>
                </Grid>
              </>
            )}
          {buttonExtendsLoading && (
            <Grid item display="grid" marginTop="35px">
              <CircularProgress
                style={{ color: "#657273", width: "30px", height: "30px" }}
              />
            </Grid>
          )}
        </Grid>
        {getTokensCost() > 0 &&
          [0, 1, 3, "0", "1", "3"].includes(
            props?.user?.user?.account_type
          ) && (
            <Grid item display="grid" marginTop="20px" justifyContent="center">
              <span
                style={{
                  fontFamily: "Poppins",
                  color: "rgb(101, 114, 115)",
                  fontSize: "14px",
                }}
              >
                * Vous possèdez un compte{" "}
                {props?.user?.user?.account_type == 0 && "de test"}
                {props?.user?.user?.account_type == 3 && "de test"}
                {props?.user?.user?.account_type == 1 && "classique"}, afficher
                plus de conseils vous coûtera {getTokensCost()} jeton(s)
              </span>
            </Grid>
          )}
        <br />
        <div
          className="horizontal-bar"
          style={{
            width: "100%",
            border: "1px solid #e6ebf0",
          }}
        />
        <br />
      </div>
    </div>
  );
};

export default LastConseilComponent;
