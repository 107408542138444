import * as React from "react";
import { CircularProgress, Grid } from "@mui/material";
import { useDispatch } from "react-redux";
import FormPopup from "../FormPopup/FormPopup";
import FormPopupHeader from "../FormPopup/FormPopupHeader";
import * as createScheduleReducer from "../../redux/scheduleQuestionnaireReducer";
import * as editScheduleReducer from "../../redux/editFormScheduleReducer";
import FullField from "../Fields/FullField";
import Creatable from "react-select/creatable";
import OrangeButton from "../Library/Buttons/OrangeButton/OrangeButton";
import ErrorHelp from "../Library/Errors/ErrorHelp";
import makeAPIRequest from "../../utils/makeAPIRequest";
import { openSnackbar } from "../../redux/snackbarReducer";
import MultilineInput from "../Library/Inputs/MultilineInput";
import OrangeCheckBox from "../Fields/CheckBox/OrangeCheckBox";
import TextInput from "../Library/Inputs/TextInput";
import { Check } from "@mui/icons-material";
import GroupCheckBoxField from "../Fields/CheckBox/GroupCheckBoxField";
import useAppSelector from "../../redux/useAppSelector";

const errorMessages = {
  emptyPatients: "Aucun patient n'est selectionné.",
  emptyDate: "La date de déclenchement n'est pas renseignée.",
  emptyTime: "L'heure de déclenchement n'est pas renseignée.",
  emptySMS: "Le contenu du SMS ne peut pas être vide.",
  badDays: "Le nombre de jours est invalide",
  emptyLink: "Le contenu du SMS doit contenir le lien du questionnaire.",
  emptyEmailSubject: "L'objet du mail ne peut pas être vide",
  "": "",
};

const errorSelector = {
  emptyPatients: "#schedule-questionnaire-list-patients",
  emptyDate: "#schedule-questionnaire-date",
  emptyTime: "#schedule-questionnaire-time",
  badDays: "#schedule-questionnaire-alert-days",
  emptySMS: "#schedule-questionnaire-sms-content",
  emptyEmailSubject: "#schedule-questionnaire-mail-subject",
  emptyLink: "#schedule-questionnaire-sms-content",
  "": "",
};

const ConfigureSchedule: React.FC<any> = (props) => {
  const { reducer, reducerFunction, isEdit } = props;
  const [sendLoading, setSendLoading] = React.useState<boolean>(false);

  const [smsContent, setSmsContent] = React.useState(
    isEdit
      ? reducer.formScheduleData?.smsContent
      : "Bonjour {nom_patient} {prenom_patient},\nVoici le questionnaire : {lien_questionnaire}"
  );
  const [receiveSMSAfterPatientResponse, setReceiveSMSAfterPatientResponse] =
    React.useState(
      isEdit ? reducer.formScheduleData?.receiveSMSAfterPatientResponse : false
    );
  const [receiveSMSIfNoResponse, setReceiveSMSIfNoResponse] = React.useState(
    isEdit ? reducer.formScheduleData?.receiveSMSIfNoResponse : false
  );
  const [receiveSMSIfNoResponseDays, setReceiveSMSIfNoResponseDays] =
    React.useState(
      isEdit ? reducer.formScheduleData?.receiveSMSIfNoResponseDays : "2"
    );
  const [type, setType] = React.useState(
    isEdit ? reducer.formScheduleData?.type : "sms"
  );
  const [emailSubject, setEmailSubject] = React.useState(
    isEdit ? reducer.formScheduleData?.emailSubject : "Questionnaire CertiPair"
  );
  const [patientSearched, setPatientSearched] = React.useState(null);
  const [patientList, setPatientList] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);

  let date = new Date();
  if (isEdit) {
    date = new Date(
      reducer.formScheduleData?.dateTrigger -
        new Date().getTimezoneOffset() * 60000
    );
  } else {
    date = new Date(date.getTime() - date.getTimezoneOffset() * 60000);
  }

  const [dateInput, setDateInput] = React.useState(
    date.toISOString().split("T")[0]
  );
  const [timeInput, setTimeInput] = React.useState(
    date.toISOString().split("T")[1].substring(0, 5)
  );
  const [error, setError] = React.useState("");
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (isEdit) {
      setIsLoading(false);
      return;
    }
    setIsLoading(true);
    makeAPIRequest("get", `/patients/list`, null, "v3").then((res: any) => {
      setPatientList(res?.data);
      setIsLoading(false);
    });
  }, []);

  const submit = isEdit ? "Modifier" : "Envoyer";

  const isValidSubmit = () => {
    const invalidConditions = {
      emptyPatients:
        !isEdit && (!patientSearched || patientSearched?.length === 0),
      emptyDate: !dateInput,
      emptyTime: !timeInput,
      badDays: receiveSMSIfNoResponse && !receiveSMSIfNoResponseDays,
      emptyEmailSubject: type === "email" && !emailSubject,
      emptySMS: !smsContent,
      emptyLink: !smsContent.includes("{lien_questionnaire}"),
    };

    for (const condition of Object.keys(invalidConditions)) {
      if (invalidConditions[condition]) {
        if (!errorMessages?.[condition]) {
          return false;
        }
        if (error !== condition) setError(condition);
        return false;
      }
    }
    if (error !== "") setError("");
    return true;
  };

  function isNumber(str) {
    if (typeof str != "string") return false;

    return !isNaN(str as any) && !isNaN(parseFloat(str));
  }

  const formatPatientsIds = () => {
    const result = [];
    for (const patient of patientSearched) {
      result.push(patient?.value);
    }
    return result;
  };

  if (isLoading) {
    return (
      <Grid
        container
        direction="row"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <CircularProgress />
      </Grid>
    );
  }

  return (
    <Grid container direction="column" wrap="nowrap">
      <Grid item display="grid">
        <GroupCheckBoxField
          title="Type"
          isMandatory={true}
          listCheckbox={[
            {
              text: "SMS",
              value: type === "sms",
              onClick: () => {
                setType("sms");
                setPatientSearched([]);
              },
            },
            {
              text: "Email",
              value: type === "email",
              onClick: () => {
                setType("email");
                setPatientSearched([]);
              },
            },
          ]}
        />
      </Grid>
      {!isEdit && (
        <Grid item display="grid" paddingTop="15px">
          <FullField isMandatory={true} title="Choix des patients">
            <div
              id="schedule-questionnaire-list-patients"
              style={{
                borderRadius: "10px",
                width: "100%",
                border: "1px solid transparent",
              }}
            >
              <Creatable
                noOptionsMessage={() => "Aucune option"}
                formatCreateLabel={(userInput) => `...`}
                isMulti={true}
                maxMenuHeight={150}
                options={(() => {
                  const optionsTmp = [];
                  if (!patientList) return optionsTmp;
                  for (const patient of patientList) {
                    if (type === "sms") {
                      if (patient?.phone) {
                        optionsTmp.push({
                          value: patient?.id,
                          label: patient?.lastname + " " + patient?.firstname,
                        });
                      }
                    }
                    if (type == "email") {
                      if (patient?.email) {
                        optionsTmp.push({
                          value: patient?.id,
                          label: patient?.lastname + " " + patient?.firstname,
                        });
                      }
                    }
                  }
                  return optionsTmp;
                })()}
                value={patientSearched}
                placeholder="Liste des patients"
                onChange={(e) => {
                  const newPatients = [];
                  for (const patient of e) {
                    if (patientList?.find((p) => p?.id === patient?.value))
                      newPatients.push(patient);
                  }
                  setPatientSearched(newPatients);
                }}
              />
            </div>
          </FullField>
        </Grid>
      )}
      {type === "email" && (
        <Grid item display="grid" paddingTop="15px">
          <FullField isMandatory={true} title={`Objet du mail`}>
            <TextInput
              id="schedule-questionnaire-mail-subject"
              onChange={(e) => setEmailSubject(e?.target?.value)}
              value={emailSubject}
            />
          </FullField>
        </Grid>
      )}
      <Grid item display="grid" paddingTop="15px">
        <FullField
          isMandatory={true}
          title={`Contenu du ${type === "sms" ? "SMS" : "mail"}`}
        >
          <Grid container direction="column" wrap="nowrap">
            <Grid item display="grid">
              <span
                style={{
                  color: "#0B243C",
                  fontWeight: "500",
                  fontFamily: "Poppins",
                  letterSpacing: "0px",
                  fontSize: "14px",
                }}
              >
                Vous pouvez utiliser les variables ci-dessous :
              </span>
            </Grid>
            <Grid item display="grid">
              <span
                style={{
                  color: "#0B243C",
                  fontWeight: "500",
                  fontFamily: "Poppins",
                  letterSpacing: "0px",
                  fontSize: "14px",
                }}
              >
                {"{nom_patient} - {prenom_patient} - {lien_questionnaire}"}
              </span>
            </Grid>
            <Grid item display="grid" paddingTop="5px">
              <MultilineInput
                id="schedule-questionnaire-sms-content"
                maxLength={type === "sms" ? 325 : 50000}
                onChange={(e) => setSmsContent(e)}
                value={smsContent}
                height={"80px"}
              />
            </Grid>
          </Grid>
        </FullField>
      </Grid>
      <Grid item display="grid" paddingTop="15px">
        <FullField isMandatory={true} title="Date de déclenchement">
          <Grid
            container
            direction="row"
            alignItems="center"
            wrap="nowrap"
            spacing="10px"
          >
            <Grid item display="grid">
              <span>Le</span>
            </Grid>
            <Grid item display="grid">
              <input
                id="schedule-questionnaire-date"
                type="date"
                style={{
                  minWidth: "100%",
                  border: "1px solid #D3E0E0",
                  background: "#EDF2F280 0% 0% no-repeat padding-box",
                  borderRadius: "10px",
                  paddingRight: "20px",
                  paddingLeft: "20px",
                  color:
                    dateInput && dateInput?.length > 0 ? "#0B243C" : "#657273",
                  fontFamily: "Poppins",
                  fontSize: "16px",
                  height: "42px",
                }}
                value={dateInput}
                onChange={(e) => setDateInput(e.target.value)}
              />
            </Grid>
            <Grid item display="grid">
              <span>à</span>
            </Grid>
            <Grid item display="grid">
              <input
                id="schedule-questionnaire-time"
                type="time"
                style={{
                  minWidth: "100%",
                  border: "1px solid #D3E0E0",
                  background: "#EDF2F280 0% 0% no-repeat padding-box",
                  borderRadius: "10px",
                  paddingRight: "20px",
                  paddingLeft: "20px",
                  color:
                    timeInput && timeInput?.length > 0 ? "#0B243C" : "#657273",
                  fontFamily: "Poppins",
                  fontSize: "16px",
                  height: "42px",
                }}
                value={timeInput}
                onChange={(e) => setTimeInput(e.target.value)}
              />
            </Grid>
          </Grid>
        </FullField>
      </Grid>
      <Grid item display="grid" paddingTop="15px">
        <FullField isMandatory={false} title="Alertes">
          <Grid container direction="column" spacing="5px" wrap="nowrap">
            <Grid item display="grid">
              <OrangeCheckBox
                value={receiveSMSAfterPatientResponse}
                onClick={() => setReceiveSMSAfterPatientResponse((b) => !b)}
                text="Recevoir un SMS après réponse du patient"
              />
            </Grid>
            <Grid item display="grid">
              <Grid
                container
                direction="row"
                spacing="10px"
                alignItems="center"
                wrap="nowrap"
              >
                <Grid item display="grid">
                  <div
                    style={{
                      background: !receiveSMSIfNoResponse
                        ? "#EDF2F2 0% 0% no-repeat padding-box"
                        : "#FFA500 0% 0% no-repeat padding-box",
                      width: "22px",
                      height: "22px",
                      borderRadius: "5px",
                      border: "1px solid #D3E0E0",
                      opacity: 1,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {receiveSMSIfNoResponse && (
                      <Check
                        onClick={() => setReceiveSMSIfNoResponse((b) => !b)}
                        fontSize="small"
                        style={{ paddingLeft: "2px", color: "white" }}
                      />
                    )}
                    <input
                      type="checkbox"
                      className="checkbox_modals"
                      checked={receiveSMSIfNoResponse}
                      onChange={() => {
                        return null;
                      }}
                      onClick={() => setReceiveSMSIfNoResponse((b) => !b)}
                      style={{
                        padding: "0",
                        margin: "0",
                        borderRadius: "5px",
                        width: "100%",
                        height: "100%",
                        opacity: 0,
                      }}
                    />
                  </div>
                </Grid>
                <Grid item display="grid">
                  <Grid
                    container
                    direction="row"
                    alignItems="center"
                    spacing="5px"
                  >
                    <Grid item display="grid">
                      <span
                        className="text_champs"
                        style={{ margin: "0px", padding: "0px" }}
                      >
                        Recevoir un SMS si aucune réponse n'est reçue, après
                      </span>
                    </Grid>
                    <Grid item display="grid" maxWidth="39px">
                      <TextInput
                        id="schedule-questionnaire-alert-days"
                        textAlign="center"
                        padding="2px 7px 2px 7px"
                        value={receiveSMSIfNoResponseDays}
                        onChange={(e) => {
                          if (!e.target.value)
                            setReceiveSMSIfNoResponseDays("");
                          else if (
                            isNumber(e.target.value) &&
                            parseInt(e.target.value) > 0
                          )
                            setReceiveSMSIfNoResponseDays(e.target.value);
                        }}
                      />
                    </Grid>
                    <Grid item display="grid">
                      <span
                        className="text_champs"
                        style={{ margin: "0px", padding: "0px" }}
                      >
                        jour(s)
                      </span>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </FullField>
      </Grid>
      <Grid container direction="column" paddingTop="30px" wrap="nowrap">
        {error?.length > 0 && (
          <Grid item display="grid">
            <ErrorHelp
              errorMessages={errorMessages}
              errorSelector={errorSelector}
              error={error}
            />
          </Grid>
        )}
        <Grid item display="grid" justifyContent="center">
          {isValidSubmit() ? (
            <OrangeButton
              animation={false}
              enable={true}
              isLoading={sendLoading}
              type="submit"
              text={submit}
              onClick={
                isEdit
                  ? () => {
                      const toSend = {
                        dateTrigger: Date.parse(
                          dateInput + "T" + timeInput + ":00"
                        ),
                        smsContent: smsContent,
                        receiveSMSAfterPatientResponse:
                          receiveSMSAfterPatientResponse,
                        receiveSMSIfNoResponse: receiveSMSIfNoResponse,
                        receiveSMSIfNoResponseDays: parseInt(
                          receiveSMSIfNoResponseDays
                        ),
                        emailSubject: emailSubject,
                        type: type,
                      };
                      setSendLoading(true);
                      makeAPIRequest(
                        "post",
                        `questions/form/schedule/${reducer.formScheduleData?.formScheduleId}/update`,
                        toSend,
                        "v3"
                      )
                        .then((res) => {
                          const newObject = res?.data;
                          props?.setAllScheduled((x) => {
                            return x.map((y) => {
                              if (
                                y?.formScheduleId ==
                                reducer.formScheduleData?.formScheduleId
                              ) {
                                return { ...y, ...newObject };
                              }
                              return y;
                            });
                          });
                          dispatch(reducerFunction.closeSchedule());
                        })
                        .catch((err) => {
                          dispatch(
                            openSnackbar({
                              message:
                                "La modification de votre questionnaire a échouée",
                              type: "error",
                              duration: 3000,
                            })
                          );
                        }).finally(() => setSendLoading(false));
                    }
                  : () => {
                      const toSend = {
                        formId: reducer?.questionnaireId,
                        patients: formatPatientsIds(),
                        dateTrigger: Date.parse(
                          dateInput + "T" + timeInput + ":00"
                        ),
                        smsContent: smsContent,
                        receiveSMSAfterPatientResponse:
                          receiveSMSAfterPatientResponse,
                        receiveSMSIfNoResponse: receiveSMSIfNoResponse,
                        receiveSMSIfNoResponseDays: parseInt(
                          receiveSMSIfNoResponseDays
                        ),
                        emailSubject: emailSubject,
                        type: type,
                      };
                      setSendLoading(true);
                      makeAPIRequest(
                        "post",
                        "/questions/form/schedule",
                        toSend,
                        "v3"
                      )
                        .then((res) => {
                          dispatch(
                            openSnackbar({
                              message:
                                "Votre questionnaire a bien été programmé",
                              type: "success",
                              duration: 3000,
                            })
                          );
                          dispatch(reducerFunction.closeSchedule());
                        })
                        .catch((err) => {
                          dispatch(
                            openSnackbar({
                              message:
                                "La programmation de votre questionnaire a échouée",
                              type: "error",
                              duration: 3000,
                            })
                          );
                        }).finally(() => setSendLoading(false)); 
                      console.log(toSend);
                    }
              }
            />
          ) : (
            <OrangeButton
              animation={false}
              enable={false}
              isLoading={sendLoading}
              type="submit"
              text={"Envoyer"}
              onClick={() => {
                return null;
              }}
            />
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

const StartQuestionnaire: React.FC<any> = (props) => {
  const { isEdit } = props;
  const reducerCreate = useAppSelector(
    (state) => state.scheduleQuestionnaireReducer
  );
  const reducerEdit = useAppSelector((state) => state.editFormScheduleReducer);
  const [reducer, setReducer] = React.useState(reducerCreate);
  const [reducerFunction, setReducerFunction] = React.useState<any>(
    createScheduleReducer
  );
  const [title, setTitle] = React.useState("Programmer");
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (isEdit) {
      setReducerFunction(editScheduleReducer);
      setReducer(reducerEdit as any);
      setTitle("Modifier");
    } else {
      setReducerFunction(createScheduleReducer);
      setReducer(reducerCreate);
      setTitle("Programmer");
    }
  }, [isEdit]);

  if (isEdit && !(reducer as any)?.formScheduleData) {
    return <></>;
  }

  return (
    <FormPopup>
      <Grid
        item
        display="grid"
        paddingTop="40px"
        paddingLeft="50px"
        paddingRight="50px"
        paddingBottom="40px"
      >
        <FormPopupHeader
          firstTitle={title}
          secondTitle={"un envoi"}
          onClose={() => {
            dispatch(reducerFunction.closeSchedule());
          }}
        />
      </Grid>
      <Grid item display="grid">
        <Grid
          container
          display="flex"
          width="70%"
          justifySelf="center"
          direction="column"
          wrap="nowrap"
          paddingBottom="40px"
        >
          <Grid item display="grid">
            <span
              style={{
                fontFamily: "Poppins",
                fontSize: "18px",
                color: "rgb(11, 36, 60)",
              }}
            >
              {reducer?.title}
            </span>
          </Grid>
          <Grid item display="grid" paddingTop="20px">
            <ConfigureSchedule
              isEdit={isEdit}
              reducerFunction={reducerFunction}
              reducer={reducer}
              {...props}
            />
          </Grid>
        </Grid>
      </Grid>
    </FormPopup>
  );
};

export default StartQuestionnaire;
