import * as React from "react";
import { GenericLongText } from "../../../pages/CreateOrEditChatbotLexPage";

const DisplayBeforeMessage: React.FC<any> = (props) => {
  const { chatbotData, setChatbotData } = props;

  return (
    <GenericLongText
      usableVariables={["nom_patient", "prénom_patient", "civilité_patient", "date_declenchement", "heure_declenchement", "heure_declenchement + Xmin", "heure_declenchement - Xmin", "jour_declenchement"]}
      fieldTitle="SMS d'introduction"
      isMandatory={false}
      id="chatbot-before-sms-input"
      maxLength={325}
      value={chatbotData?.smsBefore}
      onChange={(e) => {
        setChatbotData((x) => {
          return {
            ...x,
            smsBefore: e ? e : "",
          };
        });
      }}
    />
  );
};

export default DisplayBeforeMessage;
