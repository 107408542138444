import { CircularProgress, Grid } from "@mui/material";
import * as React from "react";
import "./RecipientContainer.scss";
import { ReactComponent as IconSection } from "../../../assets/IconSection.svg";
import "../../../styles/PatientCard.scss";
import { AiOutlineDown, AiOutlineUp } from "react-icons/ai";
import {
  AddCircleRounded,
  Check,
  ClearRounded,
  ContactPhone,
  Done,
  DoneOutlined,
} from "@mui/icons-material";
import TextInput from "../../Library/Inputs/TextInput";
import { Dropdown } from "react-bootstrap";
import * as createPatientReducer from "../../../redux/createPatientReducer";
import * as selectPatientReducer from "../../../redux/selectPatientReducer";
import * as editPatientReducer from "../../../redux/editPatientReducer";
import * as snackbarReducer from "../../../redux/snackbarReducer";
import isValidPhoneNumber from "../../../utils/isValidPhoneNumber";
import { useDispatch } from "react-redux";
import EventEmitter from "../../../services/event";
import { nanoid } from "nanoid";
import InformationPopup from "../../Library/Popups/InformationPopup";
import BoldOnlyThisCharacters from "../../Library/Text/BoldOnlyThisCharacters";
import makeAPIRequest from "../../../utils/makeAPIRequest";
import { openHelpInstallationVitale } from "../../../redux/helpInstallationVitaleReducer";
import useOutsideClick from "../../../utils/hooks/useOutsideClick";
import useAppSelector from "../../../redux/useAppSelector";
import { lastIsValidPhoneNumber } from "../../MonCompteContent/MonCompteContent";
import { GenericSelectPatient } from "../../Messages/MessagesContent";
import { useOpenSnackbar } from "../../../pages/CreateOrEditChatbotLexPage";
import { ButtonIconWithTooltip } from "../../Questionnaire/PDF/CreateOrEditPDFQuestionnaire";
import { GenericFromPopup } from "../../../pages/ChatbotLexPage";

const formatPhone = (toFormat) => {
  let newPhone = "";

  if (toFormat?.length === 10) {
    for (let i = 0; i < 10; i++) {
      newPhone += toFormat[i];
      if (i % 2) newPhone += " ";
    }
  } else {
    newPhone = toFormat;
  }
  return newPhone;
};

const addNewPatient = (input: string, dispatch: any) => {
  const saved = input;
  let firstname = "";
  let lastname = "";
  let phone = "";
  if ([" "].includes(saved)) {
    const splited = saved.split(" ");
    if (splited?.length >= 2) {
      firstname = splited[0];
      lastname = splited[1];
      phone = "";
    }
  } else {
    if (isValidPhoneNumber(saved)) {
      firstname = "";
      lastname = "";
      phone = saved;
      dispatch(selectPatientReducer.addPhone(saved));
      return;
    } else {
      firstname = "";
      lastname = saved;
      phone = "";
    }
  }
  dispatch(
    createPatientReducer?.openPatient({
      lastname: lastname,
      firstname: firstname,
      phone: phone,
    })
  );
};

const MultiplePhoneRecip: React.FC<any> = (props) => {
  return (
    <>
      <Grid item>
        <div
          className="setFreeText"
          style={{ paddingBottom: "0px", paddingTop: "10px" }}
        >
          <p style={{ padding: "0", margin: "0" }}>
            Ou plusieurs n° en même temps :{" "}
          </p>
          <span
            className="freeTextDisplay"
            style={{ padding: "0", margin: "0" }}
            onClick={() => props.setIsMultipleRecip(!props.isMultipleRecip)}
          >
            <Grid container direction="row" alignItems="center">
              <Grid item display="grid" paddingLeft="5px">
                {props?.isMultipleRecip ? "Saisir" : "Saisir"}
              </Grid>
              <Grid item display="grid" paddingLeft="5px">
                {props.isMultipleRecip ? <AiOutlineUp /> : <AiOutlineDown />}
              </Grid>
            </Grid>
          </span>
        </div>
      </Grid>
      {props.isMultipleRecip && (
        <Grid item paddingTop="10px">
          <Grid container direction="row" display="flex" alignItems="center">
            <Grid item display="grid" xs>
              <input
                id="multiple-recipe-input"
                value={props.phonesInput}
                type="text"
                placeholder="0699756785 0745698562 0645987452"
                onChange={(e) => {
                  props.setPhonesInput(e.target.value);
                }}
                className="input-phone-multiple border-animation"
                style={{
                  borderColor:
                    props?.showError &&
                    props?.error === "InvalidMultipleRecipPhones"
                      ? "#FF3100"
                      : null,
                }}
              />
            </Grid>
            <Grid item display="grid" paddingLeft="4px">
              {props.isValidPhonesInput() && (
                <Done style={{ color: "#5bb9c1" }} />
              )}
            </Grid>
          </Grid>
          <Grid item paddingTop="5px">
            <span
              style={{
                paddingLeft: "1px",
                textAlign: "left",
                font: "normal normal normal 14px Poppins",
                letterSpacing: "0px",
                color: "#657273",
              }}
            >
              {props.phonesInputMessage}
            </span>
          </Grid>
        </Grid>
      )}
    </>
  );
};

const InputPatient: React.FC<any> = (props) => {
  const [toDisplay, setToDisplay] = React.useState(20);
  const patientsFiltered = props?.patientList
    ? props?.patientList?.filter(
        (patient) =>
          !props?.patientSelected?.includes(patient?.id) &&
          (patient?.phone
            ?.toUpperCase()
            .includes(props?.inputPatient?.toUpperCase()) ||
            patient?.lastname
              ?.toUpperCase()
              .includes(props?.inputPatient?.toUpperCase()))
      )
    : [];
  return (
    <Grid container direction="column" wrap="nowrap">
      <Grid item display="grid" style={{ zIndex: 101 }}>
        <GenericSelectPatient
          onAddPatientClick={(value) => {
            addNewPatient(value, props?.dispatch);
          }}
          placeholder={"Nom ou n° tel patient"}
          onPatientClick={(patient) => {
            if (props?.patientSelected?.length < 100) {
              props?.setPatientList((x) => {
                if (x?.length == 0) {
                  return [patient];
                }
                const founded = x.find((y) => y?.id == patient?.id);
                if (founded) {
                  return x;
                }
                return [patient, ...x];
              });
              props?.dispatch(selectPatientReducer?.onlyAddPatient(patient));
            } else {
              props?.dispatch(
                snackbarReducer.openSnackbar({
                  message: `Vous ne pouvez pas sélectionner plus de 100 patients`,
                  type: "error",
                  duration: 2000,
                })
              );
            }
          }}
        />
        {/*
        <TextInput
          background="#F0F2F2"
          value={props?.inputPatient}
          onChange={(e) => {
            if (e?.target?.value) {
              if (lastIsValidPhoneNumber(e.target.value.replaceAll(" ", ""))) {
                props?.setInputPatient(e.target.value.replaceAll(" ", ""));
              } else {
                props?.setInputPatient(e.target.value);
              }
            } else {
              props?.setInputPatient("");
            }
          }}
          placeholder="Nom ou n° tel patient"
        />*/}
      </Grid>
      {props?.inputPatient !== "" && (
        <Grid item display="grid" paddingLeft="0px">
          <Dropdown.Menu
            show
            style={{
              borderRadius: "0px 10px 10px 10px",
              zIndex: 100,
              padding: "0",
              marginTop: "-5px",
              marginLeft: "2px",
              overflow: "none",
              width: "230px",
            }}
          >
            <ul
              className="drowDownSearch"
              style={{
                zIndex: 100,
                width: "100%",
                maxHeight: "300px",
                overflow: "auto",
                paddingTop: "10px",
              }}
            >
              {props?.patientList &&
                patientsFiltered?.slice(0, toDisplay)?.map((patient) => {
                  return (
                    <li
                      key={patient?.id}
                      className="PatientUnit"
                      style={{
                        height: "100%",
                        paddingTop: "5px",
                        paddingBottom: "5px",
                        flexDirection: "column",
                        display: "flex",
                      }}
                      onClick={() => {
                        props?.setInputPatient("");
                        if (props?.patientSelected?.length < 100) {
                          props?.dispatch(
                            selectPatientReducer?.onlyAddPatient(patient)
                          );
                        } else {
                          props?.dispatch(
                            snackbarReducer.openSnackbar({
                              message: `Vous ne pouvez pas sélectionner plus de 100 patients`,
                              type: "error",
                              duration: 2000,
                            })
                          );
                        }
                      }}
                    >
                      <Grid container direction="row" columnSpacing="4px">
                        {!patient?.firstname && !patient?.lastname && (
                          <Grid item display="grid">
                            <span
                              style={{
                                fontFamily: "Poppins",
                                fontSize: "14px",
                                letterSpacing: "0px",
                                color: "#0B243C",
                                textAlign: "left",
                              }}
                            >
                              {"Aucun nom renseigné"}
                            </span>
                          </Grid>
                        )}
                        <Grid item display="grid">
                          <BoldOnlyThisCharacters
                            style={{
                              fontFamily: "Poppins",
                              fontSize: "14px",
                              letterSpacing: "0px",
                              color: "#0B243C",
                              textAlign: "left",
                            }}
                            text={`${
                              patient?.lastname ? patient.lastname : ""
                            }`}
                            characters={props?.inputPatient}
                          />
                        </Grid>
                        <Grid item display="grid">
                          <span
                            style={{
                              fontFamily: "Poppins",
                              fontSize: "14px",
                              letterSpacing: "0px",
                              color: "#0B243C",
                              textAlign: "left",
                            }}
                          >
                            {patient?.firstname ? patient.firstname : ""}
                          </span>
                        </Grid>
                      </Grid>
                      {patient.phone ? (
                        <BoldOnlyThisCharacters
                          style={{
                            fontFamily: "Poppins",
                            fontSize: "14px",
                            letterSpacing: "0px",
                            color: "#0B243C",
                            textAlign: "left",
                          }}
                          characters={props?.inputPatient}
                          text={patient?.phone}
                        />
                      ) : (
                        <span
                          style={{
                            fontFamily: "Poppins",
                            fontSize: "14px",
                            letterSpacing: "0px",
                            color: "#0B243C",
                            textAlign: "left",
                          }}
                        >
                          {patient.phone
                            ? formatPhone(patient.phone)
                            : "À compléter"}
                        </span>
                      )}
                    </li>
                  );
                })}
              {patientsFiltered?.length > toDisplay && (
                <>
                  <div
                    style={{ borderTop: "1px solid #e6ebf0", width: "100%" }}
                  />
                  <li
                    className="sendTo"
                    style={{ zIndex: 100, maxWidth: "100%", width: "100%" }}
                    onClick={() => {
                      setToDisplay((actual) => actual + 10);
                    }}
                  >
                    {`Afficher plus`}
                  </li>
                </>
              )}
              <div style={{ borderTop: "1px solid #e6ebf0", width: "100%" }} />
              <li
                className="sendTo"
                style={{ zIndex: 100, maxWidth: "100%", width: "100%" }}
                onClick={() => {
                  addNewPatient(props?.inputPatient, props?.dispatch);
                  props?.setInputPatient("");
                }}
              >
                {`Ajouter le patient '${props?.inputPatient}'`}
              </li>
            </ul>
          </Dropdown.Menu>
        </Grid>
      )}
    </Grid>
  );
};

const ButtonVitale: React.FC<any> = (props) => {
  const { lastInfosVitale, checkSoftware } = props;
  const dispatch = useDispatch();

  return (
    <button
      style={{
        background:
          lastInfosVitale?.status == "1"
            ? "#0B243C 0% 0% no-repeat padding-box"
            : "#0B243C 0% 0% no-repeat padding-box",
        borderRadius: "10px",
        fontSize: "13px",
        fontFamily: "Poppins",
        fontWeight: 500,
        textAlign: "center",
        color: "#FFFFFF",
        height: "42px",
      }}
      onClick={() => {
        checkSoftware();
      }}
    >
      Importer la carte Vitale
    </button>
  );
};

const PatientsRecip: React.FC<any> = (props) => {
  const { checkSoftwareLoading, checkSoftware } = props;
  const user = useAppSelector((x) => x?.userReducer?.user);
  const ref = useOutsideClick(() => {
    props?.setInputPatient("");
  });
  return (
    <Grid container direction="row" spacing={"10px"}>
      {user?.interface && !["8742"].includes(user?.interface) && (
        <>
          {!checkSoftwareLoading ? (
            <Grid item display="grid">
              <ButtonVitale {...props} />
            </Grid>
          ) : (
            <Grid item display="grid" paddingRight="15px">
              <Grid
                container
                direction="row"
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <CircularProgress />
              </Grid>
            </Grid>
          )}
        </>
      )}
      <Grid item ref={ref} xs display="grid">
        <InputPatient {...props} />
      </Grid>
    </Grid>
  );
};

const ListPatient: React.FC<any> = (props) => {
  const phoneSelected = useAppSelector(
    (state) => state.selectPatientReducer.phoneSelected
  );
  const dispatch = useDispatch();

  const countPatients = () => {
    let count = 0;
    const patientsSelected = props?.patientSelected;
    const patientList = props?.patientList;

    for (const patientSelected of patientsSelected) {
      if (patientList?.find((p) => p?.id == patientSelected)) {
        count++;
      }
    }
    return count;
  };

  if (countPatients() === 0 && phoneSelected?.length === 0) {
    return (
      <span
        style={{
          fontFamily: "Poppins",
          fontSize: "16px",
          textAlign: "left",
          fontWeight: 500,
          color: "#657273",
        }}
      >
        Saisir un nom ou un n° dans le champ de recherche
      </span>
    );
  } else {
    return (
      <Grid
        container
        direction="column"
        alignItems="center"
        justifyContent="center"
        gap="5px"
        wrap="nowrap"
      >
        {phoneSelected.map((phone, index) => {
          return (
            <>
              <Grid
                container
                key={phone.phone}
                direction="column"
                wrap="nowrap"
                gap="1px"
                paddingBottom="10px"
                paddingTop="5px"
              >
                <Grid item display="grid" justifyContent="center">
                  <Grid
                    container
                    direction="row"
                    wrap="nowrap"
                    alignItems="center"
                    spacing="5px"
                  >
                    <Grid item display="grid">
                      <DoneOutlined
                        fontSize="small"
                        style={{ color: "#5BB9C1" }}
                      />
                    </Grid>
                    <Grid item display="grid">
                      <span
                        style={{
                          color: "#0B243C",
                          fontSize: "20px",
                          fontWeight: "bold",
                          textAlign: "center",
                          fontFamily: "Poppins",
                          letterSpacing: "0px",
                        }}
                      >
                        {formatPhone(phone.phone)}
                      </span>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item display="grid" justifyContent="center">
                  <Grid container direction="row" spacing="5px">
                    <Grid item display="grid" alignItems="center">
                      <button
                        onClick={() => {
                          dispatch(
                            createPatientReducer?.openPatient({
                              lastname: "",
                              firstname: "",
                              phone: phone.phone,
                            })
                          );
                          dispatch(
                            selectPatientReducer?.openPhone(phone.phone)
                          );
                        }}
                        style={{
                          margin: "0",
                          padding: "0",
                          background: "none",
                          alignItems: "center",
                          justifyContent: "center",
                          display: "flex",
                        }}
                      >
                        <span
                          style={{
                            color: "#5BB9C1",
                            fontSize: "12px",
                            textAlign: "center",
                            fontFamily: "Poppins",
                            fontWeight: 600,
                            letterSpacing: "0px",
                          }}
                        >
                          Compléter
                        </span>
                      </button>
                    </Grid>
                    <Grid item display="grid" alignItems="center">
                      <div
                        style={{ borderLeft: "1px solid grey", height: "50%" }}
                      ></div>
                    </Grid>
                    <Grid item display="grid" alignItems="center">
                      <button
                        onClick={() => {
                          dispatch(
                            selectPatientReducer?.deletePhone(phone.phone)
                          );
                        }}
                        style={{
                          margin: "0",
                          padding: "0",
                          background: "none",
                          alignItems: "center",
                          justifyContent: "center",
                          display: "flex",
                        }}
                      >
                        <span
                          style={{
                            color: "#5BB9C1",
                            fontSize: "12px",
                            textAlign: "center",
                            fontFamily: "Poppins",
                            fontWeight: 600,
                            letterSpacing: "0px",
                          }}
                        >
                          Désélectionner
                        </span>
                      </button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              {index !== phoneSelected.length - 1 && (
                <div className="horizontal-bar" style={{ width: "60%" }} />
              )}
            </>
          );
        })}
        {props?.patientList?.filter((element) =>
          props?.patientSelected?.includes(element?.id)
        )?.length > 0 &&
          phoneSelected?.length > 0 && (
            <div className="horizontal-bar" style={{ width: "60%" }} />
          )}
        {props?.patientList
          ?.filter((element) => props?.patientSelected?.includes(element?.id))
          .map((patient, index) => {
            return (
              <>
                {index != 0 && (
                  <div className="horizontal-bar" style={{ width: "60%" }} />
                )}
                <Grid item key={patient?.id} display="grid">
                  <Grid
                    container
                    direction="column"
                    wrap="nowrap"
                    gap="1px"
                    paddingBottom="10px"
                    paddingTop="5px"
                  >
                    <Grid item display="grid">
                      <Grid
                        container
                        direction="row"
                        wrap="nowrap"
                        alignItems="center"
                        justifyContent="center"
                        spacing="5px"
                      >
                        {patient.phone && (
                          <Grid item display="grid">
                            <DoneOutlined
                              fontSize="small"
                              style={{ color: "#5BB9C1" }}
                            />
                          </Grid>
                        )}
                        <Grid item display="grid">
                          <span
                            style={{
                              color: "#0B243C",
                              fontSize: "20px",
                              fontWeight: "bold",
                              textAlign: "center",
                              fontFamily: "Poppins",
                            }}
                          >
                            {patient?.lastname}{" "}
                            <span
                              style={{
                                color: "#0B243C",
                                fontSize: "20px",
                                fontWeight: "bold",
                                textAlign: "center",
                                fontFamily: "Poppins",
                              }}
                            >
                              {patient?.firstname}
                            </span>
                          </span>
                        </Grid>
                      </Grid>
                      <Grid item display="grid">
                        <span
                          style={{
                            color: "#657273",
                            fontSize: "16px",
                            textAlign: "center",
                            fontFamily: "Poppins",
                            letterSpacing: "0px",
                          }}
                        >
                          {patient?.phone ? formatPhone(patient?.phone) : ""}
                        </span>
                      </Grid>
                    </Grid>
                    <Grid item display="grid" justifyContent="center">
                      <Grid container direction="row" spacing="5px">
                        <Grid item display="grid" alignItems="center">
                          <button
                            onClick={() => {
                              props?.dispatch(
                                editPatientReducer?.openPatient(patient)
                              );
                            }}
                            style={{
                              margin: "0",
                              padding: "0",
                              background: "none",
                              alignItems: "center",
                              justifyContent: "center",
                              display: "flex",
                            }}
                          >
                            <span
                              style={{
                                color: "#5BB9C1",
                                fontSize: "12px",
                                textAlign: "center",
                                fontFamily: "Poppins",
                                fontWeight: 600,
                                letterSpacing: "0px",
                              }}
                            >
                              {patient?.phone ? "Modifier" : "Compléter"}
                            </span>
                          </button>
                        </Grid>
                        <Grid item display="grid" alignItems="center">
                          <div
                            style={{
                              borderLeft: "1px solid grey",
                              height: "50%",
                            }}
                          ></div>
                        </Grid>
                        <Grid item display="grid" alignItems="center">
                          <button
                            onClick={() => {
                              props?.dispatch(
                                selectPatientReducer?.addPatient(patient)
                              );
                            }}
                            style={{
                              margin: "0",
                              padding: "0",
                              background: "none",
                              alignItems: "center",
                              justifyContent: "center",
                              display: "flex",
                            }}
                          >
                            <span
                              style={{
                                color: "#5BB9C1",
                                fontSize: "12px",
                                textAlign: "center",
                                fontFamily: "Poppins",
                                fontWeight: 600,
                                letterSpacing: "0px",
                              }}
                            >
                              Désélectionner
                            </span>
                          </button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </>
            );
          })}
      </Grid>
    );
  }
};

const RecipientContainerV2 = (props: any) => {
  const {
    isPatientListLoading,
    setIsPatientListLoading,
    phoneRegisteredForLaterOpen,
    setPhoneRegisteredForLaterOpen,
    phoneRegisteredForLater,
    setPhoneRegisteredForLater,
    phoneDeleteLoading,
    setPhoneDeleteLoading,
    phoneRegisteredForLaterLoading,
    setPhoneRegisteredForLaterLoading,
  } = props;
  const loadingTimeInMs = 8000;
  const checkPoints = [70, 85];
  const [isValidateLoading, setIsValidateLoading] = React.useState(false);
  const [isImportPopup, setIsImportPopup] = React.useState(false);
  const [importedPatient, setImportedPatient] = React.useState([]);
  const [isVitaleReaderLoading, setIsVitaleReaderLoading] =
    React.useState(false);
  const [vitaleReaderLoadingPercent, setVitaleReaderLoadingPercent] =
    React.useState(0);
  const [checkSoftwareLoadingPercent, setCheckSoftwareLoadingPercent] =
    React.useState(0);
  const [inputPatient, setInputPatient] = React.useState("");
  const dispatch = useDispatch();
  const user_id = localStorage.getItem("user_id");
  const [tokenVitalReaderTest, setTokenVitealReader] = React.useState(null);
  const [lastInfosVitale, setLastInfosVitale] = React.useState(null);
  const [checkSoftwareLoading, setCheckSoftwareLoading] = React.useState(false);
  const snackbarOpenUse = useOpenSnackbar();

  const closePhoneRegistered = () => {
    setPhoneDeleteLoading([]);
    setPhoneRegisteredForLaterLoading(false);
    setPhoneRegisteredForLaterOpen(false);
  };

  React.useEffect(() => {
    localStorage.removeItem("lastInfosVitale");
  }, []);

  const updateCheckSoftwarePercent = (percent, maxTime) => {
    setTimeout(() => {
      if (percent >= 100) {
        let infos = null;
        try {
          infos = JSON.parse(localStorage.getItem("lastInfosVitale"));
        } catch {
          infos = null;
        }
        if (infos?.status == "1") {
          const id = nanoid(8);
          localStorage.setItem("transactionId", id);
          setIsVitaleReaderLoading(true);
          updatePercent(0);
          window.location.href = `certipairvitalereader:${id}`;
        }
        setCheckSoftwareLoading(false);
        return;
      }
      setCheckSoftwareLoadingPercent(percent);
      if (localStorage.getItem("loadedSoftware") == "true") {
        updateCheckSoftwarePercent(90, maxTime);
        localStorage.setItem("loadedSoftware", "false");
      } else {
        updateCheckSoftwarePercent(percent + 1, maxTime);
      }
    }, maxTime / 100);
  };

  const checkSoftware = () => {
    if (!window.navigator.userAgent.includes("Windows")) {
      dispatch(
        snackbarReducer.openSnackbar({
          message:
            "Vous devez être sur Windows pour utiliser cette fonctionnalité",
          type: "error",
          duration: 3000,
        })
      );
      return;
    }
    const id = `test_${nanoid()}`;
    setVitaleReaderLoadingPercent(0);
    localStorage.setItem("loadedSoftware", "false");
    setCheckSoftwareLoading(true);
    updateCheckSoftwarePercent(0, 11000);
    window.location.href = `certipairvitalereader:${id}`;
    setTimeout(() => {
      makeAPIRequest("get", `vitale/check_vitale?transactionId=${id}`, null)
        .then((res) => {
          localStorage.setItem("loadedSoftware", "true");
          const infos = res?.data?.data?.infos?.[0];
          if (!infos) {
            dispatch(
              snackbarReducer.openSnackbar({
                message:
                  "Le logiciel Certipair Vitale Reader n'est pas installé",
                type: "error",
                duration: 3000,
              })
            );
            dispatch(openHelpInstallationVitale());
          } else {
            if (infos?.message == "L'API_LEC n'est pas installée") {
              dispatch(openHelpInstallationVitale());
            }
            dispatch(
              snackbarReducer.openSnackbar({
                message:
                  infos?.status == "1"
                    ? "L'application est prête à importer la carte Vitale"
                    : infos?.message,
                type: infos?.status == "1" ? "success" : "error",
                duration: 3000,
              })
            );
          }
          localStorage.setItem("lastInfosVitale", JSON.stringify(infos));
          setLastInfosVitale(infos);
        })
        .catch((err) => {});
    }, 7500);
  };

  React.useEffect(() => {
    onRefresh();

    const listenerPatientAdded = EventEmitter.addListener(
      "PatientAdded",
      onRefresh
    );

    return () => {
      listenerPatientAdded.remove();
    };
  }, []);

  const onRefresh = (eventData = null) => {
    if (eventData && eventData?.text && eventData?.id) {
      const newPatient = eventData?.text;
      newPatient.id = eventData?.id;

      if (props?.patientSelected?.length < 100) {
        dispatch(selectPatientReducer?.onlyAddPatient(newPatient));
      } else {
        dispatch(
          snackbarReducer.openSnackbar({
            message: `Vous ne pouvez pas sélectionner plus de 100 patients`,
            type: "error",
            duration: 2000,
          })
        );
      }
    }
  };

  const endLoading = () => {
    setIsVitaleReaderLoading(false);
    const transactionId = localStorage.getItem("transactionId");
    setVitaleReaderLoadingPercent(0);

    if (transactionId) {
      makeAPIRequest(
        "get",
        `patient/load_patient?transactionId=${transactionId}`
      )
        .then((res) => {
          if (res?.data?.data?.patient?.length > 0) {
            dispatch(
              snackbarReducer?.openSnackbar({
                duration: 2500,
                message: "Lecture de la carte Vitale terminée",
                type: "success",
              })
            );
            const importedPatientTmp = res?.data?.data?.patient;
            for (let i = 0; i < importedPatientTmp?.length; i++)
              importedPatientTmp[i].isSelected = true;
            setImportedPatient(importedPatientTmp);
            for (const patient of res.data.data.patient) {
              EventEmitter.emit("PatientAdded", {
                text: patient,
                id: patient?.id,
              });
            }
            setIsImportPopup(true);
          } else {
            dispatch(
              snackbarReducer?.openSnackbar({
                duration: 7000,
                message: "Impossible de lire la carte Vitale.",
                type: "error",
              })
            );
          }
        })
        .catch((e) => {
          dispatch(
            snackbarReducer?.openSnackbar({
              duration: 7000,
              message: "Impossible de lire la carte Vitale.",
              type: "error",
            })
          );
        });
    } else {
      console.error("No transaction transactionId found in local storage.");
    }
  };

  const executeCheckPoint = (next) => {
    const transactionId = localStorage.getItem("transactionId");
    if (transactionId) {
      makeAPIRequest(
        "get",
        `patient/load_patient?transactionId=${transactionId}`
      ).then((res) => {
        if (res?.data?.data?.patient?.length > 0) {
          updatePercent(90);
        } else {
          updatePercent(next);
        }
      });
    } else {
      console.error("No transaction transactionId found in local storage.");
      updatePercent(next);
    }
  };

  const updatePercent = (next) => {
    setTimeout(() => {
      if (checkPoints.includes(next)) {
        executeCheckPoint(next + 1);
      } else if (next >= 100) endLoading();
      else {
        setVitaleReaderLoadingPercent(next);
        updatePercent(next + 1);
      }
    }, loadingTimeInMs / 100);
  };

  const BigComponent: React.FC<any> = (props) => {
    const { importedPatient, setImportedPatient } = props;

    return (
      <Grid container direction="column" wrap="nowrap" paddingTop="15px">
        <Grid item display="grid">
          <span
            style={{
              textAlign: "center",
              color: "#657273",
              paddingBottom: "5px",
              fontFamily: "Poppins",
              fontSize: "16px",
            }}
          >
            Quel(s) patient(s) souhaitez-vous ajouter ?
          </span>
        </Grid>
        <Grid item display="grid" justifyContent="center">
          {importedPatient?.map((patient, i) => {
            return (
              <Grid
                container
                direction="row"
                minWidth="290px"
                marginLeft="-35px"
                paddingTop="5px"
                display="flex"
                key={patient?.id}
                alignItems="center"
                columnSpacing="10px"
              >
                <Grid item display="grid" xs={10}>
                  <span
                    style={{
                      textAlign: "center",
                      color: "#657273",
                      fontFamily: "Poppins",
                      fontSize: "16px",
                    }}
                  >
                    {`${importedPatient[i]?.firstname} ${importedPatient[i]?.lastname}`}
                  </span>
                </Grid>
                <Grid item display="grid" xs={2}>
                  <div
                    style={{
                      background: !importedPatient[i]?.isSelected
                        ? "#EDF2F2 0% 0% no-repeat padding-box"
                        : "#FFA500 0% 0% no-repeat padding-box",
                      width: "22px",
                      height: "22px",
                      borderRadius: "5px",
                      border: "1px solid #D3E0E0",
                      opacity: 1,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {importedPatient[i]?.isSelected && (
                      <Check
                        onClick={() => {
                          const importedPatientTmp = [...importedPatient];

                          importedPatientTmp[i].isSelected =
                            !importedPatientTmp[i].isSelected;
                          setImportedPatient(importedPatientTmp);
                        }}
                        fontSize="small"
                        style={{ paddingLeft: "0px", color: "white" }}
                      />
                    )}
                    <input
                      type="checkbox"
                      className="checkbox_modals"
                      checked={importedPatient[i]?.isSelected}
                      onChange={() => {
                        return null;
                      }}
                      onClick={() => {
                        const importedPatientTmp = [...importedPatient];

                        importedPatientTmp[i].isSelected =
                          !importedPatientTmp[i].isSelected;
                        setImportedPatient(importedPatientTmp);
                      }}
                      style={{
                        padding: "0",
                        margin: "0",
                        borderRadius: "5px",
                        width: "100%",
                        height: "100%",
                        opacity: 0,
                      }}
                    />
                  </div>
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      </Grid>
    );
  };

  return (
    <>
      {phoneRegisteredForLaterOpen && (
        <GenericFromPopup
          onClose={() => closePhoneRegistered()}
          xs={5}
          title={{
            leftPart: "Mes",
            rightPart: "numéros enregistrés",
          }}
        >
          <Grid
            container
            direction="column"
            wrap="nowrap"
            padding="10px"
            gap="10px"
          >
            <Grid item display="grid">
              <span
                style={{
                  fontSize: "14px",
                  fontFamily: "Poppins",
                  color: "#0B243C",
                }}
              >
                {`${phoneRegisteredForLater.length} numéro${
                  phoneRegisteredForLater.length > 1 ? "s" : ""
                } au total`}
              </span>
            </Grid>
            {phoneRegisteredForLater?.length > 0 && (
              <Grid item display="grid" paddingTop="5px">
                <Grid container direction="row" wrap="nowrap" gap="10px">
                  <Grid item display="grid">
                    <button
                      onClick={() => {
                        setPhoneDeleteLoading(phoneRegisteredForLater);
                        phoneRegisteredForLater.forEach((phone) => {
                          dispatch(selectPatientReducer.addPhone(phone));
                        });
                        const addS =
                          phoneRegisteredForLater.length > 1 ? "s" : "";
                        snackbarOpenUse.success(
                          `${phoneRegisteredForLater.length} numéro${addS} ont été sélectionné${addS}`
                        );
                        makeAPIRequest(
                          "post",
                          "/sms/remove_multiple_phone_numbers",
                          {
                            phoneNumbers: phoneRegisteredForLater,
                          },
                          "v3"
                        )
                          .then(() => {
                            setPhoneRegisteredForLater([]);
                          })
                          .catch((err) => {
                            snackbarOpenUse.error(
                              "Impossible de retirer ces numéros de la liste.",
                              err
                            );
                          })
                          .finally(() => {
                            closePhoneRegistered();
                            setPhoneRegisteredForLater([]);
                          });
                      }}
                      className="clear-text-button"
                      style={{ margin: "0", padding: "0", fontSize: "14px" }}
                    >
                      {"Tout sélectionner"}
                    </button>
                  </Grid>
                  <Grid item display="grid">
                    <button
                      onClick={() => {
                        setPhoneDeleteLoading(phoneRegisteredForLater);
                        makeAPIRequest(
                          "post",
                          "/sms/remove_multiple_phone_numbers",
                          {
                            phoneNumbers: phoneRegisteredForLater,
                          },
                          "v3"
                        )
                          .then(() => {
                            const addS =
                              phoneRegisteredForLater.length > 1 ? "s" : "";
                            snackbarOpenUse.success(
                              `${phoneRegisteredForLater.length} numéro${addS} ont été effacé${addS}`
                            );
                            setPhoneRegisteredForLater([]);
                          })
                          .catch((err) => {
                            snackbarOpenUse.error(
                              "Impossible de retirer ces numéros de la liste.",
                              err
                            );
                          })
                          .finally(() => {
                            setPhoneDeleteLoading([]);
                          });
                      }}
                      className="clear-text-button"
                      style={{ margin: "0", padding: "0", fontSize: "14px" }}
                    >
                      {"Tout effacer"}
                    </button>
                  </Grid>
                </Grid>
              </Grid>
            )}
            {phoneRegisteredForLater.length > 0 && (
              <Grid item display="grid">
                <Grid container direction="column" wrap="nowrap" gap="5px">
                  {phoneRegisteredForLater.map((phone) => {
                    return (
                      <Grid item display="grid" key={phone}>
                        <Grid
                          container
                          direction="row"
                          gap="10px"
                          wrap="nowrap"
                          alignItems={"center"}
                        >
                          <Grid item display="grid" width="100px">
                            <span
                              style={{
                                fontSize: "14px",
                                fontFamily: "Poppins",
                                color: "#0B243C",
                              }}
                            >
                              {phone}
                            </span>
                          </Grid>
                          <Grid item display="grid">
                            {phoneDeleteLoading.includes(phone) ? (
                              <>
                                <CircularProgress
                                  style={{
                                    color: "#657273",
                                    width: "18px",
                                    height: "18px",
                                  }}
                                />
                              </>
                            ) : (
                              <Grid
                                container
                                direction="row-reverse"
                                wrap="nowrap"
                                gap="5px"
                              >
                                <Grid item display="grid">
                                  <ButtonIconWithTooltip
                                    tooltipText="Supprimer"
                                    Icon={
                                      <ClearRounded
                                        style={{
                                          color: "#FF3100",
                                          fontSize: "18px",
                                        }}
                                      />
                                    }
                                    onClick={() => {
                                      setPhoneDeleteLoading((x) => {
                                        if (x.includes(phone)) {
                                          return x;
                                        }
                                        return [...x, phone];
                                      });
                                      makeAPIRequest(
                                        "post",
                                        "/sms/remove_phone_number",
                                        {
                                          phoneNumber: phone,
                                        },
                                        "v3"
                                      )
                                        .then(() => {
                                          setPhoneRegisteredForLater((x) => {
                                            return x.filter((y) => y !== phone);
                                          });
                                        })
                                        .catch((err) => {
                                          snackbarOpenUse.error(
                                            "Impossible de retirer ce numéro de la liste.",
                                            err
                                          );
                                        })
                                        .finally(() => {
                                          setPhoneDeleteLoading((x) => {
                                            return x.filter((y) => y !== phone);
                                          });
                                        });
                                    }}
                                  />
                                </Grid>
                                <Grid item display="grid">
                                  <ButtonIconWithTooltip
                                    tooltipText="Sélectionner"
                                    Icon={
                                      <AddCircleRounded
                                        style={{
                                          color: "#0B243C",
                                          fontSize: "18px",
                                        }}
                                      />
                                    }
                                    onClick={() => {
                                      setPhoneDeleteLoading((x) => {
                                        if (x.includes(phone)) {
                                          return x;
                                        }
                                        return [...x, phone];
                                      });
                                      makeAPIRequest(
                                        "post",
                                        "/sms/remove_phone_number",
                                        {
                                          phoneNumber: phone,
                                        },
                                        "v3"
                                      )
                                        .then(() => {
                                          dispatch(
                                            selectPatientReducer.addPhone(phone)
                                          );
                                          setPhoneRegisteredForLater((x) => {
                                            return x.filter((y) => y !== phone);
                                          });
                                        })
                                        .catch((err) => {
                                          snackbarOpenUse.error(
                                            "Impossible de sélectionner ce numéro de la liste.",
                                            err
                                          );
                                        })
                                        .finally(() => {
                                          setPhoneDeleteLoading((x) => {
                                            return x.filter((y) => y !== phone);
                                          });
                                        });
                                    }}
                                  />
                                </Grid>
                              </Grid>
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                    );
                  })}
                </Grid>
              </Grid>
            )}
          </Grid>
        </GenericFromPopup>
      )}
      {isImportPopup && importedPatient?.length > 0 && (
        <InformationPopup
          isBig={importedPatient?.length > 9 ? true : false}
          firstTitle="Importer"
          secondTitle="un patient"
          componentsSpacing="15px"
          isValidateLoading={isValidateLoading}
          confirmButtonText="Valider"
          onConfirm={() => {
            if (importedPatient?.length === 0) {
              setIsImportPopup(false);
            } else {
              const allSelectedPatient = importedPatient?.filter(
                (element) => element?.isSelected === true
              );
              const notSelectedPatient = importedPatient?.filter(
                (element) => element?.isSelected === false
              );
              const nbAllSelectedPatient = allSelectedPatient?.length;
              const nbNotSelectedPatient = notSelectedPatient?.length;
              for (let i = 0; i < nbNotSelectedPatient; i++) {
                if (i === 0) setIsValidateLoading(true);
                makeAPIRequest(
                  "delete",
                  `/patient/${notSelectedPatient[i]?.id}/delete`
                )
                  .then((res) => {
                    dispatch(
                      selectPatientReducer?.onlyRemovePatient(
                        notSelectedPatient[i]
                      )
                    );
                    if (i === nbNotSelectedPatient - 1) {
                      EventEmitter.emit("PatientAdded");
                      setIsImportPopup(false);
                      setIsValidateLoading(false);
                    }
                  })
                  .catch((e) => {
                    if (i === 0) setIsValidateLoading(true);
                    if (i === nbNotSelectedPatient - 1) {
                      EventEmitter.emit("PatientAdded");
                      setIsImportPopup(false);
                      setIsValidateLoading(false);
                    }
                  });
              }
              for (let i = 0; i < nbAllSelectedPatient; i++) {
                if (i === 0) setIsValidateLoading(true);
                makeAPIRequest(
                  "post",
                  `/patient/${allSelectedPatient[i]?.id}/unlock`
                )
                  .then((res) => {
                    if (i === nbAllSelectedPatient - 1) {
                      EventEmitter.emit("PatientAdded");
                      setIsImportPopup(false);
                      setIsValidateLoading(false);
                    }
                  })
                  .catch((e) => {
                    if (i === 0) setIsValidateLoading(true);
                    if (i === nbAllSelectedPatient - 1) {
                      EventEmitter.emit("PatientAdded");
                      setIsImportPopup(false);
                      setIsValidateLoading(false);
                    }
                  });
              }
            }
          }}
        >
          <BigComponent
            importedPatient={importedPatient}
            setImportedPatient={setImportedPatient}
          />
        </InformationPopup>
      )}
      <Grid
        container
        direction="column"
        wrap="nowrap"
        style={{
          paddingTop: "13px",
          paddingLeft: "19px",
          paddingRight: "19px",
          paddingBottom: "20px",
        }}
      >
        <Grid item display="grid">
          <span className="conseil-send-title">
            <Grid
              container
              direction="row"
              display="flex"
              spacing="5px"
              alignItems="center"
            >
              <Grid item display="grid">
                <IconSection />
              </Grid>
              <Grid item xs display="grid">
                <Grid
                  container
                  direction="row"
                  gap="20px"
                  justifyContent={"space-between"}
                >
                  <Grid item display="grid">
                    Destinataires
                  </Grid>
                  {phoneRegisteredForLater.length > 0 && (
                    <Grid item display="grid">
                      <ButtonIconWithTooltip
                        tooltipText={
                          phoneRegisteredForLater.length > 1
                            ? `${phoneRegisteredForLater.length} numéros enregistrés pour plus tard`
                            : `${phoneRegisteredForLater.length} numéro enregistré pour plus tard`
                        }
                        Icon={
                          <ContactPhone
                            fontSize="small"
                            style={{ color: "#0B243C" }}
                          />
                        }
                        onClick={() => setPhoneRegisteredForLaterOpen(true)}
                      />
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </span>
        </Grid>

        {isVitaleReaderLoading && (
          <Grid container direction="column" wrap="nowrap" paddingTop="11px">
            <Grid item display="grid">
              <span
                style={{
                  color: "#657273",
                  textAlign: "center",
                  letterSpacing: "0px",
                  fontFamily: "Poppins",
                  fontSize: "20px",
                  fontWeight: 300,
                }}
              >
                CHARGEMENT
              </span>
            </Grid>
            <Grid item display="grid" paddingTop="11px">
              <div className="animated-progress">
                <span style={{ width: `${vitaleReaderLoadingPercent}%` }}>
                  {vitaleReaderLoadingPercent}%
                </span>
              </div>
            </Grid>
            <Grid item display="grid" paddingTop="12px">
              <span
                style={{
                  color: "#657273",
                  textAlign: "center",
                  letterSpacing: "0px",
                  fontFamily: "Poppins",
                  fontSize: "13px",
                  fontWeight: 600,
                  fontStyle: "italic",
                }}
              >
                Import des données de la carte Vitale en cours
              </span>
            </Grid>
          </Grid>
        )}

        {checkSoftwareLoading && !isVitaleReaderLoading && (
          <Grid container direction="column" wrap="nowrap" paddingTop="11px">
            <Grid item display="grid">
              <span
                style={{
                  color: "#657273",
                  textAlign: "center",
                  letterSpacing: "0px",
                  fontFamily: "Poppins",
                  fontSize: "20px",
                  fontWeight: 300,
                }}
              >
                CHARGEMENT
              </span>
            </Grid>
            <Grid item display="grid" paddingTop="11px">
              {checkSoftwareLoadingPercent > 1 &&
              checkSoftwareLoadingPercent < 99 ? (
                <div className={"animated-progress"}>
                  <span style={{ width: `${checkSoftwareLoadingPercent}%` }}>
                    {checkSoftwareLoadingPercent}%
                  </span>
                </div>
              ) : (
                <div className={"animated-progress"}>
                  <span style={{ width: `0%` }}>0%</span>
                </div>
              )}
            </Grid>
            <Grid item display="grid" paddingTop="12px">
              <span
                style={{
                  color: "#657273",
                  textAlign: "center",
                  letterSpacing: "0px",
                  fontFamily: "Poppins",
                  fontSize: "13px",
                  fontWeight: 600,
                  fontStyle: "italic",
                }}
              >
                Vérification de votre lecteur en cours
              </span>
            </Grid>
          </Grid>
        )}

        {!props.isMultipleRecip &&
          !isVitaleReaderLoading &&
          !checkSoftwareLoading && (
            <>
              <Grid item display="grid" paddingTop="10px">
                <PatientsRecip
                  updatePercent={updatePercent}
                  checkSoftwareLoadingPercent={checkSoftwareLoadingPercent}
                  setCheckSoftwareLoadingPercent={
                    setCheckSoftwareLoadingPercent
                  }
                  vitaleReaderLoadingPercent={vitaleReaderLoadingPercent}
                  setVitaleReaderLoadingPercent={setVitaleReaderLoadingPercent}
                  isVitaleReaderLoading={isVitaleReaderLoading}
                  setIsVitaleReaderLoading={setIsVitaleReaderLoading}
                  inputPatient={inputPatient}
                  lastInfosVitale={lastInfosVitale}
                  checkSoftwareLoading={checkSoftwareLoading}
                  checkSoftware={checkSoftware}
                  setInputPatient={setInputPatient}
                  dispatch={dispatch}
                  {...props}
                />
              </Grid>
              <Grid item display="grid" paddingTop="20px" paddingBottom="5px">
                {isPatientListLoading ? (
                  <Grid
                    container
                    direction="row"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <CircularProgress />
                  </Grid>
                ) : (
                  <ListPatient dispatch={dispatch} {...props} />
                )}
              </Grid>
            </>
          )}
      </Grid>
    </>
  );
};

export default RecipientContainerV2;
