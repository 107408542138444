import * as React from "react";
import { Grid, CircularProgress } from "@mui/material";
import { useParams } from "react-router";
import makeAPIRequest from "../utils/makeAPIRequest";
import SimpleFullPage from "./SimpleFullPage";
import { useOpenSnackbar } from "./CreateOrEditChatbotLexPage";

interface IParams {
  id: string;
}

function traduireMoisAnnee(dateString: string): string {
  // Tableau des mois en français
  const mois = [
    "Janvier",
    "Février",
    "Mars",
    "Avril",
    "Mai",
    "Juin",
    "Juillet",
    "Août",
    "Septembre",
    "Octobre",
    "Novembre",
    "Décembre",
  ];

  // Vérification du format d'entrée
  const regex = /^\d{4}-(0[1-9]|1[0-2])$/;
  if (!regex.test(dateString)) {
    throw new Error("Le format doit être 'YYYY-MM'");
  }

  // Extraction de l'année et du mois
  const [annee, moisIndex] = dateString.split("-");
  const moisNom = mois[parseInt(moisIndex, 10) - 1]; // Les index des mois commencent à 0

  return `${moisNom} ${annee}`;
}

export const StatsBox: React.FC = () => {
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const { id } = useParams<IParams>();
  const snackbarOpenUse = useOpenSnackbar();
  const [stats, setStats] = React.useState(undefined);
  const [isError, setIsError] = React.useState(false);

  React.useEffect(() => {
    if (!id) {
      return;
    }
    setIsLoading(true);
    makeAPIRequest(
      "post",
      "/conseils/stats_activity",
      {
        user_id: id,
      },
      "v2",
    )
      .then((res) => {
        setStats(res?.data?.data);
        console.log(res);
      })
      .catch((err) => {
        snackbarOpenUse.error("Impossible de récupérer les statistiques.", err);
        setIsError(true);
      })
      .finally(() => setIsLoading(false));
  }, [id]);

  interface Envoi {
    conseilId: string;
    title: string;
    type: string;
    pack_sended: number;
    nb_sended_phones: number;
    stop_sms: number;
    sms_sended: number;
  }

  let liste: {
    mois: string;
    envois: Envoi[];
  }[] = [];

  if (stats?.sended_conseils && typeof stats.sended_conseils === "object") {
    const objMonths = stats.sended_conseils;
    for (const monthKey of Object.keys(objMonths)) {
      if (monthKey && monthKey?.length == 7) {
        let envois: Envoi[] = [];
        if (
          stats.sended_conseils?.[monthKey] &&
          typeof stats.sended_conseils[monthKey] === "object"
        ) {
          const objConseils = stats.sended_conseils[monthKey];
          for (const conseilIdKey of Object.keys(objConseils)) {
            if (conseilIdKey && objConseils?.[conseilIdKey]) {
              envois.push({
                conseilId: conseilIdKey,
                nb_sended_phones: objConseils[conseilIdKey]["nb_sended_phones"],
                stop_sms: objConseils[conseilIdKey]["stop_sms"],
                sms_sended: objConseils[conseilIdKey]["sms_sended"],
                pack_sended: objConseils[conseilIdKey]["pack_sended"],
                title: objConseils[conseilIdKey]["title"],
                type: objConseils[conseilIdKey]["type"],
              });
            }
          }
        }
        liste.push({
          mois: monthKey,
          envois: envois,
        });
        liste = liste.sort((a, b) => {
          return a.mois > b.mois ? -1 : 1;
        });
      }
    }
  }
  if (isError) {
    return (
      <Grid container wrap="nowrap" direction="column" padding="5%">
        <Grid item display="grid" paddingTop="20px">
          <span
            style={{
              fontFamily: "Poppins",
              fontSize: "16px",
              color: "#0B243C",
            }}
          >
            Impossible de récupérer la liste des activités, l'utilisateur doit
            activé cette option.
          </span>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container wrap="nowrap" direction="column" padding="5%">
      {isLoading && (
        <Grid item display="grid" paddingTop="20px">
          <Grid
            container
            direction="row"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <CircularProgress />
          </Grid>
        </Grid>
      )}
      {!isLoading && (
        <Grid item display="grid">
          <Grid container direction="column" wrap="nowrap" gap="10px">
            <Grid item display="grid">
              <span
                style={{
                  fontSize: "16px",
                  color: "#0B243C",
                  fontFamily: "Poppins",
                }}
              >
                {stats?.nameOfUser}
              </span>
            </Grid>
            <Grid item display="grid" paddingTop="20px">
              <div className="table-grid-activity-stats-patient">
                <span className="table-grid-title">Mois</span>
                <span className="table-grid-title">Titre du contenu</span>
                <span className="table-grid-title">Parcours déclenchés</span>
                <span className="table-grid-title">SMS envoyés</span>
                <span className="table-grid-title">Patients concernés</span>
                <span className="table-grid-title">STOP SMS</span>
                {liste.map((x, xIndex) => {
                  return x.envois.map((y, yIndex) => {
                    return (
                      <>
                        <span className="table-grid-text">
                          {yIndex === 0 ? traduireMoisAnnee(x.mois) : ""}
                        </span>
                        <span className="table-grid-text">{y.title}</span>
                        <span className="table-grid-text">
                          {y?.type === "pack" && y.pack_sended >= 0
                            ? y.pack_sended
                            : ""}
                        </span>
                        <span className="table-grid-text">{y.sms_sended}</span>
                        <span className="table-grid-text">
                          {y?.nb_sended_phones > 0 ? y.nb_sended_phones : 0}
                        </span>
                        <span className="table-grid-text">
                          {y?.stop_sms > 0 ? y.stop_sms : 0}
                        </span>
                      </>
                    );
                  });
                })}
              </div>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

const StatsActivitePage: React.FC = () => {
  return (
    <>
      <SimpleFullPage>
        <Grid
          container
          wrap="nowrap"
          width="95vw"
          direction="column"
          maxWidth="1200px"
        >
          <Grid item display="grid">
            <StatsBox />
          </Grid>
        </Grid>
      </SimpleFullPage>
    </>
  );
};

export default StatsActivitePage;
