import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import "../profile.css";
import { Field, Formik } from "formik";
import AuthService from "../../services/auth.services";
import IUsersData from "../../types/Users";
import "@pathofdev/react-tag-input/build/index.css";
import Select from "react-select";
import "../../styles/ProfileCompents.scss";
import TutorialService from "../../services/TutorialService";
import EventEmitter from "../../services/event";
import swal from "sweetalert";
import useUtils from "../../utils/hooks/useUtils";
import OrangeButton from "../Library/Buttons/OrangeButton/OrangeButton";
import { Grid, Tooltip } from "@mui/material";
import { ArrowDropDown, ArrowRightOutlined, Help } from "@mui/icons-material";
import makeAPIRequest from "../../utils/makeAPIRequest";
import OrangeCheckBox from "../Fields/CheckBox/OrangeCheckBox";
import FullField from "../Fields/FullField";
import ImportButton from "../Library/Buttons/ImportButton/ImportButton";
import { openSnackbar } from "../../redux/snackbarReducer";
import {
  getDDMMYYYYFromTimestamp,
  INSiCertificateAddPopup,
} from "../AdminGroups/CreateGroupPopup";
import { emailValidator } from "../Questionnaire/SendResponsePopup";
import { getUser } from "../../redux/new/userReducer";
import useAppDispatch from "../../redux/useAppDispatch";
import useAppSelector from "../../redux/useAppSelector";
import {
  GenericFromPopup,
  GenericSendButton,
} from "../../pages/ChatbotLexPage";
import {
  GenericLongText,
  GenericTextInput,
  useOpenSnackbar,
} from "../../pages/CreateOrEditChatbotLexPage";
import CancellablePopup from "../Library/Popups/CancellablePopup";
import forceDisconnectUser from "../../utils/forceDisconnectUser";

export const lastIsValidPhoneNumber = (phoneSelected: string): boolean => {
  if (!phoneSelected) {
    return false;
  }
  for (let i = 0; i < phoneSelected.length; i++) {
    if (i === 0 && phoneSelected[i] === "+") {
      continue;
    }
    if (
      !["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"].includes(
        phoneSelected[i]
      )
    ) {
      return false;
    }
  }
  // +262
  if (
    phoneSelected?.length === 13 &&
    (phoneSelected.substr(0, 5) === "+2626" ||
      phoneSelected.substr(0, 5) === "+2626" ||
      phoneSelected.substr(0, 5) === "+2627" ||
      phoneSelected.substr(0, 5) === "+2627")
  ) {
    return true;
  }
  if (
    phoneSelected?.length === 14 &&
    (phoneSelected.substr(0, 6) === "002626" ||
      phoneSelected.substr(0, 6) === "002626" ||
      phoneSelected.substr(0, 6) === "002627" ||
      phoneSelected.substr(0, 6) === "002627")
  ) {
    return true;
  }
  if (phoneSelected?.length != 10) return false;
  if (
    !["06", "07", "05", "04", "03", "02", "01", "09"].includes(
      phoneSelected.substr(0, 2)
    )
  )
    return false;
  for (const number of phoneSelected) {
    if (!["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"].includes(number)) {
      return false;
    }
  }
  return true;
};

const errorMessages = {
  emptyFirstName: "Le prénom n'est pas renseigné.",
  emptyLastName: "Le nom n'est pas renseigné.",
  badPassword: "Les mots de passes saisis ne sont pas identiques.",
  badPasswordLength: "Le mot de passe doit contenir au minimum 14 caractères",
  badPasswordMajuscule:
    "Le mot de passe doit contenir au minimum une majuscule",
  badPasswordChiffre: "Le mot de passe doit contenir au minimum un chiffre",
  badPasswordMinuscule:
    "Le mot de passe doit contenir au minimum une minuscule",
  emptyProfession: "La profession n'est pas renseignée.",
  invalidPhone: "Le numéro de téléphone est invalide.",
  emptyNniType: "Le type de numéro n'est pas renseigné.",
  emptyNniId: "Le numéro RPPS/ADELI n'est pas renseigné.",
  invalidMedimail: "L'adresse email medimail est invalide.",
  emptyMainSpeciality: "La spécialité principale n'est pas renseignée.",
  "": "",
};

const errorSelector = {
  emptyFirstName: "#input-first-name",
  emptyLastName: "#input-last-name",
  badPassword: "#input-password",
  badPasswordLength: "#input-password",
  badPasswordMajuscule: "#input-password",
  badPasswordChiffre: "#input-password",
  badPasswordMinuscule: "#input-password",
  emptyProfession: "#input-profession",
  invalidPhone: "#input-phone",
  emptyNniType: "#input-type-number",
  emptyNniId: "#input-nni-id",
  emptyMainSpeciality: "#input-main-speciality",
  invalidMedimail: "#input-email-medimail",
  "": "",
};

/*
emptyFirstName: !firstNameSelected || firstNameSelected?.length === 0,
emptyLastName: !lastNameSelected || lastNameSelected?.length === 0,
emptyProfession: !professionSelected?.value,
emptyMainSpeciality: !mainSpecialitySelected?.value,
emptyNniType: !nniSelected?.value,
emptyNniId: !nniIdSelected || nniIdSelected?.length === 0,
badPassword: passwordSelected != passwordConfirmationSelected,
invalidPhone: !lastIsValidPhoneNumber(),
*/

let user_id = 0;

const MonCompteContent: React.FC = () => {
  const [users, setUsers] = useState(null);
  const [users2, setUsers2] = useState<IUsersData | null>(null);
  const [data, setData] = useState<any>();
  const [windowsWidth, setWindowWidth] = useState(window?.innerWidth);
  const [phoneError, setPhoneError] = useState<boolean>();
  const [, fctMiseAJour] = React.useState({});
  const miseAJour = React.useCallback(() => fctMiseAJour({}), []);
  const user = useAppSelector((x) => x.userReducer.userClass);
  const [utils, setUtils] = useUtils();
  const [showError, setShowError] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const dispatch = useAppDispatch();
  const [consommation, setConsommation] = React.useState<{
    nbSendedThisMonth: number;
    limitSMS: number;
  }>({
    nbSendedThisMonth: undefined,
    limitSMS: undefined,
  });
  const displayConso =
    consommation.limitSMS !== undefined &&
    consommation.nbSendedThisMonth !== undefined;
  const percentConsommation = displayConso
    ? Math.round((consommation.nbSendedThisMonth / consommation.limitSMS) * 100)
    : 0;
  const snackbarUse = useOpenSnackbar();
  const [deleteMyAccount, setDeleteMyAccount] = React.useState<{
    isOpen: boolean;
    reason: string;
    waitingForConfirmationDelete?: boolean;
    deleteLoading?: boolean;
  }>({
    isOpen: false,
    reason: "",
    waitingForConfirmationDelete: false,
    deleteLoading: false,
  });

  // Selected options

  // Inputs
  const [mailPreferenceSelected, setMailPreferenceSelected] = useState(null);
  const [firstNameSelected, setFirstNameSelected] = useState(null);
  const [lastNameSelected, setLastNameSelected] = useState(null);
  const [phoneSelected, setPhoneSelected] = useState(null);
  const [nniIdSelected, setNniIdSelected] = useState(null);
  const [expeditorName, setExpeditorName] = useState("");
  const [mailMedimail, setMailMedimail] = useState("");
  const [stopSMSActive, setStopSMSActive] = useState("0");
  const [receiveSMSFailureActive, setReceiveSMSFailureActive] = useState("0");
  const [autoConsentPatient, setAutoConsentPatient] = useState("0");
  const [noConfirmSends, setNoConfirmSends] = useState("0");
  const [shareConseilsStats, setShareConseilsStats] = useState("0");
  const [passwordSelected, setPasswordSelected] = useState("");
  const [passwordConfirmationSelected, setPasswordConfirmationSelected] =
    useState("");

  // Simple selects
  const [nniSelected, setNniSelected] = useState({ value: null, label: null });
  const [professionSelected, setProfessionSelected] = useState({
    value: null,
    label: null,
  });
  const [civilitySelected, setCivilitySelected] = useState({
    value: null,
    label: null,
  });
  const [mainSpecialitySelected, setMainSpecialitySelected] = useState({
    value: null,
    label: null,
  });

  // Multi selects
  const [othersSpecialitiesSelected, setOthersSpecialitiesSelected] = useState(
    []
  );

  // Select options
  const [professionsOptions, setProfessionsOptions] = useState([]);
  const [civilitiesOptions, setCivilitiesOptions] = useState([]);
  const [mainSpecialitiesOptions, setMainSpecialitiesOptions] = useState([]);
  const [nniOptions, setNniOptions] = useState([]);
  const INSiCertificateReducer = useAppSelector(
    (state) => state.INSiReducer.certificate
  );
  const userHasGroup = useAppSelector((state) => state.INSiReducer.hasGroup);
  const [isPopupCertificateToFillOpen, setIsPopupCertificateToFillOpen] =
    React.useState<boolean>(false);
  const [displayCertificateINSiDetails, setDisplayCertificateINSiDetails] =
    React.useState(false);
  const [INSICertificate, setINSICertificate] = React.useState<
    | {
        binary: string;
        name: string;
        password: string;
        versionLPS: string;
        IDAM: string;
        infos?: {
          subject: {
            C: string;
            ST: string;
            O: string;
            OU: string;
            CN: string;
          };
          issuer: {
            C: string;
            ST: string;
            O: string;
            OU: string;
            CN: string;
          };
          validity: {
            from: string;
            to: string;
          };
        };
      }
    | undefined
  >(undefined);

  useEffect(() => {
    makeAPIRequest("get", "/user/month_consommation", null, "v3")
      .then((res) => {
        if (
          res?.data?.limitSMS !== undefined &&
          res?.data?.nbSendedThisMonth !== undefined
        ) {
          setConsommation({
            limitSMS: res.data.limitSMS,
            nbSendedThisMonth: res.data.nbSendedThisMonth,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if (INSiCertificateReducer) {
      setINSICertificate(INSiCertificateReducer);
    }
  }, [INSiCertificateReducer]);

  useEffect(() => {
    setWindowWidth(window.innerWidth);
    user_id = get_user_id();
    displayUser();
    displayUser2();
  }, [windowsWidth]);

  // When utils object is loaded
  useEffect(() => {
    const civilitiesOptionsTmp = [];
    const nniOptionsTmp = [];

    utils?.getNniTypes().map((n) => {
      nniOptionsTmp.push({ value: n.id, label: n.name });
    });
    civilitiesOptionsTmp.push({ value: "", label: "-- Aucune civilité --" });
    utils?.getCivilities().map((n) => {
      if (n?.name !== "Autres" && n?.name !== "")
        civilitiesOptionsTmp.push({ value: n.id, label: n.name + "." });
    });
    setProfessionsOptions(utils?.getProfessionsOptionsSorted());
    setMainSpecialitiesOptions(utils?.getSpecialitiesOptionsSorted());
    setCivilitiesOptions(civilitiesOptionsTmp);
    setNniOptions(nniOptionsTmp);
  }, [utils]);

  // When user object is loaded
  useEffect(() => {
    if (
      user?.getOthersSpecialities() !== "null" &&
      user?.getOthersSpecialities()?.length > 0
    ) {
      const othersSpecialitiesParsed = JSON.parse(
        user?.getOthersSpecialities()
      );

      if (othersSpecialitiesParsed?.length > 0) {
        const othersSpecialitiesSelectedTmp = [];
        for (const otherSpecialityId of othersSpecialitiesParsed) {
          const finded = mainSpecialitiesOptions?.find(
            (element) => element?.value === otherSpecialityId
          );
          if (finded) {
            othersSpecialitiesSelectedTmp.push(finded);
          }
        }
        setOthersSpecialitiesSelected(othersSpecialitiesSelectedTmp);
      }
    }
    const nniSelectedResult = nniOptions.find(
      (nni) => nni?.value === user?.getNniType()
    );
    const professionSelectedResult = professionsOptions?.find(
      (profession) => profession?.value === user?.getProfession()
    );
    const civilitySelectedResult = civilitiesOptions.find(
      (civility) => civility?.value === user?.getCivility()
    );
    const mainSpecialitySelectedResult = mainSpecialitiesOptions?.find(
      (mainSpeciality) => mainSpeciality?.value === user?.getMainSpeciality()
    );
    if (nniSelectedResult?.label && nniSelectedResult?.value)
      setNniSelected(nniSelectedResult);
    if (professionSelectedResult?.label && professionSelectedResult?.value)
      setProfessionSelected(professionSelectedResult);
    if (civilitySelectedResult?.label && civilitySelectedResult?.value)
      setCivilitySelected(civilitySelectedResult);
    if (
      mainSpecialitySelectedResult?.label &&
      mainSpecialitySelectedResult?.value
    )
      setMainSpecialitySelected(mainSpecialitySelectedResult);
    user?.getPhone()?.length === 9
      ? setPhoneSelected("0" + user?.getPhone())
      : setPhoneSelected(user?.getPhone());
    setFirstNameSelected(user?.getFirstName());
    setMailPreferenceSelected(user?.getMailPreference());
    setLastNameSelected(user?.getLastName());
    setExpeditorName(user?.getExpeditorName());
    setMailMedimail(user?.getMailMedimail());
    setStopSMSActive(user?.getStopSMSActive());
    setReceiveSMSFailureActive(user?.getReceiveSMSFailureActive());
    setAutoConsentPatient(user?.getAutoConsentPatient());
    setNoConfirmSends(user?.getNoConfirmSends());
    setShareConseilsStats(user?.getShareConseilsStats());
    setNniIdSelected(user?.getNniId());
  }, [user, professionsOptions]);

  const displayUser2 = () => {
    AuthService.get_profile(1)
      .then((response: any) => {
        setUsers2(response.data.user_meta);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handlePatientBase = () => {
    EventEmitter.emit("PatientBase", {
      page: "Profile",
    });
  };

  const get_user_id = () => {
    const id_string = localStorage.getItem("user_id");
    if (id_string) {
      const user_id = parseInt(id_string);
      return user_id;
    }
    return 0;
  };

  const displayUser = () => {
    AuthService.get_profile(user_id)
      .then((response: any) => {
        if (response.data.extra_user_meta)
          response?.data.extra_user_meta?.map((number: any) =>
            setUsers(number)
          );
        const dataToPut = {
          civility: response.data.extra_user_meta[0].civility,
          email: response.data.extra_user_meta[0].email,
          first_name: response.data.extra_user_meta[0].first_name,
          id: response.data.extra_user_meta[0].id,
          last_name: response.data.extra_user_meta[0].last_name,
          nni_id: response.data.extra_user_meta[0].nni_id,
          nni_type: response.data.extra_user_meta[0].nni_type,
          pathology_ids: response.data.extra_user_meta[0].pathology_ids,
          phone: response.data.extra_user_meta[0].phone,
          profession: response.data.extra_user_meta[0].profession_id,
          signature: response.data.extra_user_meta[0].signature,
          speciality_main: response.data.extra_user_meta[0].speciality_main_id,
        };
        setData(dataToPut);
        /*
        response.data.extra_user_meta.map(
          (person: any) => (tag = person.speciality_others_ids)
        );
        */
      })
      .catch((e) => {
        console.log(e);
      });
    miseAJour();
  };

  const getOthersSpecialitiesToSend = () => {
    const othersSpecialitiesToSend = [];

    if (othersSpecialitiesSelected?.length > 0) {
      for (const otherSpecialitySelected of othersSpecialitiesSelected) {
        othersSpecialitiesToSend.push(parseInt(otherSpecialitySelected?.value));
      }
    }
    return JSON.stringify(othersSpecialitiesToSend)
      ?.replace("[", "")
      ?.replace("]", "")
      ?.replace(",", ", ");
  };

  const isValidSubmit = () => {
    const invalidConditions = {
      emptyFirstName: !firstNameSelected || firstNameSelected?.length === 0,
      emptyLastName: !lastNameSelected || lastNameSelected?.length === 0,
      emptyProfession: !professionSelected?.value,
      emptyMainSpeciality: !mainSpecialitySelected?.value,
      emptyNniType: !nniSelected?.value,
      emptyNniId: !nniIdSelected || nniIdSelected?.length === 0,
      badPassword: passwordSelected != passwordConfirmationSelected,
      badPasswordLength: passwordSelected && passwordSelected?.length < 14,
      badPasswordMajuscule: passwordSelected && !/[A-Z]/.test(passwordSelected),
      badPasswordChiffre: passwordSelected && !/\d/.test(passwordSelected),
      badPasswordMinuscule: passwordSelected && !/[a-z]/.test(passwordSelected),
      invalidPhone: !lastIsValidPhoneNumber(phoneSelected),
      invalidMedimail: mailMedimail && !emailValidator(mailMedimail),
    };

    for (const condition of Object.keys(invalidConditions)) {
      if (invalidConditions[condition]) {
        if (!errorMessages?.[condition]) {
          return false;
        }
        if (error !== condition) setError(condition);
        return false;
      }
    }
    if (error !== "") setError("");
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const toSend: any = {
      first_name: firstNameSelected,
      mail_preference: mailPreferenceSelected,
      email_medimail: mailMedimail ? mailMedimail : "",
      expeditor_name: expeditorName ? expeditorName : "",
      last_name: lastNameSelected,
      profession: professionSelected?.value,
      civility: civilitySelected?.value ? civilitySelected?.value : "",
      phone: phoneSelected,
      speciality_main: mainSpecialitySelected?.value,
      speciality_others: getOthersSpecialitiesToSend(),
      nni_type: nniSelected?.value,
      nni_id: nniIdSelected,
      stop_sms_active: stopSMSActive,
      receive_sms_failure_active: receiveSMSFailureActive,
      auto_consent_patient: autoConsentPatient,
      no_confirm_sends: noConfirmSends,
      share_conseils_stats: shareConseilsStats,
    };
    if (passwordSelected.length != 0) toSend.user_pass = passwordSelected;

    if (userHasGroup === true) {
      try {
        await makeAPIRequest(
          "post",
          `/insi/update_my_certificate`,
          {
            INSICertificate: INSICertificate,
          },
          "v3"
        );
      } catch (err) {
        dispatch(
          openSnackbar({
            message: "Impossible de mettre à jour le certificat",
            type: "error",
            duration: 3000,
          })
        );
      }
    }

    TutorialService.update_user(user?.getId(), toSend)
      .then((res) => {
        const temp = JSON.parse(localStorage.getItem("user"));
        const user_data = {
          token: temp.token,
          user_display_name: firstNameSelected + " " + lastNameSelected,
          user_email: user?.getEmail(),
          user_id: user?.getId(),
          user_nicename: users2.nickname[0],
        };
        localStorage.setItem("user", JSON.stringify(user_data));
        EventEmitter.emit("userChange", {
          text: "value",
        });
        dispatch(getUser());
        swal({
          icon: "success",
          title: "Information mis à jour",
        });
        miseAJour();
      })
      .catch((e) => console.error(e));
  };

  const initialValues = {
    last_name: "",
    first_name: "",
  };

  const cancelDeleteAccount = () => {
    setDeleteMyAccount({
      isOpen: false,
      reason: "",
      waitingForConfirmationDelete: false,
      deleteLoading: false,
    });
  };

  return (
    <>
      {deleteMyAccount.waitingForConfirmationDelete && (
        <CancellablePopup
          firstTitle="Supprimer"
          secondTitle="mon compte"
          message="Attention, cette action est irréversible, votre compte ainsi que les données liées à celui-ci seront supprimés définitivement."
          confirmButtonText="Supprimer"
          cancelButtonText="Annuler"
          isLoading={deleteMyAccount.deleteLoading}
          onCancel={() => {
            cancelDeleteAccount();
          }}
          onConfirm={() => {
            setDeleteMyAccount((x) => {
              return { ...x, deleteLoading: true };
            });
          }}
        />
      )}
      {deleteMyAccount.isOpen && (
        <GenericFromPopup
          onClose={() => {
            cancelDeleteAccount();
          }}
          title={{
            leftPart: "Supprimer",
            rightPart: "mon compte",
          }}
        >
          <Grid
            container
            direction="column"
            wrap="nowrap"
            padding="20px"
            gap="15px"
          >
            <Grid item display="grid">
              <GenericLongText
                fieldTitle="Pour quelle raison souhaitez-vous supprimer votre compte ?"
                isMandatory={true}
                value={deleteMyAccount.reason}
                id={`reason-input`}
                maxLength={2000}
                onChange={(e) => {
                  setDeleteMyAccount((x) => {
                    const copy = { ...x };
                    if (e && typeof e === "string") {
                      copy.reason = e;
                    } else {
                      copy.reason = "";
                    }
                    return copy;
                  });
                }}
              />
            </Grid>
            <Grid item display="grid">
              <GenericSendButton
                errorMessages={{
                  noReason: "La raison de la suppression n'est pas renseignée.",
                }}
                errorSelector={{
                  noReason: "#reason-input",
                }}
                invalidConditions={{
                  noReason: !deleteMyAccount.reason,
                }}
                textButton="Continuer"
                onSendClick={() => {
                  setDeleteMyAccount((x) => {
                    return {
                      ...x,
                      waitingForConfirmationDelete: true,
                      isOpen: false,
                    };
                  });
                  makeAPIRequest(
                    "post",
                    "/user/delete_account",
                    { reason: deleteMyAccount.reason },
                    "v2"
                  )
                    .then((res: any) => {
                      snackbarUse.success(
                        "Votre compte a été supprimé avec succès, vous allez être déconnecté.",
                        res
                      );
                      setTimeout(() => {
                        forceDisconnectUser();
                      }, 3000);
                    })
                    .catch((err: any) => {
                      snackbarUse.error(
                        "Une erreur est survenue lors de la suppression de votre compte.",
                        err
                      );
                    })
                    .finally(() => {
                      setDeleteMyAccount((x) => {
                        return {
                          ...x,
                          deleteLoading: false,
                          waitingForConfirmationDelete: false,
                          isOpen: false,
                          reason: "",
                        };
                      });
                    });
                }}
              />
            </Grid>
          </Grid>
        </GenericFromPopup>
      )}
      <div className="container">
        {isPopupCertificateToFillOpen && INSICertificate && (
          <INSiCertificateAddPopup
            setIsPopupCertificateToFillOpen={setIsPopupCertificateToFillOpen}
            INSICertificate={INSICertificate}
            isPopupCertificateToFillOpen={isPopupCertificateToFillOpen}
            setINSICertificate={setINSICertificate}
          />
        )}
        <div className="list row"></div>
        <Formik initialValues={initialValues} onSubmit={handleSubmit}>
          <Form>
            <div className="container_form">
              {["0"].includes(user?.user?.account_type) && (
                <Grid
                  container
                  direction="column"
                  wrap="nowrap"
                  paddingTop="30px"
                >
                  <Grid item display="grid">
                    <span
                      style={{
                        fontFamily: "Poppins",
                        fontSize: "14px",
                        color: "#0B243C",
                      }}
                    >
                      Vous avez un compte de test avec {user?.user?.tokens}{" "}
                      jetons
                    </span>
                  </Grid>
                  <Grid item display="grid">
                    <span
                      style={{
                        fontFamily: "Poppins",
                        fontSize: "14px",
                        color: "#0B243C",
                      }}
                    >
                      Il expirera le{" "}
                      {(() => {
                        const splited = user?.user?.expiration?.split(" ");
                        const date = splited?.[0];
                        const time = splited?.[1];
                        const dateSplited = date?.split("-");
                        const timeSplited = time?.split(":");
                        const hours = timeSplited?.[0];
                        const minutes = timeSplited?.[1];
                        const seconds = timeSplited?.[2];
                        const year = dateSplited?.[0];
                        const month = dateSplited?.[1];
                        const day = dateSplited?.[2];

                        return `${day}/${month}/${year} à ${hours}:${minutes}`;
                      })()}
                    </span>
                  </Grid>
                </Grid>
              )}
              {["5"].includes(user?.user?.account_type) && (
                <Grid
                  container
                  direction="column"
                  wrap="nowrap"
                  paddingTop="30px"
                >
                  <Grid item display="grid">
                    <span
                      style={{
                        fontFamily: "Poppins",
                        fontSize: "14px",
                        color: "#0B243C",
                      }}
                    >
                      Vous avez un compte limité, vous n'avez pas accès à toutes les fonctionnalités.
                    </span>
                  </Grid>
                </Grid>
              )}
              {["1"].includes(user?.user?.account_type) && (
                <Grid
                  container
                  direction="column"
                  wrap="nowrap"
                  paddingTop="30px"
                >
                  <Grid item display="grid">
                    <span
                      style={{
                        fontFamily: "Poppins",
                        fontSize: "14px",
                        color: "#0B243C",
                      }}
                    >
                      Vous avez un compte de classique avec {user?.user?.tokens}{" "}
                      jetons
                    </span>
                  </Grid>
                </Grid>
              )}
              {displayConso && (
                <div style={{ width: "370px", marginTop: "30px" }}>
                  <span
                    style={{
                      fontSize: "16px",
                      color: "#0B243C",
                      fontFamily: "Poppins",
                      marginLeft: "7px",
                    }}
                  >
                    Consommation mensuelle ({consommation.nbSendedThisMonth}/{consommation.limitSMS} envoi{consommation.nbSendedThisMonth > 1 ? 's' : ''})
                  </span>
                  {percentConsommation > 0 ? (
                    <div
                      className={"animated-progress"}
                      style={{ height: "35px", marginTop: "5px", }}
                    >
                      <span
                        style={{
                          width: percentConsommation <= 15 ? "15%" : `${percentConsommation}%`,
                          color: "#FFF",
                          alignItems: "center",
                          justifyContent: "flex-end",
                          display: "flex",
                          paddingLeft: "15px",
                          paddingRight: "15px",
                          textAlign: "right",
                          fontSize: "14px",
                        }}
                      >
                        {percentConsommation}%
                      </span>
                    </div>
                  ) : (
                    <div
                      className={"animated-progress"}
                      style={{ height: "35px", marginTop: "5px", }}
                    >
                      <span
                        style={{
                          width: `0%`,
                          backgroundColor: "transparent",
                          color: "#0B243C",
                          alignItems: "center",
                          justifyContent: "flex-end",
                          display: "flex",
                          paddingLeft: "30px",
                          textAlign: "right",
                          fontSize: "14px",
                        }}
                      >
                        0%
                      </span>
                    </div>
                  )}
                </div>
              )}
              <div className="identity_name">
                <div className="title_box">Identité</div>
              </div>
              {user ? (
                <div className="first_section">
                  <div className="first_name">
                    <div className="title_input">Prénom *</div>
                    <Field
                      style={{
                        marginTop: "0px",
                        borderColor:
                          showError && error === "emptyFirstName"
                            ? "#FF3100"
                            : null,
                      }}
                      name="first_name"
                      id="input-first-name"
                      type="text"
                      value={firstNameSelected}
                      onChange={(event) =>
                        setFirstNameSelected(event.target.value)
                      }
                      className="input-form border-animation"
                    />
                  </div>
                  <div className="last_name">
                    <div className="title_input">Nom *</div>
                    <Field
                      style={{
                        marginTop: "0px",
                        borderColor:
                          showError && error === "emptyLastName"
                            ? "#FF3100"
                            : null,
                      }}
                      name="last_name"
                      type="text"
                      id="input-last-name"
                      value={lastNameSelected}
                      onChange={(event) =>
                        setLastNameSelected(event.target.value)
                      }
                      className="input-form border-animation"
                    />
                  </div>
                </div>
              ) : (
                <div>Veuillez patienter...</div>
              )}
              <div className="password_name">
                <div className="title_box">Mot de passe</div>
              </div>
              <div className="secon_section">
                <div className="first_pass">
                  <div className="title_input">Nouveau mot de passe</div>
                  <Field
                    style={{
                      marginTop: "0px",
                      borderColor:
                        showError && error === "badPassword" ? "#FF3100" : null,
                    }}
                    onChange={(event) =>
                      setPasswordSelected(event.target.value)
                    }
                    value={passwordSelected}
                    name="user_pass"
                    autoComplete="new-password"
                    id="input-password"
                    type="password"
                    placeholder="• • • • • • •"
                    className="input-form border-animation"
                  />
                </div>
                <div className="second_pass">
                  <div className="title_input">Répéter le mot de passe</div>
                  <Field
                    style={{
                      marginTop: "0px",
                      borderColor:
                        showError && error === "badPassword" ? "#FF3100" : null,
                    }}
                    onChange={(event) =>
                      setPasswordConfirmationSelected(event.target.value)
                    }
                    value={passwordConfirmationSelected}
                    id="input-password-repeat"
                    name="user_pass"
                    autoComplete="new-password"
                    type="password"
                    placeholder="• • • • • • •"
                    className="input-form border-animation"
                  />
                </div>
              </div>
              {(passwordSelected || passwordConfirmationSelected) && (
                <div style={{ marginTop: "15px" }}>
                  <span
                    style={{
                      textAlign: "left",
                      fontSize: "12px",
                      fontFamily: "Poppins",
                      color: "#0B243C",
                    }}
                  >
                    {`Le mot de passe doit être composé d'au minimum
                    14 caractères comprenant des majuscules, des minuscules et
                    des chiffres.`}
                  </span>
                </div>
              )}
              {professionsOptions && user?.user ? (
                <div className="last_section">
                  <div className="param_name">
                    <div className="title_box">Paramétrage</div>
                  </div>
                  <div className="first_param">
                    <div className="profession">
                      <div className="title_input">Profession *</div>
                      <div
                        className="Profession_container border-animation"
                        style={{
                          border:
                            showError && error === "emptyProfession"
                              ? "1px solid #FF3100"
                              : "1px solid transparent",
                          borderRadius: "10px",
                          width: "80%",
                        }}
                      >
                        <Select
                          name={"profession"}
                          noOptionsMessage={() => "En cours de chargement"}
                          options={professionsOptions}
                          value={professionSelected}
                          hideSelectedOptions={true}
                          id="input-profession"
                          className="border-animation"
                          isClearable={false}
                          isSearchable={true}
                          placeholder="Sélectionner une profession"
                          onChange={(element) => {
                            setProfessionSelected({
                              value: element?.value,
                              label: element?.label,
                            });
                          }}
                        />
                      </div>
                    </div>
                    <div className="phone">
                      <div className="title_input">Téléphone *</div>
                      <Field
                        style={{
                          marginTop: "0px",
                          borderColor:
                            showError && error === "invalidPhone"
                              ? "#FF3100"
                              : null,
                        }}
                        name="phone"
                        type="text"
                        id="input-phone"
                        value={phoneSelected}
                        onChange={(event) =>
                          setPhoneSelected(event.target.value)
                        }
                        className="input-form border-animation"
                      />
                    </div>
                  </div>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <div className="profession">
                      <div className="title_input">Type de numéro *</div>
                      <div
                        className="Profession_container border-animation"
                        style={{
                          border:
                            showError && error === "emptyNniType"
                              ? "1px solid #FF3100"
                              : "1px solid transparent",
                          borderRadius: "10px",
                          width: "80%",
                        }}
                      >
                        <Select
                          name="nni_type"
                          noOptionsMessage={() => "En cours de chargement"}
                          options={nniOptions}
                          value={nniSelected}
                          id="input-type-number"
                          className="border-animation"
                          hideSelectedOptions={true}
                          isClearable={false}
                          isSearchable={true}
                          placeholder="Sélectionner un type de numéro"
                          onChange={(element) => {
                            setNniSelected({
                              value: element?.value,
                              label: element?.label,
                            });
                          }}
                        />
                      </div>
                    </div>
                    <div className="phone">
                      <div className="title_input">Numéro RPPS/ADELI *</div>
                      <Field
                        style={{
                          marginTop: "0px",
                          borderColor:
                            showError && error === "emptyNniId"
                              ? "#FF3100"
                              : null,
                        }}
                        name="nni_id"
                        type="text"
                        id="input-nni-id"
                        value={nniIdSelected}
                        onChange={(event) =>
                          setNniIdSelected(event.target.value)
                        }
                        className="input-form border-animation"
                      />
                    </div>
                  </div>
                  <div className="first_section">
                    <div className="mail_preference">
                      <div className="title_input">
                        Adresse pour recevoir nos e-mails
                      </div>
                      <Field
                        style={{
                          marginTop: "0px",
                          borderColor: null,
                          width: "327px",
                        }}
                        placeholder={user?.getEmail()}
                        name="mail_preference"
                        id="input-reception-email"
                        type="text"
                        value={mailPreferenceSelected}
                        onChange={(event) =>
                          setMailPreferenceSelected(event.target.value)
                        }
                        className="input-form border-animation"
                      />
                    </div>
                  </div>
                  <div className="civility">
                    <div className="title_input">Civilité</div>
                    <Select
                      name="civility"
                      noOptionsMessage={() => "En cours de chargement"}
                      options={civilitiesOptions}
                      value={civilitySelected}
                      hideSelectedOptions={true}
                      isClearable={true}
                      isSearchable={true}
                      placeholder="Sélectionner une civilité"
                      onChange={(element) => {
                        setCivilitySelected({
                          value: element?.value,
                          label: element?.label,
                        });
                      }}
                    />
                  </div>
                  <div
                    className="spe_main"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <div className="title_input">Spécialité principale *</div>
                    <div
                      className="spec_main_profile border-animation"
                      style={{
                        border:
                          showError && error === "emptyMainSpeciality"
                            ? "1px solid #FF3100"
                            : "1px solid transparent",
                        borderRadius: "10px",
                      }}
                    >
                      <Select
                        name="spec_main"
                        options={mainSpecialitiesOptions}
                        value={mainSpecialitySelected}
                        hideSelectedOptions={true}
                        id="input-main-speciality"
                        isClearable={false}
                        isSearchable={true}
                        noOptionsMessage={() => "En cours de chargement"}
                        placeholder="Sélectionner une spécialité"
                        onChange={(element) => {
                          setMainSpecialitySelected({
                            value: element?.value,
                            label: element?.label,
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="spe_other">
                    <div className="title_input">Autre(s) spécialité(s)</div>
                    <div className="spec_other_profile">
                      <Select
                        name={"spec_other"}
                        options={mainSpecialitiesOptions}
                        noOptionsMessage={() => "En cours de chargement"}
                        isMulti={true}
                        value={othersSpecialitiesSelected}
                        placeholder={""}
                        onChange={(values) =>
                          setOthersSpecialitiesSelected(values as any)
                        }
                      />
                    </div>
                  </div>

                  {process.env.REACT_APP_ENABLE_MEDIMAIL == "true" &&
                    process.env.REACT_APP_USER_CAN_EDIT_MEDIMAIL == "true" && (
                      <div className="first_name" style={{ marginTop: "15px" }}>
                        <div className="title_input">
                          <span>Email medimail</span>
                        </div>
                        <Field
                          style={{
                            marginTop: "0px",
                            height: "41px",
                            maxWidth: "230px",
                            backgroundColor: "rgba(237, 242, 242, 0.5)",
                          }}
                          name="email_medimail"
                          id="input-email-medimail"
                          type="text"
                          placeholder={""}
                          value={mailMedimail}
                          onChange={(event) =>
                            setMailMedimail(event.target.value)
                          }
                          className="input-form border-animation"
                        />
                      </div>
                    )}
                  <div className="first_name" style={{ marginTop: "15px" }}>
                    <Grid
                      className="title_input"
                      columnSpacing="10px"
                      alignItems="center"
                      container
                      direction="row"
                    >
                      <Grid item display="grid">
                        <span>Nom d'expéditeur</span>
                      </Grid>
                      <Grid item display="grid">
                        <span style={{ fontSize: "14px" }}>-</span>
                      </Grid>
                      <Grid item display="grid">
                        <span style={{ fontSize: "14px" }}>
                          {expeditorName ? expeditorName.length : 0}/11
                          caractères
                        </span>
                      </Grid>
                    </Grid>
                    <Field
                      style={{
                        marginTop: "0px",
                        height: "41px",
                        maxWidth: "230px",
                        backgroundColor: "rgba(237, 242, 242, 0.5)",
                      }}
                      name="expeditor_name"
                      id="input-expeditor-name"
                      type="text"
                      placeholder={(() => {
                        if (expeditorName) {
                          return expeditorName;
                        }
                        let result = "";
                        if (civilitySelected?.value)
                          result += civilitySelected?.label;

                        if (lastNameSelected) result += ` ${lastNameSelected}`;
                        return result.substring(0, 11);
                      })()}
                      value={expeditorName}
                      onChange={(event) =>
                        setExpeditorName(event.target.value.substring(0, 11))
                      }
                      className="input-form border-animation"
                    />
                  </div>

                  {/*<div style={{ paddingTop: "15px" }}>
                    <OrangeCheckBox
                      onClick={() =>
                        setStopSMSActive((x) => (x == "1" ? "0" : "1"))
                      }
                      value={stopSMSActive == "1" ? true : false}
                      text="Envoyer mes SMS avec le STOP"
                    />
                    </div>*/}

                  <div style={{ paddingTop: "15px" }}>
                    <OrangeCheckBox
                      onClick={() =>
                        setReceiveSMSFailureActive((x) =>
                          x == "1" ? "0" : "1"
                        )
                      }
                      value={receiveSMSFailureActive == "1" ? true : false}
                      text="Recevoir une alerte par e-mail à chaque échec d'envoi"
                    />
                  </div>
                  <div style={{ paddingTop: "15px" }}>
                    <OrangeCheckBox
                      onClick={() =>
                        setAutoConsentPatient((x) =>
                          x == "1" ? "0" : "1"
                        )
                      }
                      value={autoConsentPatient == "1" ? true : false}
                      text="Pré-cocher automatiquement le consentement du patient"
                    />
                  </div>
                  <div style={{ paddingTop: "15px" }}>
                    <OrangeCheckBox
                      onClick={() =>
                        setNoConfirmSends((x) =>
                          x == "1" ? "0" : "1"
                        )
                      }
                      value={noConfirmSends == "1" ? true : false}
                      text="Confirmer automatiquement le panier d'envoi"
                    />
                  </div>
                  <div style={{ paddingTop: "15px" }}>
                    <OrangeCheckBox
                      onClick={() =>
                        setShareConseilsStats((x) =>
                          x == "1" ? "0" : "1"
                        )
                      }
                      value={shareConseilsStats == "1" ? true : false}
                      text={`Créer mon URL de statistiques conseils (${process.env.REACT_APP_WEB_APP_URL}/#/stats_activite/${user?.getId()})`}
                    />
                  </div>

                  {userHasGroup && (
                    <>
                      <div className="param_name">
                        <div className="title_box">Téléservices INSi & DMP</div>
                      </div>
                      <Grid item display="grid" paddingTop="15px">
                        <FullField
                          isMandatory={false}
                          title="Certificat INSi de votre groupe"
                        >
                          <Grid container direction="row">
                            <Grid item display="grid" style={{ width: "100%" }}>
                              <ImportButton
                                isAfterImport={INSICertificate != undefined}
                                fileNameAfterImport={INSICertificate?.name}
                                onCloseAfterImport={() =>
                                  setINSICertificate(undefined)
                                }
                                label={"Joindre un certificat (INSi)"}
                                onChange={(e) => {
                                  const fileReader = new FileReader();
                                  if (e.target.files[0] != null) {
                                    if (e.target.files[0].size >= 500000000) {
                                      dispatch(
                                        openSnackbar({
                                          message: "Le fichier est trop lourd",
                                          type: "error",
                                          duration: 3000,
                                        })
                                      );
                                    } else {
                                      fileReader.readAsBinaryString(
                                        e.target.files[0]
                                      );
                                      fileReader.onload = (event) => {
                                        setIsPopupCertificateToFillOpen(true);
                                        setINSICertificate({
                                          binary: event.target.result as string,
                                          name: e.target.files[0].name,
                                          password: "",
                                          versionLPS: "",
                                          IDAM: "",
                                          infos: null,
                                        });
                                      };
                                    }
                                  }
                                }}
                                acceptedFormat={[".pfx", ".p12"]}
                              >
                                <>
                                  {INSICertificate?.infos?.validity && (
                                    <span
                                      style={{
                                        fontSize: "12px",
                                        marginLeft: "15px",
                                        color: "#0B243C",
                                        fontFamily: "Poppins",
                                      }}
                                    >
                                      Valide du{" "}
                                      {getDDMMYYYYFromTimestamp(
                                        parseInt(
                                          INSICertificate.infos.validity.from
                                        )
                                      )}{" "}
                                      au{" "}
                                      {getDDMMYYYYFromTimestamp(
                                        parseInt(
                                          INSICertificate.infos.validity.to
                                        )
                                      )}
                                    </span>
                                  )}
                                  {INSICertificate?.infos && (
                                    <Grid
                                      container
                                      direction="row"
                                      justifySelf="flex-start"
                                      paddingBottom="3px"
                                      paddingTop="5px"
                                      marginLeft="8px"
                                      wrap="nowrap"
                                      width="unset"
                                      onClick={() =>
                                        setDisplayCertificateINSiDetails(
                                          (s) => !s
                                        )
                                      }
                                      style={{
                                        cursor: "pointer",
                                      }}
                                    >
                                      <Grid
                                        item
                                        display="grid"
                                        alignSelf="center"
                                        justifySelf="center"
                                      >
                                        {displayCertificateINSiDetails ? (
                                          <ArrowDropDown
                                            style={{
                                              color: "#0B243C",
                                            }}
                                          />
                                        ) : (
                                          <ArrowRightOutlined
                                            style={{
                                              color: "#0B243C",
                                            }}
                                          />
                                        )}
                                      </Grid>
                                      <Grid
                                        item
                                        display="grid"
                                        alignSelf="center"
                                        justifySelf="center"
                                      >
                                        <span
                                          style={{
                                            color: "#0B243C",
                                            fontSize: "12px",
                                            fontWeight: "bold",
                                            fontFamily: "Poppins",
                                          }}
                                        >
                                          Détails
                                        </span>
                                      </Grid>
                                    </Grid>
                                  )}
                                  {INSICertificate?.infos &&
                                  displayCertificateINSiDetails ? (
                                    <Grid
                                      container
                                      direction="column"
                                      wrap="nowrap"
                                      paddingLeft="40px"
                                      paddingBottom="10px"
                                      paddingRight="15px"
                                    >
                                      <Grid item display="grid">
                                        <span
                                          style={{
                                            fontFamily: "Poppins",
                                            fontWeight: "bold",
                                            fontSize: "12px",
                                            color: "#0B243C",
                                          }}
                                        >
                                          {
                                            "Entité représentée par le certificat"
                                          }
                                        </span>
                                      </Grid>
                                      {Object.keys(
                                        INSICertificate.infos.subject
                                      ).map((subjectElementKey, index) => {
                                        if (
                                          INSICertificate.infos.subject[
                                            subjectElementKey
                                          ] === "Inconnu"
                                        ) {
                                          return <></>;
                                        }
                                        return (
                                          <Grid item display="grid" key={index}>
                                            <span
                                              style={{
                                                fontFamily: "Poppins",
                                                fontSize: "12px",
                                                color: "#0B243C",
                                              }}
                                            >
                                              {subjectElementKey} :{" "}
                                              {
                                                INSICertificate.infos.subject[
                                                  subjectElementKey
                                                ]
                                              }
                                            </span>
                                          </Grid>
                                        );
                                      })}
                                      <Grid
                                        item
                                        display="grid"
                                        paddingTop="10px"
                                      >
                                        <span
                                          style={{
                                            fontFamily: "Poppins",
                                            fontWeight: "bold",
                                            fontSize: "12px",
                                            color: "#0B243C",
                                          }}
                                        >
                                          {"Émetteur"}
                                        </span>
                                      </Grid>
                                      {Object.keys(
                                        INSICertificate.infos.issuer
                                      ).map((issuerElementKey, index) => {
                                        if (
                                          INSICertificate.infos.issuer[
                                            issuerElementKey
                                          ] === "Inconnu"
                                        ) {
                                          return <></>;
                                        }
                                        return (
                                          <Grid item display="grid" key={index}>
                                            <span
                                              style={{
                                                fontFamily: "Poppins",
                                                fontSize: "12px",
                                                color: "#0B243C",
                                              }}
                                            >
                                              {issuerElementKey} :{" "}
                                              {
                                                INSICertificate.infos.issuer[
                                                  issuerElementKey
                                                ]
                                              }
                                            </span>
                                          </Grid>
                                        );
                                      })}
                                      <Grid
                                        item
                                        display="grid"
                                        paddingTop="10px"
                                      >
                                        <span
                                          style={{
                                            fontFamily: "Poppins",
                                            fontWeight: "bold",
                                            fontSize: "12px",
                                            color: "#0B243C",
                                          }}
                                        >
                                          {"Informations LPS"}
                                        </span>
                                      </Grid>
                                      <Grid item display="grid">
                                        <span
                                          style={{
                                            fontFamily: "Poppins",
                                            fontSize: "12px",
                                            color: "#0B243C",
                                          }}
                                        >
                                          {`IDAM : ${
                                            INSICertificate?.IDAM
                                              ? INSICertificate?.IDAM
                                              : "Inconnu"
                                          }`}
                                        </span>
                                      </Grid>
                                      <Grid item display="grid">
                                        <span
                                          style={{
                                            fontFamily: "Poppins",
                                            fontSize: "12px",
                                            color: "#0B243C",
                                          }}
                                        >
                                          {`Version : ${
                                            INSICertificate?.versionLPS
                                              ? INSICertificate?.versionLPS
                                              : "Inconnu"
                                          }`}
                                        </span>
                                      </Grid>
                                    </Grid>
                                  ) : (
                                    <></>
                                  )}
                                </>
                              </ImportButton>
                            </Grid>
                            <Grid item display="grid"></Grid>
                          </Grid>
                        </FullField>
                      </Grid>
                    </>
                  )}
                  {/* TELESERVICES INSI & DMP */}
                </div>
              ) : (
                <div style={{ paddingTop: "20px" }}>Veuillez patienter...</div>
              )}
              {user && (
                <div>
                  <div className="oblig">* Champs obligatoires</div>
                  {error?.length === 0 && (
                    <span
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        textAlign: "center",
                        margin: "30px 0px 5px 0px",
                      }}
                      className="title_input"
                    >
                      Le nom d'expéditeur de votre SMS sera :{" "}
                      {(() => {
                        if (expeditorName) {
                          return expeditorName;
                        }
                        let result = "";
                        if (civilitySelected?.value)
                          result += civilitySelected?.label;

                        if (lastNameSelected) result += ` ${lastNameSelected}`;
                        return result.substring(0, 11);
                      })()}
                    </span>
                  )}
                </div>
              )}
              {professionsOptions && users && (
                <div
                  className="button_area"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {isValidSubmit() ? (
                    <OrangeButton
                      animation={true}
                      enable={true}
                      type="submit"
                      text="Mettre à jour"
                      onClick={handleSubmit}
                    />
                  ) : (
                    <Grid container direction="column" wrap="nowrap">
                      <Grid
                        container
                        direction="row"
                        display="flex"
                        justifyContent="center"
                        spacing="5px"
                        marginBottom="10px"
                        marginTop="25px"
                      >
                        <Grid item display="grid" alignItems="center">
                          <span
                            className="field_star"
                            style={{ color: "#FF3100" }}
                          >
                            {errorMessages[error]}
                          </span>
                        </Grid>
                        <Grid item display="grid" alignItems="center">
                          <button
                            style={{
                              background: "none",
                              border: "none",
                              padding: "0",
                              margin: "0",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                            onMouseLeave={() => {
                              setTimeout(() => {
                                if (showError) setShowError(false);
                              }, 2250);
                            }}
                            onClick={(e) => {
                              if (!showError) setShowError(true);
                              e.preventDefault();
                              if (error === "" || errorSelector?.[error] === "")
                                return;
                              const section = document?.querySelector(
                                errorSelector?.[error]
                              );
                              section?.scrollIntoView({
                                behavior: "smooth",
                                block: "center",
                              });
                            }}
                          >
                            <Help
                              className="field_star"
                              style={{ height: "20px", color: "#FF3100" }}
                            />
                          </button>
                        </Grid>
                      </Grid>
                      <Grid item display="flex" justifyContent="center">
                        <OrangeButton
                          animation={true}
                          enable={false}
                          type="submit"
                          text="Mettre à jour"
                          onClick={handleSubmit}
                        />
                      </Grid>
                    </Grid>
                  )}
                </div>
              )}
            </div>
          </Form>
        </Formik>
        <Grid
          container
          direction="column"
          wrap="nowrap"
          padding="20px"
          marginTop="10px"
          gap="10px"
        >
          <Grid item display="grid">
            <Grid
              container
              direction="row"
              gap="20px"
              justifyContent={"space-around"}
            >
              <Grid item display="grid">
                <a
                  className="text-button"
                  href="https://media.certipair.fr/CERTIPAIR+CGU.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  Voir les CGU
                </a>
              </Grid>
              <Grid item display="grid">
                <Grid
                  container
                  direction="row"
                  gap="5px"
                  wrap="nowrap"
                  alignItems={"center"}
                >
                  <Grid item display="grid">
                    <span
                      onClick={() => {
                        setDeleteMyAccount({
                          reason: "",
                          isOpen: true,
                        });
                      }}
                      className="text-button"
                    >
                      Supprimer mon compte
                    </span>
                  </Grid>
                  <Grid item display="grid">
                    <Tooltip
                      title={`Après suppression de votre compte, vos données seront effacées définitivement (patients, questionnaires, messages programmés, messages envoyés, etc...)`}
                    >
                      <Help
                        style={{
                          height: "16px",
                          width: "16px",
                          cursor: "help",
                          color: "#FF3100",
                        }}
                      />
                    </Tooltip>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default MonCompteContent;
