import { useDispatch } from "react-redux";
import { CircularProgress, Grid, IconButton, Tooltip } from "@mui/material";
import React, { useState, useRef, useEffect } from "react";
import TutorialDataService from "../../services/TutorialService";
import * as editAdviceReducer from "../../redux/editAdviceReducer";
import * as createAdviceReducer from "../../redux/createAdviceReducer";
import ReactDOM from "react-dom";
import FormPopupHeader from "./FormPopupHeader";
import FormPopup from "./FormPopup";
import AdviceTypeField from "../Fields/AdviceFields/AdviceTypeField";
import AdviceTitleField from "../Fields/AdviceFields/AdviceTitleField";
import AdviceSpecialityField from "../Fields/AdviceFields/AdviceSpecialityField";
import AdviceContentField from "../Fields/AdviceFields/AdviceContentField";
import AdviceUrlField from "../Fields/AdviceFields/AdviceUrlField";
import AdviceSendPeriodField from "../Fields/AdviceFields/AdviceSendPeriodField";
import AdviceAttachField from "../Fields/AdviceFields/AdviceAttachField";
import AdviceAddToFavorites from "../Fields/AdviceFields/AdviceAddToFavorites";
import OrangeButton from "../Library/Buttons/OrangeButton/OrangeButton";
import {
  Cancel,
  Help,
  PauseCircle,
  PlayArrow,
  PlayCircleFilled,
  SearchOutlined,
  StopCircle,
} from "@mui/icons-material";
import CancellablePopup from "../Library/Popups/CancellablePopup";
import GreyButton from "../Library/Buttons/GreyButton/GreyButton";
import InformationPopup from "../Library/Popups/InformationPopup";
import EventEmitter from "../../services/event";
import TextInput from "../Library/Inputs/TextInput";
import FullField from "../Fields/FullField";
import makeAPIRequest from "../../utils/makeAPIRequest";
import { openSnackbar } from "../../redux/snackbarReducer";
import ErrorHelp from "../Library/Errors/ErrorHelp";
import AdviceDisplayName from "../Fields/AdviceFields/AdviceDisplayName";
import { GenericSelect } from "../../pages/QuestionnairePage";
import useAppSelector from "../../redux/useAppSelector";
import OrangeCheckBox from "../Fields/CheckBox/OrangeCheckBox";
import useAppDispatch from "../../redux/useAppDispatch";
import {
  GenericTextInput,
  useOpenSnackbar,
} from "../../pages/CreateOrEditChatbotLexPage";
import {
  GenericFromPopup,
  GenericSendButton,
} from "../../pages/ChatbotLexPage";
import isPositiveNumber, {
  isPositiveFloat,
} from "../../utils/isPositiveNumber";
import { calcCostConseil } from "../MesConseilsBox/TabsContainer/AdminTab/AdminTabContent";

export const MAX_MB_FILE = 50;

const ImportAdvice: React.FC<any> = (props) => {
  const dispatch = useDispatch();
  const [searchInput, setSearchInput] = React.useState("");
  const [isSearchLoading, setIsSearchLoading] = React.useState(false);
  const [selectedConseil, setSelectedConseil] = React.useState(null);
  const [foundedAdvices, setFoundedAdvices] = React.useState([]);
  const [error, setError] = React.useState("");
  const { reducer, selector, type } = props;

  const errorMessagesImportAdvice = {
    emptyFoundedAdvices: "Aucune recherche n'a été effectuée",
    noConseilSelected: "Aucun conseil n'est selectionné",
    "": "",
  };

  const errorSelectorImportAdvice = {
    emptyFoundedAdvices: "#search-conseil-input",
    noConseilSelected: "#search-conseil-input",
    "": "",
  };

  const isValidSubmit = () => {
    const invalidConditions = {
      emptyFoundedAdvices: !foundedAdvices || foundedAdvices.length === 0,
      noConseilSelected: !selectedConseil,
    };

    for (const condition of Object.keys(invalidConditions)) {
      if (invalidConditions[condition]) {
        if (!errorMessagesImportAdvice?.[condition]) {
          return false;
        }
        if (error !== condition) setError(condition);
        return false;
      }
    }
    if (error !== "") setError("");
    return true;
  };

  return (
    <FormPopup>
      <Grid
        item
        display="grid"
        paddingTop="40px"
        paddingLeft="50px"
        paddingRight="50px"
        paddingBottom="40px"
      >
        <FormPopupHeader
          firstTitle={"Importer"}
          secondTitle={"un conseil"}
          onClose={() => dispatch(reducer?.closeImportAdvice())}
        />
      </Grid>
      <Grid item display="grid" paddingBottom="40px">
        <Grid
          container
          wrap="nowrap"
          width="70%"
          justifySelf="center"
          direction="column"
        >
          <Grid item display="grid">
            <FullField title="Rechercher un conseil :" isMandatory={false}>
              <TextInput
                RightIcon={
                  <Tooltip title="Lancer la recherche">
                    <IconButton
                      onClick={() => {
                        setIsSearchLoading(true);
                        makeAPIRequest(
                          "post",
                          "/conseils/search",
                          {
                            search: searchInput,
                          },
                          "v2"
                        )
                          .then((res) => {
                            setIsSearchLoading(false);
                            let founded =
                              res?.data?.data?.search_conseils_results
                                ?.search_conseils;
                            if (selector?.[type]?.adviceOpenIndex !== -1) {
                              console.log(founded);
                              founded = founded?.filter(
                                (e) => e?.type == "simple"
                              );
                            }
                            if (founded && founded.length > 0) {
                              setSelectedConseil(founded[0]);
                              setFoundedAdvices(founded);
                            } else {
                              setFoundedAdvices([]);
                              setSelectedConseil(null);
                            }
                          })
                          .catch((err) => {
                            setIsSearchLoading(false);
                            dispatch(
                              openSnackbar({
                                type: "error",
                                message:
                                  "Une erreur est survenue lors de la recherche du conseil",
                                duration: 3000,
                              })
                            );
                            console.log(err);
                          });
                      }}
                      style={{ margin: "0", padding: "0" }}
                    >
                      <SearchOutlined
                        id="search-conseil-input"
                        style={{
                          color: "#0B243C",
                          borderRadius: "100px",
                          border: "0px solid transparent",
                        }}
                      />
                    </IconButton>
                  </Tooltip>
                }
                height="100%"
                padding="6px 25px 6px 25px"
                placeholder="Titre ou contenu du conseil"
                value={searchInput}
                onChange={(e) => setSearchInput(e.target.value)}
              />
            </FullField>
          </Grid>
          {isSearchLoading && (
            <Grid
              container
              direction="row"
              display="flex"
              justifyContent="center"
              alignItems="center"
              paddingTop="20px"
            >
              <CircularProgress />
            </Grid>
          )}
          {foundedAdvices.length > 0 && !isSearchLoading && (
            <Grid item display="grid" paddingTop="20px">
              <FullField title="Choisissez votre conseil :" isMandatory={false}>
                <Grid container direction="column" wrap="nowrap" gap="10px">
                  {foundedAdvices.map((advice, index) => {
                    if (index > 10) return null;
                    return (
                      <Grid item key={advice?.id} display="grid">
                        <Grid
                          container
                          direction="row"
                          wrap="nowrap"
                          spacing="15px"
                        >
                          <Grid item display="grid">
                            <input
                              type="radio"
                              className="checkbox_type"
                              checked={selectedConseil?.id == advice?.id}
                              onChange={() => {
                                return null;
                              }}
                              onClick={() => {
                                /* changes here */
                                setSelectedConseil(advice);
                              }}
                            />
                          </Grid>
                          <Grid item display="grid">
                            <span
                              style={{
                                fontSize: "14px",
                                color: "#0B243C",
                                fontFamily: "Poppins",
                              }}
                            >
                              {advice?.type == "pack" ? "(pack)" : ""}{" "}
                              {advice?.title
                                ? advice.title
                                : "Titre introuvable"}
                            </span>
                          </Grid>
                        </Grid>
                      </Grid>
                    );
                  })}
                </Grid>
              </FullField>
            </Grid>
          )}
          <Grid item display="grid" paddingTop="30px">
            <Grid container direction="column" wrap="nowrap">
              {error?.length > 0 && (
                <Grid item display="grid">
                  <ErrorHelp
                    errorSelector={errorSelectorImportAdvice}
                    errorMessages={errorMessagesImportAdvice}
                    error={error}
                  />
                </Grid>
              )}
              <Grid item display="grid">
                <Grid
                  container
                  direction="row"
                  display="flex"
                  columnSpacing="15px"
                  rowSpacing="10px"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid item display="grid">
                    <GreyButton
                      animation={false}
                      enable={true}
                      text="Annuler"
                      onClick={() => {
                        dispatch(reducer?.closeImportAdvice());
                      }}
                    />
                  </Grid>
                  <Grid item display="grid">
                    {isValidSubmit() ? (
                      <OrangeButton
                        animation={false}
                        enable={true}
                        type="submit"
                        text={"Importer"}
                        onClick={() => {
                          dispatch(reducer?.addAdvice(selectedConseil));
                          dispatch(
                            openSnackbar({
                              message: "Votre conseil a bien été importé",
                              type: "success",
                              duration: 3000,
                            })
                          );
                          dispatch(reducer?.closeImportAdvice());
                        }}
                      />
                    ) : (
                      <OrangeButton
                        animation={false}
                        enable={false}
                        type="submit"
                        text={"Importer"}
                        onClick={() => {
                          return null;
                        }}
                      />
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </FormPopup>
  );
};

const MAX_ADVICE_IN_PACK = 20;

const errorMessages = {
  emptyTitle: "Le titre n'est pas renseigné.",
  emptySpeciality: "La spécialité n'est pas renseignée.",
  "": "",
};

const errorSelector = {
  emptyTitle: "#advice-title-input",
  emptySpeciality: "#advice-speciality-input",
  "": "",
};

for (let i = 0; i < MAX_ADVICE_IN_PACK; i++) {
  errorMessages[`emptyContent-${i}`] =
    i === 0
      ? "Le contenu n'est pas renseigné."
      : `Le contenu n'est pas renseigné dans le conseil n°${i + 1}.`;
  errorSelector[`emptyContent-${i}`] = `#advice-content-input-${i}`;

  /* Annuler l'envoi si le patient a répondu il y a moins de X jour(s) */
  errorMessages[`emptyPeriodCancelSend-${i}`] =
    i === 0
      ? "La période permettant d'annuler l'envoi en cas de réponse n'est pas renseignée."
      : `La période permettant d'annuler l'envoi en cas de réponse n'est pas renseignée dans le conseil n°${
          i + 1
        }.`;
  errorSelector[
    `emptyPeriodCancelSend-${i}`
  ] = `#advice-period-cancel-send-${i}`;
}

interface IMessage {
  hours?: number;
  id?: number;
  url?: string;
  attachment?: string;
  attachment_name?: string;
  content?: string;
  questionnaire_interactif_id?: string;
  short_url?: 0 | 1;
  stop_sms?: 0 | 1;
  questionnaire_interactif_title?: string;
  is_questionnaire_interactif_owner?: boolean;
  questionnaire_interactif_nano_id?: string;
  dont_send_form_if?: 0 | 1;
  dont_send_form_if_duration?: string;
}

interface IMessageFields {
  message?: IMessage;
  onChangeMessageField: any;
  onChangeHoursField: any;
  onChangeDontSendFormIfDuration: any;
  onChangeUrlField: any;
  onChangeQuestionnaireInteractifField: any;
  deleteAttachField: any;
  onClickShortUrl: any;
  onClickStopSMS: any;
  onClickDontSendFormIf: any;
  index?: number;
  updateAttachField: any;
  isPack?: boolean;
  error?: string;
  showError?: string;
  reducer?: any;
}

const RecordWebCam: React.FC<{
  onRecordEnded: (base64: string) => void;
  conseilIndex?: number | undefined;
}> = ({ onRecordEnded, conseilIndex }) => {
  const [isPrevisualisationOpen, setIsPrevisualisationOpen] =
    useState<boolean>(false);
  const [isRecording, setIsRecording] = useState<boolean>(false);
  const [isPaused, setIsPaused] = useState<boolean>(false);
  const [mediaStream, setMediaStream] = useState<MediaStream | null>(null);
  const [elapsedTime, setElapsedTime] = useState<number>(0);
  const [recordedSize, setRecordedSize] = useState<number>(0);
  const [chunkCount, setChunkCount] = useState<number>(0);
  const mediaRecorder = useRef<MediaRecorder | null>(null);
  const videoRef = useRef<HTMLVideoElement>(null);
  const chunks = useRef<Blob[]>([]);
  const intervalRef = useRef<number | null>(null);
  const snackbarOpenUse = useOpenSnackbar();
  const sizeInMB = recordedSize / (1024 * 1024);
  const isFileTooBig = sizeInMB >= MAX_MB_FILE;

  useEffect(() => {
    if (isFileTooBig) {
      pauseRecording();
      snackbarOpenUse.error(
        `Vous ne pouvez pas excéder les ${MAX_MB_FILE} MB.`
      );
    }
  }, [isFileTooBig]);

  useEffect(() => {
    if (isRecording && !isPaused) {
      intervalRef.current = window.setInterval(() => {
        setElapsedTime((prev) => prev + 1);
      }, 1000);
    } else {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
        intervalRef.current = null;
      }
    }

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [isRecording, isPaused]);

  useEffect(() => {
    const size = chunks.current.reduce((acc, chunk) => acc + chunk.size, 0);
    setRecordedSize(size);
  }, [chunkCount]);

  const startPreview = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: { width: 640, height: 480 },
        audio: true,
      });
      setMediaStream(stream);
      if (videoRef.current) {
        videoRef.current.srcObject = stream;
      }
    } catch (error) {
      snackbarOpenUse.error(
        "Impossible d'accéder à votre caméra et/ou microphone."
      );
    }
  };

  const startRecording = () => {
    if (mediaStream) {
      const mimeType = "video/webm; codecs=vp8,opus";
      if (MediaRecorder.isTypeSupported(mimeType)) {
        const options = {
          mimeType,
          videoBitsPerSecond: 2500000,
        };
        mediaRecorder.current = new MediaRecorder(mediaStream, options);
        mediaRecorder.current.ondataavailable = (event) => {
          if (event.data.size > 0) {
            chunks.current.push(event.data);
            setChunkCount((prev) => prev + 1);
          }
        };
        mediaRecorder.current.onstop = async () => {
          if (chunks.current.length > 0) {
            const blob = new Blob(chunks.current, { type: "video/webm" });
            const base64 = await convertBlobToBase64(blob);
            onRecordEnded(base64);
            if (conseilIndex !== undefined) {
              const s = document?.querySelector(
                `#conseil-attachment-${conseilIndex}`
              );
              s?.scrollIntoView({
                behavior: "smooth",
                block: "center",
              });
            }
          }
          setIsPrevisualisationOpen(false);
          chunks.current = [];
          setChunkCount(0);
          setRecordedSize(0); // Réinitialiser recordedSize lorsque l'enregistrement est terminé
        };
        mediaRecorder.current.start(1000);
        setIsRecording(true);
        setIsPaused(false);
        setElapsedTime(0);
        setRecordedSize(0);
      } else {
        snackbarOpenUse.error(
          `Le type MIME ${mimeType} n'est pas supporté dans votre navigateur.`
        );
      }
    }
  };

  const stopRecording = () => {
    if (mediaRecorder.current) {
      mediaRecorder.current.stop();
      setIsRecording(false);
      setIsPaused(false);
    }
  };

  const pauseRecording = () => {
    if (mediaRecorder.current && mediaRecorder.current.state === "recording") {
      mediaRecorder.current.pause();
      setIsPaused(true);
      setChunkCount(0); // Réinitialiser chunkCount lors de la pause
    }
  };

  const resumeRecording = () => {
    if (mediaRecorder.current && mediaRecorder.current.state === "paused") {
      mediaRecorder.current.resume();
      setIsPaused(false);
    }
  };

  const cancelRecording = (closePrevisualisation = false) => {
    if (mediaRecorder.current && (isRecording || isPaused)) {
      mediaRecorder.current.stop();
    }
    chunks.current = [];
    setChunkCount(0);
    setElapsedTime(0);
    setRecordedSize(0);
    setIsRecording(false);
    setIsPaused(false);
    stopMediaStream(); // Arrêter la caméra lors de l'annulation

    if (closePrevisualisation) {
      setIsPrevisualisationOpen(false);
    }
  };

  const convertBlobToBase64 = (blob: Blob): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result as string);
      };
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  };

  const closePreview = () => {
    stopMediaStream(); // Arrêter la caméra lorsque la prévisualisation est fermée
    setMediaStream(null);
  };

  useEffect(() => {
    if (!isPrevisualisationOpen && mediaStream) {
      closePreview();
    }
  }, [isPrevisualisationOpen]);

  const stopMediaStream = () => {
    if (mediaStream) {
      mediaStream.getTracks().forEach((track) => track.stop());
    }
  };

  const formatTime = (time: number): string => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes < 10 ? "0" : ""}${minutes}:${
      seconds < 10 ? "0" : ""
    }${seconds}`;
  };

  return (
    <Grid container direction="column" wrap="nowrap" gap="10px">
      <Grid item display="flex" justifyContent="flex-end" alignItems="center">
        {!isPrevisualisationOpen && (
          <button
            onClick={() => {
              setIsPrevisualisationOpen(true);
              startPreview();
            }}
            className="delete_advice_button"
            style={{ padding: "0", margin: "0", color: "#0B243C" }}
          >
            {`Enregistrer une vidéo avec ma caméra`}
          </button>
        )}
        {isPrevisualisationOpen && (
          <Grid
            container
            direction="row"
            wrap="nowrap"
            gap="20px"
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            {isRecording ? (
              <>
                <Grid item display="grid">
                  <Grid container direction="row" wrap="nowrap" gap="5px">
                    {isPaused ? (
                      <Tooltip
                        title="Reprendre l'enregistrement"
                        arrow
                        placement="top"
                      >
                        <IconButton
                          onClick={
                            isFileTooBig
                              ? () => {
                                  snackbarOpenUse.error(
                                    "Vous ne pouvez pas reprendre l'enregistrement, la limite de taille a été atteinte."
                                  );
                                }
                              : () => resumeRecording()
                          }
                        >
                          <PlayArrow
                            style={{ fontSize: 24, color: "#0B243C" }}
                          />
                        </IconButton>
                      </Tooltip>
                    ) : (
                      <Tooltip title="Pause" arrow placement="top">
                        <IconButton onClick={pauseRecording}>
                          <PauseCircle
                            style={{ fontSize: 24, color: "#0B243C" }}
                          />
                        </IconButton>
                      </Tooltip>
                    )}
                    <Tooltip
                      title="Arrêter l'enregistrement"
                      arrow
                      placement="top"
                    >
                      <IconButton onClick={stopRecording}>
                        <StopCircle
                          style={{ fontSize: 24, color: "#0B243C" }}
                        />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </Grid>
                <Grid item display="grid">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                      flexDirection: "row",
                    }}
                  >
                    <div
                      style={{
                        width: "10px",
                        height: "10px",
                        backgroundColor: "red",
                        borderRadius: "50%",
                      }}
                    ></div>
                    <span>{formatTime(elapsedTime)}</span>
                    <span style={{ marginLeft: "10px" }}>
                      {(recordedSize / (1024 * 1024)).toFixed(2)} MB / 50 MB
                    </span>
                  </div>
                </Grid>
              </>
            ) : (
              <Grid container direction="row" gap="5px">
                <Grid item display="grid">
                  <Tooltip
                    title="Commencer l'enregistrement"
                    arrow
                    placement="top"
                  >
                    <IconButton onClick={startRecording}>
                      <PlayCircleFilled
                        style={{ fontSize: 24, color: "#0B243C" }}
                      />
                    </IconButton>
                  </Tooltip>
                </Grid>
                <Grid item display="grid">
                  <Tooltip title="Fermer" arrow placement="top">
                    <IconButton onClick={() => cancelRecording(true)}>
                      <Cancel style={{ fontSize: 24, color: "#0B243C" }} />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
            )}
          </Grid>
        )}
      </Grid>
      {isPrevisualisationOpen && (
        <video ref={videoRef} autoPlay muted style={{ width: "100%" }}></video>
      )}
    </Grid>
  );
};

const MessageFields = (props: IMessageFields) => {
  const {
    message,
    index,
    onChangeMessageField,
    onChangeUrlField,
    onClickShortUrl,
    onClickStopSMS,
    onClickDontSendFormIf,
    onChangeHoursField,
    deleteAttachField,
    updateAttachField,
    onChangeDontSendFormIfDuration,
    onChangeQuestionnaireInteractifField,
    isPack,
    error,
    showError,
    reducer,
  } = props;

  const dispatch = useAppDispatch();
  const [recordIsEndedPopup, setRecordIsEndedPopup] = React.useState<{
    index: number;
    attachment: string;
    attachment_name: string;
  }>(undefined);
  const questionnairesInteractifs = useAppSelector(
    (x) => x.questionnaireInteractifReducer.list
  );

  return (
    <>
      {recordIsEndedPopup && (
        <GenericFromPopup
          onClose={() => setRecordIsEndedPopup(undefined)}
          title={{
            leftPart: "Enregistrer",
            rightPart: "ma vidéo",
          }}
        >
          <Grid
            container
            direction="column"
            wrap="nowrap"
            padding="20px"
            gap="5px"
          >
            <Grid item display="grid">
              <GenericTextInput
                fieldTitle="Titre de la vidéo"
                isMandatory={true}
                id="input-title-video"
                value={recordIsEndedPopup.attachment_name}
                onChange={(e) => {
                  setRecordIsEndedPopup((x) => {
                    return { ...x, attachment_name: e.target.value };
                  });
                }}
              />
            </Grid>
            <Grid item display="grid">
              <GenericSendButton
                errorMessages={{
                  noTitle: "Le titre de la vidéo est obligatoire.",
                }}
                errorSelector={{}}
                invalidConditions={{
                  noTitle: !recordIsEndedPopup?.attachment_name,
                }}
                textButton="Valider"
                onSendClick={() => {
                  dispatch(
                    reducer?.setAttachment({
                      index: recordIsEndedPopup.index,
                      attachment: recordIsEndedPopup.attachment,
                      attachment_name: recordIsEndedPopup.attachment_name,
                    })
                  );
                  setRecordIsEndedPopup(undefined);
                }}
              />
            </Grid>
          </Grid>
        </GenericFromPopup>
      )}
      <Grid container direction="column" wrap="nowrap" paddingTop="5px">
        <Grid item display="grid">
          <AdviceContentField
            itemBorder={
              showError && error === `emptyContent-${index}`
                ? "1px solid #FF3100"
                : null
            }
            index={index}
            adviceContent={message?.content}
            onChange={(e) => onChangeMessageField(e)}
          />
        </Grid>
        <Grid item display="grid" paddingTop="28px">
          <AdviceUrlField
            adviceUrl={message?.url}
            onChange={(e) => onChangeUrlField(e.target.value)}
          />
        </Grid>
        <Grid item display="grid" paddingTop="28px">
          <GenericSelect
            fieldTitle="Ajout d'un questionnaire interactif"
            maxMenuHeight="150px"
            isMandatory={false}
            value={
              message?.questionnaire_interactif_id &&
              message.questionnaire_interactif_title
                ? {
                    value: message.questionnaire_interactif_id,
                    label: message.questionnaire_interactif_title,
                  }
                : undefined
            }
            id="advice-quest-interactif"
            placeholder=""
            isClearable={true}
            options={
              questionnairesInteractifs
                ? questionnairesInteractifs
                    ?.filter((x) => x?.title && x?._id)
                    ?.map((x) => {
                      return {
                        label: x?.title,
                        value: x?._id,
                      };
                    })
                : []
            }
            onChange={(e) => {
              onChangeQuestionnaireInteractifField(e);
            }}
          />
        </Grid>
        <Grid
          item
          display="grid"
          paddingTop="28px"
          id={`conseil-attachment-${index}`}
        >
          <AdviceAttachField
            index={index}
            updateAttachField={updateAttachField}
            adviceAttachment={message?.attachment}
            adviceAttachmentName={message?.attachment_name}
            deleteAttachField={deleteAttachField}
          />
        </Grid>
        <Grid item display="grid" paddingTop="28px">
          <RecordWebCam
            conseilIndex={index}
            onRecordEnded={(base64) => {
              setRecordIsEndedPopup({
                index: index,
                attachment: base64,
                attachment_name: `enregistrement-webcam-${Date.now()}`,
              });
              /*
            dispatch(
              reducer?.setAttachment({
                index: index,
                attachment: base64,
                attachment_name: `enregistrement-webcam-${Date.now()}`,
              })
            );
            */
            }}
          />
        </Grid>
        {isPack && (
          <Grid item display="grid" paddingTop="28px">
            <AdviceSendPeriodField
              adviceHours={message?.hours}
              onChange={onChangeHoursField}
            />
          </Grid>
        )}

        <Grid item display="grid" paddingTop="20px">
          <OrangeCheckBox
            onClick={() => onClickStopSMS()}
            value={message?.stop_sms == 1}
            text="Envoyer avec le STOP SMS"
          />
        </Grid>
        {message?.url && (
          <>
            {message?.url?.includes("/#/q/") && (
              <Grid item display="grid" paddingTop="10px">
                <>
                  <OrangeCheckBox
                    onClick={() => {
                      onClickDontSendFormIf();
                      /*
                      setNodeParameters((state) => {
                        const copyObject = {
                          ...state.sendEmailBeforeUserExpire,
                        };

                        if (copyObject.isActive) {
                          copyObject.isActive = false;
                        } else {
                          copyObject.isActive = true;
                        }
                        return {
                          ...state,
                          sendEmailBeforeUserExpire: copyObject,
                        };
                      });
                      */
                    }}
                    value={message?.dont_send_form_if == 1}
                  >
                    <Grid
                      container
                      direction="row"
                      alignItems="center"
                      spacing="10px"
                    >
                      <Grid item display="grid">
                        <span className="text_champs">
                          {`Annuler l'envoi si le patient a répondu il y a moins de`}
                        </span>
                      </Grid>
                      <Grid item display="grid">
                        <div style={{ maxWidth: "65px" }}>
                          <TextInput
                            value={message?.dont_send_form_if_duration ? message.dont_send_form_if_duration : ""}
                            onChange={(e) => {
                              onChangeDontSendFormIfDuration(e?.target?.value ? e.target.value : "");
                            }}
                          />
                        </div>
                      </Grid>
                      <Grid item display="grid">
                        <span className="text_champs">{"jour(s)"}</span>
                      </Grid>
                    </Grid>
                  </OrangeCheckBox>
                </>
              </Grid>
            )}
            <Grid item display="grid" paddingTop="10px">
              <OrangeCheckBox
                onClick={() => onClickShortUrl()}
                value={message?.short_url == 1}
                text="Minimiser l'URL (ne pas décocher si cela concerne un questionnaire)"
              />
            </Grid>
          </>
        )}
        {isPack && (
          <Grid item display="grid" paddingTop="40px">
            <div style={{ border: "1px dashed #8D9899", width: "100%" }} />
          </Grid>
        )}
      </Grid>
    </>
  );
};

const UniqueAdviceContent: React.FC<any> = (props) => {
  const { actualAdvice, reducer, error, showError } = props;
  const dispatch = useDispatch();
  return (
    <Grid container direction="column" wrap="nowrap">
      <Grid item display="grid" paddingTop="37px">
        <span
          style={{
            font: "normal normal 600 20px/30px Poppins",
            color: "#23595D",
            letterSpacing: "0px",
            textAlign: "left",
          }}
        >
          Conseil unique
        </span>
      </Grid>
      <Grid item display="grid">
        <MessageFields
          error={error}
          showError={showError}
          reducer={reducer}
          isPack={false}
          index={0}
          updateAttachField={(
            index: number,
            attachment: string,
            attachment_name: string
          ) => {
            dispatch(
              reducer?.setAttachment({
                index: index,
                attachment: attachment,
                attachment_name: attachment_name,
              })
            );
          }}
          deleteAttachField={() =>
            dispatch(reducer?.deleteAttachFileAndName({ index: 0 }))
          }
          onChangeHoursField={(hours) =>
            dispatch(reducer?.setHours({ index: 0, hours: hours }))
          }
          onChangeUrlField={(e) =>
            dispatch(reducer?.setUrl({ index: 0, url: e }))
          }
          onClickShortUrl={() =>
            dispatch(
              reducer?.setShortUrl({
                index: 0,
                value: actualAdvice?.messages?.[0]?.[0]?.short_url == 1 ? 0 : 1,
              })
            )
          }
          onChangeDontSendFormIfDuration={(duration) => {
            dispatch(
              reducer?.setDontSendFormIfDuration({
                index: 0,
                value: duration,
              })
            );
          }}
          onClickDontSendFormIf={() => {
            dispatch(
              reducer?.setDontSendFormIf({
                index: 0,
                value:
                  actualAdvice?.messages?.[0]?.[0]?.dont_send_form_if == 1
                    ? 0
                    : 1,
              })
            );
          }}
          onClickStopSMS={() =>
            dispatch(
              reducer?.setStopSMS({
                index: 0,
                value: actualAdvice?.messages?.[0]?.[0]?.stop_sms == 1 ? 0 : 1,
              })
            )
          }
          onChangeMessageField={(e) =>
            dispatch(reducer?.setContent({ index: 0, content: e }))
          }
          onChangeQuestionnaireInteractifField={(e) =>
            dispatch(
              reducer?.setQuestionnaireInteractif({
                index: 0,
                value: e?.value,
                label: e?.label,
              })
            )
          }
          message={actualAdvice?.messages?.[0]?.[0]}
        />
      </Grid>
      <Grid item display="grid" paddingTop="40px" paddingBottom="40px">
        <div style={{ border: "1px dashed #8D9899", width: "100%" }} />
      </Grid>
    </Grid>
  );
};

const PackAdviceContent: React.FC<any> = (props) => {
  const dispatch = useDispatch();
  const { actualAdvice, reducer, error, showError, setPopupDeleteAdvice } =
    props;
  const user = useAppSelector((x) => x.userReducer.user);

  return (
    <>
      <Grid container direction="column" wrap="nowrap">
        {actualAdvice?.messages?.[0]?.map((message, index) => {
          return (
            <Grid
              container
              direction="column"
              key={message?.id}
              paddingTop="37px"
              wrap="nowrap"
            >
              <Grid item display="grid">
                <Grid container direction="row" justifyContent="space-between">
                  <Grid item display="grid">
                    <span
                      style={{
                        font: "normal normal 600 20px/30px Poppins",
                        color: "#23595D",
                        letterSpacing: "0px",
                        textAlign: "left",
                      }}
                    >
                      Conseil n°{index + 1}
                    </span>
                  </Grid>
                  <Grid item display="grid">
                    <Grid container direction="column" gap="10px" wrap="nowrap">
                      <Grid item display="grid">
                        <button
                          onClick={
                            actualAdvice?.messages?.[0]?.length > 1
                              ? () => {
                                  dispatch(reducer?.deleteAdvice(index));
                                  /*
                                if (
                                  actualAdvice?.messages?.[0]?.[index]
                                    ?.totalScheduled > 0 &&
                                  actualAdvice?.user_id &&
                                  user?.id == actualAdvice?.user_id
                                ) {
                                  setPopupDeleteAdvice({
                                    isOpen: true,
                                    totalScheduled:
                                      actualAdvice.messages[0][index]
                                        .totalScheduled,
                                    onClick: () => {
                                      dispatch(reducer?.deleteAdvice(index));
                                    },
                                  });
                                } else {
                                  dispatch(reducer?.deleteAdvice(index));
                                }
                                */
                                }
                              : null
                          }
                          className={
                            actualAdvice?.messages?.[0]?.length > 1
                              ? "delete_advice_button"
                              : "disable_delete_advice_button"
                          }
                          style={{ padding: "0", margin: "0" }}
                        >
                          Supprimer le conseil
                        </button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item display="grid" paddingBottom="5px">
                <button
                  onClick={() => {
                    dispatch(reducer?.openImportAdvice(index));
                  }}
                  className="delete_advice_button"
                  style={{ padding: "0", margin: "0", color: "#0B243C" }}
                >
                  Importer un conseil
                </button>
              </Grid>
              <Grid item display="grid">
                <MessageFields
                  reducer={reducer}
                  error={error}
                  showError={showError}
                  isPack={true}
                  index={index}
                  updateAttachField={(
                    index: number,
                    attachment: string,
                    attachment_name: string
                  ) => {
                    dispatch(
                      reducer?.setAttachment({
                        index: index,
                        attachment: attachment,
                        attachment_name: attachment_name,
                      })
                    );
                  }}
                  deleteAttachField={() =>
                    dispatch(reducer?.deleteAttachFileAndName({ index: index }))
                  }
                  onChangeDontSendFormIfDuration={(duration) => {
                    dispatch(
                      reducer?.setDontSendFormIfDuration({
                        index: index,
                        value: duration,
                      })
                    );
                  }}
                  onChangeHoursField={(hours) =>
                    dispatch(reducer?.setHours({ index: index, hours: hours }))
                  }
                  onChangeUrlField={(e) =>
                    dispatch(reducer?.setUrl({ index: index, url: e }))
                  }
                  onClickShortUrl={() =>
                    dispatch(
                      reducer?.setShortUrl({
                        index: index,
                        value: message?.short_url == 1 ? 0 : 1,
                      })
                    )
                  }
                  onClickDontSendFormIf={() =>
                    dispatch(
                      reducer?.setDontSendFormIf({
                        index: index,
                        value: message?.dont_send_form_if == 1 ? 0 : 1,
                      })
                    )
                  }
                  onClickStopSMS={() =>
                    dispatch(
                      reducer?.setStopSMS({
                        index: index,
                        value: message?.stop_sms == 1 ? 0 : 1,
                      })
                    )
                  }
                  onChangeMessageField={(e) =>
                    dispatch(reducer?.setContent({ index: index, content: e }))
                  }
                  onChangeQuestionnaireInteractifField={(e) =>
                    dispatch(
                      reducer?.setQuestionnaireInteractif({
                        index: index,
                        label: e?.label,
                        value: e?.value,
                      })
                    )
                  }
                  message={actualAdvice?.messages?.[0]?.[index]}
                />
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};

const CreateOrEditAdvice: React.FC<any> = (props) => {
  const { specialitiesOptions } = props;
  const [isEditChoicePopup, setIsEditChoicePopup] =
    React.useState<boolean>(false);
  const [showError, setShowError] = React.useState<boolean>(false);
  const [error, setError] = React.useState<string>("");
  const { isEdit, isCreate } = props;
  const [reducer, setReducer] = React.useState(null);
  const [type, setType] = React.useState("create");
  const user = useAppSelector((x) => x?.userReducer?.user);
  const isAdmin = user?.account_type === "4";
  const initialPopupDeleteAdvice = {
    totalScheduled: 0,
    isOpen: false,
    onClick: () => {
      return;
    },
  };
  const [popupDeleteAdvice, setPopupDeleteAdvice] = React.useState<{
    totalScheduled: number;
    isOpen: boolean;
    onClick: () => void;
  }>(initialPopupDeleteAdvice);

  const dispatch = useDispatch();
  const selector = {
    edit: {
      beforeSubmittedPopupLoading: useAppSelector(
        (state?: any) => state.editAdviceReducer.beforeSubmittedPopupLoading
      ),
      isSubmittedPopup: useAppSelector(
        (state?: any) => state.editAdviceReducer.isSubmittedPopup
      ),
      isExitPopup: useAppSelector(
        (state?: any) => state.editAdviceReducer.isExitPopup
      ),
      isAdviceOpen: useAppSelector(
        (state?: any) => state.editAdviceReducer.isAdviceOpen
      ),
      isImportAdviceOpen: useAppSelector(
        (state?: any) => state.editAdviceReducer.isImportAdviceOpen
      ),
      addToFavorites: useAppSelector(
        (state?: any) => state.editAdviceReducer.addToFavorites
      ),
      actualAdvice: useAppSelector(
        (state?: any) => state.editAdviceReducer.actualAdvice
      ),
      actualAdviceNotEdited: useAppSelector(
        (state?: any) => state.editAdviceReducer.actualAdviceNotEdited
      ),
      isDuplicate: useAppSelector(
        (state?: any) => state.editAdviceReducer.isDuplicate
      ),
    },
    create: {
      isSubmittedPopup: useAppSelector(
        (state?: any) => state.createAdviceReducer.isSubmittedPopup
      ),
      isExitPopup: useAppSelector(
        (state?: any) => state.createAdviceReducer.isExitPopup
      ),
      isAdviceOpen: useAppSelector(
        (state?: any) => state.createAdviceReducer.isAdviceOpen
      ),
      isImportAdviceOpen: useAppSelector(
        (state?: any) => state.createAdviceReducer.isImportAdviceOpen
      ),
      addToFavorites: useAppSelector(
        (state?: any) => state.createAdviceReducer.addToFavorites
      ),
      actualAdvice: useAppSelector(
        (state?: any) => state.createAdviceReducer.actualAdvice
      ),
      isDuplicate: useAppSelector(
        (state?: any) => state.createAdviceReducer.isDuplicate
      ),
      actualAdviceNotEdited: useAppSelector(
        (state?: any) => state.createAdviceReducer.actualAdviceNotEdited
      ),
    },
  };
  const costHT = calcCostConseil(selector?.[type]?.actualAdvice) * 0.0345;
  const margeHT = isPositiveFloat(selector?.[type]?.actualAdvice?.marge)
    ? parseFloat(selector?.[type]?.actualAdvice?.marge)
    : 0;
  const venteHT = costHT + margeHT;
  const margePercent = ((venteHT - costHT) / venteHT) * 100;

  React.useEffect(() => {
    if (isEdit === true) {
      setReducer(editAdviceReducer);
      setType("edit");
    }
    if (isCreate === true) {
      setReducer(createAdviceReducer);
      setType("create");
    }
  }, [isEdit, isCreate]);

  const isValidSubmit = () => {
    const invalidConditions = {
      emptyTitle:
        !selector?.[type]?.actualAdvice?.title ||
        selector?.[type]?.actualAdvice?.title === "",
      emptySpeciality:
        !selector?.[type]?.actualAdvice?.speciality_id ||
        selector?.[type]?.actualAdvice?.speciality_id === "",
    };

    // Adding more invalid conditions (contents, periods)
    for (
      let i = 0;
      i < selector?.[type]?.actualAdvice?.messages[0]?.length &&
      i < MAX_ADVICE_IN_PACK;
      i++
    ) {
      invalidConditions[`emptyContent-${i}`] =
        !selector?.[type]?.actualAdvice?.messages[0]?.[i]?.content ||
        selector?.[type]?.actualAdvice?.messages[0]?.[i]?.content === "";

      invalidConditions[`emptyPeriodCancelSend-${i}`] =
        selector?.[type]?.actualAdvice?.messages[0]?.[i]?.dont_send_form_if ==
        1 && !selector?.[type]?.actualAdvice?.messages[0]?.[i]?.dont_send_form_if_duration; 
    }

    for (const condition of Object.keys(invalidConditions)) {
      if (invalidConditions[condition]) {
        if (!errorMessages?.[condition]) {
          return false;
        }
        if (error !== condition) setError(condition);
        return false;
      }
    }
    if (error !== "") setError("");
    return true;
  };

  const executeDuplicateNotInBase = async (): Promise<boolean> => {
    return new Promise((resolve, reject) => {
      const messages = [];
      for (const message of selector?.[type]?.actualAdviceNotEdited
        ?.messages?.[0]) {
        if (
          selector?.[type]?.actualAdviceNotEdited?.type === "simple" &&
          messages?.length === 1
        )
          break;
        messages.push({
          hours:
            selector?.[type]?.actualAdviceNotEdited?.type === "simple"
              ? 0
              : message?.hours,
          url: message?.url,
          attachment: message?.attachment,
          attachment_name: message?.attachment_name,
          short_url: message?.short_url,
          stop_sms: message?.stop_sms,
          dont_send_form_if: message?.dont_send_form_if,
          dont_send_form_if_duration: message?.dont_send_form_if_duration,
          content: message?.content,
          questionnaire_interactif_id: message?.questionnaire_interactif_id,
        });
      }
      const data = {
        type: selector?.[type]?.actualAdviceNotEdited?.type,
        add_my_favourites: false,
        display_name: selector?.[type]?.actualAdviceNotEdited?.display_name,
        title: selector?.[type]?.actualAdviceNotEdited?.title,
        speciality_id: selector?.[type]?.actualAdviceNotEdited?.speciality_id,
        messages: messages,
        notInBase: true,
      };
      if (isAdmin) {
        data["marge"] = selector?.[type]?.actualAdvice?.marge;
      }
      TutorialDataService.create(data)
        .then((res) => {
          resolve(true);
        })
        .catch((err) => reject(err));
    });
  };

  const executeCreate = (duplicate = false) => {
    const messages = [];
    for (const message of selector?.[type]?.actualAdvice?.messages?.[0]) {
      if (
        selector?.[type]?.actualAdvice?.type === "simple" &&
        messages?.length === 1
      )
        break;
      messages.push({
        hours:
          selector?.[type]?.actualAdvice?.type === "simple"
            ? 0
            : message?.hours,
        url: message?.url,
        id: message?.id,
        attachment: message?.attachment,
        attachment_name: message?.attachment_name,
        short_url: message?.short_url,
        stop_sms: message?.stop_sms,
        dont_send_form_if: message?.dont_send_form_if,
        dont_send_form_if_duration: message?.dont_send_form_if_duration,
        content: message?.content,
        questionnaire_interactif_id: message?.questionnaire_interactif_id,
      });
    }
    const data = {
      id: selector?.[type]?.actualAdvice?.id,
      type: selector?.[type]?.actualAdvice?.type,
      add_my_favourites: selector?.[type]?.addToFavorites,
      display_name: selector?.[type]?.actualAdvice?.display_name,
      title: selector?.[type]?.actualAdvice?.title,
      speciality_id: selector?.[type]?.actualAdvice?.speciality_id,
      messages: messages,
    };
    if (isAdmin) {
      data["marge"] = selector?.[type]?.actualAdvice?.marge;
    }
    if (duplicate) {
      dispatch(reducer?.setIsDuplicate(true));
    }
    dispatch(reducer?.setBeforeSubmittedPopupLoading(true));
    TutorialDataService.create(data)
      .then((res) => {
        EventEmitter.emit("Created", {
          text: data,
        });
        if (data?.add_my_favourites) {
          EventEmitter.emit("RefreshFavorite");
        }
        setIsEditChoicePopup(false);
        dispatch(reducer?.setIsSubmittedPopup(true));
      })
      .catch((err) => console.log(err))
      .finally(() => dispatch(reducer?.setBeforeSubmittedPopupLoading(false)));
  };

  const executeEdit = async () => {
    const messages = [];
    for (const message of selector?.[type]?.actualAdvice?.messages?.[0]) {
      if (
        selector?.[type]?.actualAdvice?.type === "simple" &&
        messages?.length === 1
      )
        break;
      messages.push({
        hours:
          selector?.[type]?.actualAdvice?.type === "simple"
            ? 0
            : message?.hours,
        url: message?.url,
        id: message?.id,
        attachment: message?.attachment ? message?.attachment : "",
        attachment_name: message?.attachment_name
          ? message?.attachment_name
          : "",
        short_url: message?.short_url,
        stop_sms: message?.stop_sms,
        dont_send_form_if: message?.dont_send_form_if,
        dont_send_form_if_duration: message?.dont_send_form_if_duration,
        content: message?.content,
        questionnaire_interactif_id: message?.questionnaire_interactif_id,
      });
    }
    const data = {
      id: selector?.[type]?.actualAdvice?.id,
      type: selector?.[type]?.actualAdvice?.type,
      add_my_favourites: selector?.[type]?.addToFavorites,
      display_name: selector?.[type]?.actualAdvice?.display_name,
      title: selector?.[type]?.actualAdvice?.title,
      speciality_id: selector?.[type]?.actualAdvice?.speciality_id,
      messages: messages,
    };
    if (isAdmin) {
      data["marge"] = selector?.[type]?.actualAdvice?.marge;
    }
    dispatch(reducer?.setBeforeSubmittedPopupLoading(true));
    TutorialDataService.updateConseil(data, data?.id)
      .then((res) => {
        EventEmitter.emit("Created", {
          text: data,
        });
        setIsEditChoicePopup(false);
        dispatch(reducer?.setIsSubmittedPopup(true));
        if (data?.add_my_favourites) {
          EventEmitter.emit("RefreshFavorite");
        }
      })
      .catch((err) => console.log(err))
      .finally(() => dispatch(reducer?.setBeforeSubmittedPopupLoading(false)));
  };

  const convertValueToLabelAndValue = (value?: string, options?: any[]) => {
    if (!value || !options) return { value: null, label: null };
    const result = options.find((element) => element?.value === value);

    if (result) {
      return result;
    }
    return { value: null, label: null };
  };
  if (popupDeleteAdvice?.isOpen) {
    return (
      <CancellablePopup
        firstTitle="Supprimer"
        secondTitle="mon conseil"
        message={`Attention, ce conseil est utilisé dans la programmation de ${popupDeleteAdvice.totalScheduled} SMS, êtes-vous sûr de vouloir le supprimer ?`}
        confirmButtonText="Supprimer"
        cancelButtonText="Annuler"
        onCancel={() => setPopupDeleteAdvice(initialPopupDeleteAdvice)}
        onConfirm={() => {
          popupDeleteAdvice.onClick();
          setPopupDeleteAdvice(initialPopupDeleteAdvice);
        }}
      />
    );
  }
  if (isEditChoicePopup) {
    return (
      <CancellablePopup
        message="Voulez-vous remplacer ce conseil ou conserver les deux ?"
        cancelButtonText="Remplacer"
        confirmButtonText="Conserver"
        firstTitle="Modifier"
        secondTitle="un conseil"
        onClose={() => setIsEditChoicePopup(false)}
        onConfirm={() => executeCreate(true)}
        onCancel={() => executeEdit()}
        isLoading={selector.edit.beforeSubmittedPopupLoading}
      />
    );
  }
  if (selector?.[type]?.isSubmittedPopup) {
    return ReactDOM.createPortal(
      <>
        <InformationPopup
          firstTitle={
            isEdit || selector?.[type]?.isDuplicate ? `Modifier` : "Créer"
          }
          secondTitle={
            isEdit || selector?.[type]?.isDuplicate
              ? "le conseil"
              : "un conseil"
          }
          message={(() => {
            if (selector?.[type]?.isDuplicate) {
              return `Ce conseil a été dupliqué avec vos modifications.`;
            }
            if (isEdit) {
              return `Votre conseil a bien été modifié.`;
            }
            return `Votre conseil a bien été crée.`;
          })()}
          confirmButtonText="OK"
          onConfirm={() => dispatch(reducer?.closeAdvice())}
        />
      </>,
      document.body
    );
  } else if (selector?.[type]?.isImportAdviceOpen) {
    return <ImportAdvice reducer={reducer} selector={selector} type={type} />;
  } else if (selector?.[type]?.isExitPopup) {
    return ReactDOM.createPortal(
      <>
        <CancellablePopup
          firstTitle={
            isEdit || selector?.[type]?.isDuplicate ? "Modifier" : "Créer"
          }
          secondTitle={
            isEdit || selector?.[type]?.isDuplicate
              ? "le conseil"
              : "un conseil"
          }
          message="Attention, vous allez fermer cette fenêtre et vos modifications ne seront pas enregistrées."
          confirmButtonText="Fermer"
          cancelButtonText="Ne pas fermer"
          onCancel={() => {
            dispatch(reducer?.setIsExitPopup(false));
          }}
          onConfirm={() => {
            dispatch(reducer?.closeAdvice());
          }}
        />
      </>,
      document.body
    );
  } else if (
    selector?.[type] &&
    reducer &&
    selector?.[type]?.isAdviceOpen &&
    specialitiesOptions
  ) {
    return (
      <FormPopup>
        <Grid
          item
          display="grid"
          paddingTop="40px"
          paddingLeft="50px"
          paddingRight="50px"
          paddingBottom="40px"
        >
          <FormPopupHeader
            firstTitle={
              isCreate && !selector?.[type]?.isDuplicate ? "Créer" : "Modifier"
            }
            secondTitle={
              isCreate && !selector?.[type]?.isDuplicate
                ? "un conseil"
                : "le conseil"
            }
            onClose={() => dispatch(reducer?.setIsExitPopup(true))}
          />
        </Grid>
        <Grid item display="grid">
          <Grid
            container
            wrap="nowrap"
            width="70%"
            justifySelf="center"
            direction="column"
          >
            <Grid item display="grid">
              <AdviceTypeField
                onSimpleClick={() => dispatch(reducer?.setPackType(false))}
                onPackClick={() => dispatch(reducer?.setPackType(true))}
                adviceType={selector?.[type]?.actualAdvice?.type}
              />
            </Grid>
            <Grid item display="grid" paddingTop="27px">
              <AdviceTitleField
                itemBorder={
                  showError && error === "emptyTitle"
                    ? "1px solid #FF3100"
                    : null
                }
                onChange={(e) => dispatch(reducer?.setTitle(e.target.value))}
                adviceTitle={selector?.[type]?.actualAdvice?.title}
              />
            </Grid>
            <Grid item display="grid" paddingTop="27px">
              <AdviceSpecialityField
                itemBorder={
                  showError && error === "emptySpeciality"
                    ? "1px solid #FF3100"
                    : "1px solid transparent"
                }
                specialitiesOptions={specialitiesOptions}
                value={convertValueToLabelAndValue(
                  selector?.[type]?.actualAdvice?.speciality_id,
                  specialitiesOptions
                )}
                onChange={(e) => dispatch(reducer?.setSpeciality(e?.value))}
              />
            </Grid>
            <Grid item display="grid" paddingTop="40px">
              <div style={{ border: "1px dashed #8D9899", width: "100%" }} />
            </Grid>
            <Grid item display="grid">
              {selector?.[type]?.actualAdvice?.type === "pack" ? (
                <PackAdviceContent
                  reducer={reducer}
                  actualAdvice={selector?.[type]?.actualAdvice}
                  error={error}
                  showError={showError}
                  setPopupDeleteAdvice={setPopupDeleteAdvice}
                />
              ) : (
                <UniqueAdviceContent
                  reducer={reducer}
                  actualAdvice={selector?.[type]?.actualAdvice}
                  error={error}
                  showError={showError}
                />
              )}
            </Grid>
            {selector?.[type]?.actualAdvice?.type === "pack" && (
              <Grid item display="grid" paddingTop="40px">
                <Grid container direction="row" spacing="10px">
                  <Grid item display="grid">
                    <button
                      onClick={() => dispatch(reducer?.addAdvice())}
                      style={{
                        background: "#0B243C 0% 0% no-repeat padding-box",
                        borderRadius: "10px",
                        height: "35px",
                        width: "152px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontWeight: 500,
                        fontFamily: "Poppins",
                        fontSize: "13px",
                        letterSpacing: "0px",
                        color: "#FFFFFF",
                        textAlign: "center",
                        paddingLeft: "18px",
                        paddingRight: "18px",
                        paddingTop: "8px",
                        paddingBottom: "8px",
                      }}
                    >
                      Ajouter un conseil
                    </button>
                  </Grid>

                  <Grid item display="grid">
                    <button
                      onClick={() => dispatch(reducer?.openImportAdvice())}
                      style={{
                        background: "#0B243C 0% 0% no-repeat padding-box",
                        borderRadius: "10px",
                        height: "35px",
                        width: "180px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontWeight: 500,
                        fontFamily: "Poppins",
                        fontSize: "13px",
                        letterSpacing: "0px",
                        color: "#FFFFFF",
                        textAlign: "center",
                        paddingLeft: "18px",
                        paddingRight: "18px",
                        paddingTop: "8px",
                        paddingBottom: "8px",
                      }}
                    >
                      Importer un conseil
                    </button>
                  </Grid>
                </Grid>
              </Grid>
            )}
            {selector?.[type]?.actualAdvice?.type === "pack" && (
              <Grid item display="grid" paddingTop="40px" paddingBottom="40px">
                <div style={{ border: "1px dashed #8D9899", width: "100%" }} />
              </Grid>
            )}
            <Grid item display="grid" paddingTop="0px">
              <AdviceDisplayName
                addName={
                  Number(selector?.[type]?.actualAdvice?.display_name) === 1
                }
                onClick={() =>
                  dispatch(
                    reducer?.setDisplayName(
                      !Number(selector?.[type]?.actualAdvice?.display_name)
                    )
                  )
                }
              />
            </Grid>
            <Grid item display="grid" paddingTop="0px" marginTop="5px">
              <AdviceAddToFavorites
                isPack={selector?.[type]?.actualAdvice?.type === "pack"}
                addToFavorites={selector?.[type]?.addToFavorites}
                onClick={() =>
                  dispatch(
                    reducer?.setFavorite(!selector?.[type]?.addToFavorites)
                  )
                }
              />
            </Grid>
            <Grid item display="grid" paddingTop="37px">
              <span className="text_champs" style={{ textAlign: "center" }}>
                * Champs obligatoires
              </span>
            </Grid>
            {isAdmin && false && (
              <Grid item display="grid" marginTop="40px">
                <Grid
                  container
                  direction="column"
                  wrap="nowrap"
                  gap="5px"
                  style={{
                    fontSize: "16px",
                    color: "#0B243C",
                    fontFamily: "Poppins",
                  }}
                >
                  <Grid item display="grid" marginBottom="40px">
                    <div
                      style={{ border: "1px dashed #8D9899", width: "100%" }}
                    />
                  </Grid>
                  <Grid item display="grid">
                    <GenericTextInput
                      fieldTitle="Marge HT (en €)"
                      isMandatory={false}
                      id="input-marge"
                      placeholder="0"
                      value={selector?.[type]?.actualAdvice?.marge}
                      onChange={(e) => {
                        let marge: string = e?.target?.value;
                        if (!marge) {
                          marge = "";
                        }
                        marge = marge.replaceAll(",", ".");
                        marge = marge.replaceAll(" ", "");
                        dispatch(reducer?.setMarge(marge));
                      }}
                    />
                  </Grid>
                  <Grid item display="grid">
                    <span>Prix d'achat HT : {costHT.toFixed(3)}€</span>
                  </Grid>
                  <Grid item display="grid">
                    <span>Prix de vente (HT) : {venteHT.toFixed(3)}€</span>
                  </Grid>
                  <Grid item display="grid">
                    <span>Marge (en %) : {margePercent.toFixed(2)}%</span>
                  </Grid>
                </Grid>
              </Grid>
            )}
            {error?.length > 0 && (
              <Grid item display="grid" paddingTop="8px">
                <Grid
                  container
                  direction="row"
                  display="flex"
                  justifyContent="center"
                  spacing="5px"
                  marginBottom="10px"
                >
                  <Grid item display="grid" alignItems="center">
                    <span className="field_star" style={{ color: "#FF3100" }}>
                      {errorMessages[error]}
                    </span>
                  </Grid>
                  <Grid item display="grid" alignItems="center">
                    <button
                      style={{
                        background: "none",
                        border: "none",
                        padding: "0",
                        margin: "0",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                      onMouseLeave={() => {
                        setTimeout(() => {
                          if (showError) setShowError(false);
                        }, 2250);
                      }}
                      onClick={() => {
                        if (!showError) setShowError(true);
                        if (error === "" || errorSelector?.[error] === "")
                          return;
                        const section = document?.querySelector(
                          errorSelector?.[error]
                        );
                        section?.scrollIntoView({
                          behavior: "smooth",
                          block: "center",
                        });
                      }}
                    >
                      <Help
                        className="field_star"
                        style={{ height: "20px", color: "#FF3100" }}
                      />
                    </button>
                  </Grid>
                </Grid>
              </Grid>
            )}
            <Grid
              item
              display="grid"
              paddingTop={error?.length > 0 ? "8px" : "52px"}
              paddingBottom="65px"
            >
              <Grid
                container
                direction="row"
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <Grid item display="grid">
                  <GreyButton
                    animation={true}
                    enable={true}
                    text="Annuler"
                    onClick={() => {
                      dispatch(reducer?.setIsExitPopup(true));
                    }}
                  />
                </Grid>
                <Grid item display="grid">
                  {isValidSubmit() ? (
                    <div style={{ marginLeft: "25px" }}>
                      <OrangeButton
                        animation={true}
                        enable={true}
                        type="submit"
                        text={
                          isCreate && !selector?.[type]?.isDuplicate
                            ? "Créer"
                            : "Modifier"
                        }
                        onClick={
                          isEdit
                            ? () => {
                                setIsEditChoicePopup(true);
                              }
                            : () => {
                                executeCreate();
                                /*
                              console.log("execute create");
                              console.log("is duplicate ?");
                              console.log(selector?.[type]?.isDuplicate);
                              if (
                                user?.id ==
                                  selector?.[type]?.actualAdvice?.user_id &&
                                selector?.[type]?.isDuplicate
                              ) {
                                executeDuplicateNotInBase();
                                executeEdit();
                              } else {
                                executeCreate();
                              }
                              */
                              }
                        }
                      />
                    </div>
                  ) : (
                    <div style={{ marginLeft: "25px" }}>
                      <OrangeButton
                        animation={true}
                        enable={false}
                        type="submit"
                        text={
                          isCreate && !selector?.[type]?.isDuplicate
                            ? "Créer"
                            : "Modifier"
                        }
                      />
                    </div>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </FormPopup>
    );
  } else {
    return null;
  }
};

export default CreateOrEditAdvice;
