import * as React from "react";
import { Grid, Tooltip } from "@mui/material";
import { Help } from "@mui/icons-material";

interface ITextField {
  title: string;
  isMandatory: boolean;
  hideAsterics?: boolean;
  remainingCharacters?: boolean;
  contentLength?: number;
  isQuestionnaire?: boolean;
  whiteSpace?: string;
}

interface IFullField {
  title: string;
  isMandatory: boolean;
  hideAsterics?: boolean;
  remainingCharacters?: boolean;
  justifyContent?: string;
  isQuestionnaire?: boolean;
  whiteSpace?: string;
  contentLength?: number;
  children: any;
  spacing?: string;
  helper?: string;
}

const TitleField = (props: ITextField) => {
  const {
    title,
    isMandatory,
    remainingCharacters,
    contentLength,
    hideAsterics,
    whiteSpace,
    isQuestionnaire,
  } = props;

  return (
    <Grid container direction="row" gap="10px">
      <Grid item display="grid">
        <span className="text_champs" style={{ wordBreak: "break-word" }}>
          {title}
          {isMandatory && !hideAsterics ? " *" : ""}
        </span>
      </Grid>
      {remainingCharacters === true && (
        <Grid item display="grid">
          <span
            style={{
              font: "normal normal normal 16px/25px Poppins",
              color: "#8D9899",
              textAlign: "left",
              letterSpacing: "0px",
              wordBreak: "break-word",
            }}
          >
            {contentLength
              ? `${325 - contentLength} caractères restants`
              : "325 caractères restants"}
          </span>
        </Grid>
      )}
    </Grid>
  );
};

const FullField = ({
  children,
  whiteSpace,
  isMandatory,
  hideAsterics,
  title,
  remainingCharacters,
  contentLength,
  spacing,
  isQuestionnaire,
  helper,
  justifyContent,
}: IFullField) => {
  return (
    <>
      <Grid container direction="column" wrap="nowrap">
        {title && (
          <Grid item display="grid">
            <Grid container direction="row" wrap="nowrap" gap="10px">
              <Grid item display="grid">
                <TitleField
                  title={title}
                  whiteSpace={whiteSpace}
                  isMandatory={isMandatory}
                  hideAsterics={hideAsterics}
                  remainingCharacters={remainingCharacters}
                  isQuestionnaire={isQuestionnaire}
                  contentLength={contentLength}
                />
              </Grid>
              {helper && (
                <Grid item display="grid" alignSelf='center'>
                  <Tooltip title={helper}>
                    <Help
                      style={{
                        height: "20px",
                        width: "20px",
                        cursor: 'help',
                        color: "#0B243C",
                      }}
                    />
                  </Tooltip>
                </Grid>
              )}
            </Grid>
          </Grid>
        )}
        <Grid item display="grid" paddingTop={spacing ? spacing : (title ? "5px" : "0px")}>
          {children}
        </Grid>
      </Grid>
    </>
  );
};

export default FullField;
