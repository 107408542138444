import * as React from "react";
import { Grid, CircularProgress } from "@mui/material";
import makeAPIRequest from "../utils/makeAPIRequest";
import SimpleFullPage from "./SimpleFullPage";
import useQuery from "../utils/hooks/useQuery";
import {
  GenericTextInput,
  useOpenSnackbar,
} from "./CreateOrEditChatbotLexPage";
import { GenericSendButton } from "./ChatbotLexPage";
import OrangeCheckBox from "../components/Fields/CheckBox/OrangeCheckBox";

const RedirectPage: React.FC<{ isReset?: boolean }> = (props) => {
  React.useEffect(() => {
    setTimeout(() => {
      window.location.replace(`${process.env.REACT_APP_WEB_APP_URL}/#/login`);
    }, 5000);
  });

  return (
    <Grid container direction="column" wrap="nowrap" gap="5px">
      <Grid item display="grid">
        <span
          style={{
            fontSize: "16px",
            color: "#0B243C",
            textAlign: "left",
            fontFamily: "Poppins",
            whiteSpace: "pre-line",
          }}
        >
          Votre mot de passe a bien été{" "}
          {props.isReset ? `réinitialisé` : `créé`}, vous allez être redirigé
          vers la page de connexion dans 5 secondes.
        </span>
      </Grid>
      <Grid item display="grid">
        <a href={`${process.env.REACT_APP_WEB_APP_URL}/#/login`}>
          Cliquez ici pour être redirigé.
        </a>
      </Grid>
    </Grid>
  );
};

const PasswordForm: React.FC<{
  setSuccess: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ setSuccess }) => {
  const email = useQuery().get("email");
  const token = useQuery().get("token");
  const isResetQuery = useQuery().get("is_reset");
  const isReset = isResetQuery === "true";
  const [cgv, setCgv] = React.useState<boolean>(false);
  const [password, setPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const openSnackbarUse = useOpenSnackbar();
  const [isLoading, setIsLoading] = React.useState(false);

  return (
    <Grid
      container
      direction="column"
      wrap="nowrap"
      justifyContent={"center"}
      gap="10px"
      paddingTop="20px"
    >
      <Grid item display="grid">
        <span
          style={{
            fontFamily: "Poppins",
            color: "#0B243C",
            fontSize: "18px",
            textAlign: "center",
            fontWeight: 600,
          }}
        >
          {isReset
            ? `Réinitialisez votre mot de passe`
            : `Choisissez votre mot de passe`}
        </span>
      </Grid>
      <Grid item display="grid" marginTop="10px">
        <GenericTextInput
          fieldTitle="Adresse e-mail"
          isMandatory={true}
          id="input-email"
          value={email}
          disabled={true}
          onChange={(e) => {
            return;
          }}
        />
      </Grid>
      <Grid item display="grid">
        <GenericTextInput
          fieldTitle="Mot de passe"
          isMandatory={true}
          type="password"
          id="input-user-pass"
          value={password}
          onChange={(e) => {
            setPassword(e?.target?.value ? e.target.value : "");
          }}
        />
      </Grid>
      <Grid item display="grid">
        <GenericTextInput
          fieldTitle="Confirmer le mot de passe"
          isMandatory={true}
          type="password"
          id="input-user-confirm-pass"
          value={confirmPassword}
          onChange={(e) => {
            setConfirmPassword(e?.target?.value ? e.target.value : "");
          }}
        />
      </Grid>
      <Grid item display="grid">
        <span
          style={{
            textAlign: "left",
            fontSize: "12px",
            fontFamily: "Poppins",
            color: "#0B243C",
          }}
        >
          {`Le mot de passe doit être composé d'au minimum
          14 caractères comprenant des majuscules, des minuscules et
          des chiffres.`}
        </span>
      </Grid>
      <Grid
        item
        display="grid"
        marginTop="5px"
        alignSelf={"center"}
        id="cgv-checkbox"
        style={{ border: "1px solid transparent", borderRadius: "15px" }}
        padding="15px"
      >
        <OrangeCheckBox
          value={cgv}
          addLinkText={
            "https://media.certipair.fr/CERTIPAIR+CGU.pdf"
          }
          textStyle={{
            color: "rgb(255, 165, 0)",
            fontWeight: "bold",
            fontSize: "14px",
          }}
          text="En cochant cette case, je reconnais avoir pris connaissance des conditions générales d'utilisation de Certipair et les accepte."
          onClick={() => {
            setCgv((x) => {
              return !x;
            });
          }}
        />
      </Grid>
      <Grid item display="grid">
        <GenericSendButton
          textButton="Valider"
          isLoading={isLoading}
          errorMessages={{
            noEmail: "L'adresse e-mail n'est pas renseignée.",
            noPassword: "Le mot de passe n'est pas renseigné.",
            badPasswordLength:
              "Le mot de passe doit contenir au minimum 14 caractères",
            badPasswordMajuscule:
              "Le mot de passe doit contenir au minimum une majuscule",
            badPasswordChiffre:
              "Le mot de passe doit contenir au minimum un chiffre",
            badPasswordMinuscule:
              "Le mot de passe doit contenir au minimum une minuscule",
            notSame: "Il faut que les mots de passes soient identiques.",
            noCgv: "Il faut accepter les conditions générales de ventes.",
          }}
          errorSelector={{
            noEmail: "input-email",
            noPassword: "#input-user-pass",
            badPasswordLength: "#input-user-pass",
            badPasswordMajuscule: "#input-user-pass",
            badPasswordChiffre: "#input-user-pass",
            badPasswordMinuscule: "#input-user-pass",
            notSame: "#input-user-pass",
            noCgv: "#cgv-checkbox",
          }}
          invalidConditions={{
            noEmail: !email,
            noPassword: !password,
            badPasswordLength: password?.length < 14,
            badPasswordMajuscule: !/[A-Z]/.test(password),
            badPasswordChiffre: !/\d/.test(password),
            badPasswordMinuscule: !/[a-z]/.test(password),
            notSame: password != confirmPassword,
            noCgv: !cgv,
          }}
          onSendClick={() => {
            setIsLoading(true);
            makeAPIRequest(
              "post",
              "/user/update_pass",
              {
                user_pass: password,
              },
              "v2",
              token
            )
              .then(() => {
                setSuccess(true);
              })
              .catch((err) => {
                openSnackbarUse.error(
                  isReset
                    ? `Impossible de réinitialiser le mot de passe.`
                    : "Impossible de créer le mot de passe.",
                  err
                );
              })
              .finally(() => setIsLoading(false));
          }}
        />
      </Grid>
    </Grid>
  );
};

export const ResetPasswordBox = (props: { noPadding?: boolean }) => {
  const [success, setSuccess] = React.useState(false);
  const isResetQuery = useQuery().get("is_reset");
  const isReset = isResetQuery === "true";

  return (
    <Grid
      container
      wrap="nowrap"
      direction="column"
      paddingLeft={props.noPadding ? 0 : "5%"}
      paddingTop={props.noPadding ? 0 : "5%"}
      paddingRight={props.noPadding ? 0 : "5%"}
      paddingBottom={props.noPadding ? 0 : "5%"}
    >
      <Grid item display="grid">
        {success ? (
          <RedirectPage isReset={isReset} />
        ) : (
          <PasswordForm setSuccess={setSuccess} />
        )}
      </Grid>
    </Grid>
  );
};

const ResetPasswordPage: React.FC = () => {
  return (
    <>
      <SimpleFullPage>
        <Grid
          container
          wrap="nowrap"
          width="95vw"
          direction="column"
          maxWidth="700px"
        >
          <Grid item display="grid">
            <ResetPasswordBox />
          </Grid>
        </Grid>
      </SimpleFullPage>
    </>
  );
};

export default ResetPasswordPage;
